import {
    IDENTIFY_SALON,
    IDENTIFY_SALON_BY_DOMAIN,
    PARAMS_INITIALIZED,
    WIDGET_SET_PARAMS,
    WIDGET_TOGGLE
} from '../actions/type'

const INITIAL_STATE = {
    open: false,
    identifier: 0,
    group_slug: '',
    params_initialized: false,
    client_web: false,
    salon_id: undefined,
    category_id: undefined,
    service_id: undefined,
}

const widgetReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case WIDGET_TOGGLE:
            const widget_element = document.getElementById('SalonLife__widget')

            if (widget_element) {
                let classes = widget_element.classList

                if (state.open === true && action.payload === false) {
                    classes.remove('open')
                } else if (state.open === false && action.payload === true) {
                    classes.add('open')
                }
            }

            return {
                ...state,
                open: action.payload,
            }
        case IDENTIFY_SALON_BY_DOMAIN:
            return {
                ...state,
                group_slug: action.payload,
            }
        case IDENTIFY_SALON:
            return {
                ...state,
                identifier: action.payload,
            }
        case WIDGET_SET_PARAMS:
            return {
                ...state,
                client_web: action.payload?.client_web ?? false,
                salon_id: action.payload?.salon_id,
                category_id: action.payload?.category_id,
                service_id: action.payload?.service_id,
            }
        case PARAMS_INITIALIZED:
            return {
                ...state,
                params_initialized: action.payload
            }

        default:
            return state
    }
}

export default widgetReducer
