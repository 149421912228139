import React from 'react';
import inputStyle from '../scss/components/FormInput.module.scss';
import phoneStyle from '../scss/components/FormPhoneField.module.scss';
import classNames from 'classnames';
import {Field} from 'redux-form';
import FormPhonePrefixField from './FormPhonePrefixField';

class FormPhoneField extends React.Component {
    inputRef = undefined;

    focusInput = () => {
        this.inputRef.focus()
    }

    render() {

        return (
            <div className={classNames(inputStyle.FormInput, {
                [inputStyle.Selected]: this.props.phone.meta.active,
                [inputStyle.Error]: (((this.props.phone_prefix.meta.touched || this.props.hasSubmissionError?.phone_prefix) && this.props.phone_prefix.meta.error) || ((this.props.phone.meta.touched || this.props.hasSubmissionError?.phone) && this.props.phone.meta.error)),
            })} onClick={this.focusInput}>
                <span className={inputStyle.Logo}>
                    {this.props.logo}
                </span>
                <label className={inputStyle.Label}>
                    <span className={inputStyle.Text}>{this.props.label}</span>
                    <div className={phoneStyle.PhoneFieldContainer}>
                        <Field
                            name="phone_prefix"
                            component={FormPhonePrefixField}
                        />
                        <div className={phoneStyle.PhoneField}>
                            <input {...this.props.phone.input} ref={ref => this.inputRef = ref} type="tel" autoComplete="new-password" spellCheck="false"/>
                        </div>
                    </div>
                </label>

            </div>
        );
    }
}

export default FormPhoneField;