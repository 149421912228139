import React, {useEffect, useRef, useState} from 'react'
import usePrevious from '../helpers/usePrevious'
import useIsMounted from '../helpers/useIsMounted'
import useDigitInput from 'react-digit-input'
import classNames from 'classnames'
import style from '../scss/components/DigitInput.module.scss'

const DigitInput = props => {
    const firstInputRef = useRef()
    const lastInputRef = useRef()
    const isMounted = useIsMounted()
    const digits = useDigitInput({
        acceptedCharacters: /^[0-9]$/,
        length: props.length,
        value: props.input.value,
        onChange: props.input.onChange,
    })
    const [focus, setFocus] = useState(undefined)
    const [autoFocus, setAutoFocus] = useState(false)
    const prevIsHidden = usePrevious(props.isHidden)
    let autoFocusTimeout = useRef(0);

    useEffect(() => {
        if ((!props.isHidden && prevIsHidden !== props.isHidden && prevIsHidden !== undefined) || props.autoFocus) {
            autoFocusTimeout.current = setTimeout(() => {
                if (isMounted) {
                    setAutoFocus(true)
                }
            }, 300)
        } else if (autoFocus) {
            if (isMounted)
                setAutoFocus(false)
        }

        return () => {
            if (autoFocusTimeout.current) {
                clearTimeout(autoFocusTimeout.current)
                autoFocusTimeout.current = 0
            }
        }
    }, [autoFocus, isMounted, prevIsHidden, props.autoFocus, props.isHidden])

    useEffect(() => {
        if (autoFocus && firstInputRef.current && isMounted)
            firstInputRef.current.focus()
    }, [autoFocus, isMounted])

    const renderFields = () => {
        const fields = []

        for (let i = 0; i < props.length; ++i) {
            let additional_props = {}
            if (i === 0)
                additional_props = {
                    ref: ref => {
                        digits[i].ref(ref)
                        firstInputRef.current = ref
                    },
                }
            else if ((i + 1) === props.length && props.blurOnEnd)
                additional_props = {
                    ref: ref => {
                        digits[i].ref(ref)
                        lastInputRef.current = ref
                    },
                    onKeyDown: e => {
                        digits[i].onKeyDown(e)

                        if (lastInputRef.current && [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].indexOf(parseInt(e.key)) !== -1 && String(props.input.value).replaceAll(' ', '').length === props.length)
                            lastInputRef.current.blur()
                    }
                }

            fields.push(
                <div className={classNames(style.FieldWrapper, {
                    [style.HasFocus]: i === focus && digits[i].value === '' && !props.disabled,
                    [style.Disabled]: props.disabled,
                    [style.Failed]: props.failed,
                })} key={i}>
                    <input inputMode="decimal"
                           {...digits[i]}
                           {...additional_props}
                           onFocus={(e) => {
                               digits[i].onFocus(e)
                               setFocus(i)
                           }}
                           onBlur={() => setFocus(undefined)}
                           disabled={props.disabled}
                           className={style.Field}/>
                </div>,
            )
        }

        return fields
    }

    return (
        <div className={classNames(style.Wrapper, {
            'hide': props.isHidden,
        })} style={props.style}>
            {renderFields()}
        </div>
    )
}

export default DigitInput
