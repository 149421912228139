import React from 'react';
import styleFooter from "../scss/components/Footer.module.scss";
import connect from "react-redux/es/connect/connect";

class Progress extends React.Component {
    renderItems() {
        const items = [];
        for (let i = 1; i <= this.props.progress.total; ++i) {
            let classes = styleFooter.ProgressEntity;

            if (i <= this.props.progress.active) {
                classes += ' ' + styleFooter.Active;
            }

            items.push(
                <div className={styleFooter.ProgressItem} key={i}>
                    <div className={classes}/>
                </div>
            );
        }

        return items;
    }

    render() {
        return (
            <div className={styleFooter.Progress}>
                { this.renderItems() }
            </div>
        );
    }
}

Progress.defaultProps = {
    steps: 2,
    active: 1
};

const mapStateToProps = (state) => {
    return {
        progress: state.ui.progress,
    };
};

export default connect(mapStateToProps, {})(Progress);
