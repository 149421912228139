import axios from './salonlife'
import {maintenanceMode} from '../actions/userInterface'

const updateServerDate = (dateStr) => {
    const serverTimeMillisGMT = Date.parse(new Date(Date.parse(dateStr)).toUTCString())
    const localMillisUTC = Date.parse(new Date().toUTCString())

    window.serverDateOffset = serverTimeMillisGMT - localMillisUTC
}

const network_service = {
    setupInterceptors: (store, history) => {
        axios.interceptors.request.use((config) => {
            return config
        }, error => Promise.reject(error))

        // Add a response interceptor
        axios.interceptors.response.use(function (response) {
            if (response.request)
                updateServerDate(response.request.getResponseHeader('Date'))

            return response
        }, function (error) {
            if (error?.response) {
                if ((error?.response?.request ?? null))
                    updateServerDate(error.response.request.getResponseHeader('Date'))
            }

            if ((error?.response?.status ?? null) === 503) {
                maintenanceMode(true)(store.dispatch, store.getState)
            } else if (store.getState().location_lock.maintenance) {
                maintenanceMode(false)(store.dispatch, store.getState)
            }

            return Promise.reject(error)
        })
    },
}

export default network_service
