import React from 'react'
import '../scss/main.scss'
import styleApp from '../scss/components/App.module.scss'
import connect from 'react-redux/es/connect/connect'
import {sizeChanged} from '../actions/window'
import {onPathChange, setTotalProgress} from '../actions/userInterface'
import registerListener from '../helpers/registerListener'
import {withTranslation} from 'react-i18next'
import {isWeb, widgetScrollContentId} from '../env'
import Header from '../components/Header'
import SimpleHeader from '../components/SimpleHeader'
import Footer from '../components/Footer'
import {matchPath, Redirect, Route, Router, Switch} from 'react-router-dom'
import LanguageSwitcher from '../components/LanguageSwitcher'
import Search from '../components/Search'
import ShoppingCart from '../components/ShoppingCart'
import Confirm from '../components/Confirm'
import Dialog from '../components/Dialog'
import classNames from 'classnames'
import ReactGA from 'react-ga4'
import FacebookPixel from '../helpers/facebookPixel'
import {slugChange} from '../actions/routes'
import SalonCode from '../components/SalonCode'
import EmployeeCode from '../components/EmployeeCode'

class Main extends React.Component {
    app_routes = {}
    routerLocation = {}
    scrollContentId = widgetScrollContentId
    appContentRef = React.createRef()

    state = {
        routerLocation: undefined,
    }

    constructor(props) {
        super(props)

        const {i18n} = this.props

        this.state = {
            disableScroll: false,
        }

        if (!this.props.maintenance)
            i18n.changeLanguage(this.props.language)
    }

    afterResize = () => {
        this.props.sizeChanged()
    }

    shouldSlugChange = () => {
        if (isWeb && this.props.salon_location.value && this.props.active_route_payload === '' && (this.props.salon_location?.selected_location?.slug ?? false))
            this.props.slugChange(this.props.salon_location?.selected_location?.slug)
    }

    componentDidMount() {
        registerListener('resize', this.afterResize)

        FacebookPixel.require_fbq()

        const analyticsObjects = []
        this.props.salons.forEach(salon => {
            if (salon?.settings['salon.google_analytics_id']) {
                analyticsObjects.push({
                    trackingId: salon.settings['salon.google_analytics_id'],
                    gtagOptions: {
                        groups: 'salon_' + salon.id,
                    },
                })
            }

            if (salon?.settings['salon.facebook_pixel_id'])
                FacebookPixel.init(salon.settings['salon.facebook_pixel_id'])
        })

        if (analyticsObjects.length) {
            ReactGA.initialize(analyticsObjects)
        }

        this.props.routerHistory.listen((p) => {
            if (typeof p === typeof undefined)
                p = this.props?.routerHistory?.location ?? {}

            this.routerLocation = p

            this.setState({
                routerLocation: this.routerLocation,
            })
        })

        this.shouldSlugChange()

        if (this.props.ui.currentPath && !isWeb)
            this.props.routerHistory.push(this.props.ui.currentPath)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state?.routerLocation?.pathname !== prevState?.routerLocation?.pathname) {
            const currentPath = this.state?.routerLocation?.pathname ?? '/'

            this.sendPageView(currentPath)

            if (prevState?.routerLocation?.pathname)
                this.props.onPathChange(currentPath)
        }

        this.shouldSlugChange()

        if (this.props.salon_location.items.length === 1) {
            if (this.props.progress.total !== 4 && !this.props.settings['my_salon_group.services.show.categories']) {
                this.props.setTotalProgress(4)
            } else if (this.props.progress.total !== 5 && this.props.settings['my_salon_group.services.show.categories']) {
                this.props.setTotalProgress(5)
            }
        }
    }

    sendPageView(page) {
        if (!this.routeIs('/') && (this.props.widget?.open || this.props.is_web)) {
            if (this.props.selected_salon_id) {
                ReactGA.event('booking_navigation', {
                    path: page,
                    send_to: this.props.selected_salon_id,
                })
                // GA4 tracks pageview automatically
                // ReactGA.send({ hitType: "pageview", send_to: this.props.selected_salon_id })
            }

            if (this.props.selected_salon_facebook_pixel_id)
                FacebookPixel.pageViewSingle(this.props.selected_salon_facebook_pixel_id)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.afterResize)
    }

    renderConfirm() {
        const {title, description, confirm_text, cancel_text, onConfirm} = this.props.ui.confirm.data

        return <Confirm title={title}
                        description={description}
                        confirm_text={confirm_text}
                        cancel_text={cancel_text}
                        onConfirm={onConfirm}/>
    }

    renderDialog() {
        const {title, description, employee} = this.props.ui.dialog.data

        return <Dialog title={title}
                       description={description}
                       employee={employee}
                       code_enabled={this.props.ui.dialog.data?.code_enabled ?? false}/>
    }

    renderHeader = () => {
        if (this.routeIs('/booking') || this.props.errorCode || this.routeIs('/cancel-booking'))
            return null

        if (this.routeIs('/customer') || this.routeIs('/terms') || this.routeIs('/review'))
            return <SimpleHeader/>
        else
            return <Header/>
    }

    canShowShoppingCart = () => {
        return (!this.routeIs('/customer') && !this.routeIs('/terms') && !this.routeIs('/review') && !this.routeIs('/booking') && !this.routeIs('/cancel-booking') && !this.props.errorCode && this.props.order.ids.length) || this.routeIs('/services')
    }

    renderShoppingCart = () => {
        if (this.canShowShoppingCart())
            return <ShoppingCart/>

        return null
    }

    renderSearch = () => {
        if (!this.routeIs('/customer') && !this.routeIs('/terms') && !this.routeIs('/review') && !this.routeIs('/booking') && !this.routeIs('/cancel-booking') && !this.routeIs('/schedule') && !this.props.errorCode)
            return <Search/>

        return null
    }

    renderRoutes() {
        const routes = []

        Object.keys(this.props.app_routes).forEach(key => {
            const item = this.props.app_routes[key]
            const RouteComponent = require('../page/' + item.component).default

            routes.push(
                <Route path={item.path}
                       key={item.path}
                       exact
                       render={(props) => <RouteComponent {...props} appContentRef={this.appContentRef}/>}/>,
            )
        })

        if (isWeb)
            routes.push(
                <Redirect to={'/'} status={404} key={404}/>,
            )

        return (
            <Switch>
                {routes}
            </Switch>
        )
    }

    render() {
        const appContentClasses = classNames(styleApp.AppContent, {
            [styleApp.NoScroll]: !this.props.ui.container_scrollable,
            [styleApp.ShowCart]: this.canShowShoppingCart(),
        })

        const footerProps = {}

        if (this.routeIs('/customer'))
            footerProps.form = 'customerForm'

        return (
            <div className={styleApp.AppContainer}>
                <Router history={this.props.routerHistory}>
                    {this.renderHeader()}
                    {this.renderShoppingCart()}
                    {this.renderConfirm()}
                    <div id={this.scrollContentId}
                         className={appContentClasses}
                         ref={this.appContentRef}>
                        {this.renderSearch()}
                        <div className={classNames(styleApp.AppContentWrapper, {
                            [styleApp.FillBackground]: this.props.errorCode || this.routeIs('/booking') || this.routeIs('/cancel-booking'),
                        })}>
                            <div className={styleApp.AppContentHeight}>
                                {this.renderRoutes()}
                            </div>
                            {this.routeIs('/booking') || this.routeIs('/cancel-booking') || this.props.errorCode ?
                                <Footer {...footerProps} simple={true}/> : <Footer {...footerProps}/>}
                        </div>
                    </div>
                    {this.renderDialog()}
                    <LanguageSwitcher/>
                    <SalonCode/>
                    <EmployeeCode/>
                </Router>
            </div>
        )
    }

    routeIs = (path = '/') => {
        if (!this.props.app_routes[path])
            return false

        return matchPath(this.routerLocation.pathname, {
            path: this.props.app_routes[path].path,
            exact: true,
        })
    }
}

Main.defaultProps = {
    is_web: false,
}

const mapStateToProps = state => {
    let selected_salon_id = null
    let selected_salon_facebook_pixel_id = null

    const selectedSalon = state.salon_location.selected_location

    if (selectedSalon?.settings?.['salon.google_analytics_id'] && state.salon_location.value)
        selected_salon_id = 'salon_' + state.salon_location.value

    if (selectedSalon?.settings?.['salon.facebook_pixel_id'] && state.salon_location.value)
        selected_salon_facebook_pixel_id = selectedSalon?.settings?.['salon.facebook_pixel_id']

    return {
        maintenance: state.location_lock.maintenance,
        widget: state.widget,
        window: state.window,
        order: state.order,
        ui: state.ui,
        submittingBooking: state.booking.submitting,
        errorCode: state.booking.errorCode,
        app_routes: state.routes.app_routes,
        active_route_payload: state.routes.active_payload,
        salons: state.salon_location.items,
        salon_location: state.salon_location,
        progress: state.ui.progress,
        settings: state.salon_location.group_settings,
        selected_salon_id,
        selected_salon_facebook_pixel_id,
    }
}

const TranslatedApp = withTranslation(['general', 'location'])(Main)

export default connect(mapStateToProps, {
    sizeChanged,
    slugChange,
    setTotalProgress,
    onPathChange,
})(TranslatedApp)
