import React from 'react';
import connect from 'react-redux/es/connect/connect';
import ServiceItem from './ServiceItem';
import {withTranslation} from 'react-i18next';
import {addServiceToOrder, removeServicesFromOrder} from '../actions/order';
import search from '../helpers/search';
import NoSearchResults from "./NoSearchResults";
import {onSearch} from '../actions/search';

class ServiceItems extends React.Component {

    onServiceItemChange = (value, category_id, isChecked) => {
        if (isChecked) {
            const multipleServicesEnabled = this.props.group_settings['my_salon_group.services.show.multiple_services_booking'];
            if (!multipleServicesEnabled)
                this.props.removeServicesFromOrder(this.props.order.ids);

            this.props.addServiceToOrder(value, category_id);
        } else
            this.props.removeServicesFromOrder(value);
    };

    renderServices = () => {
        const {t, i18n} = this.props;
        const curLang = i18n.languages[0];

        let filtered_locations = this.props.items;

        if (this.props.search.keyword) {
            filtered_locations = filtered_locations.map(items => {
                const category_names = this.props.categories.names[items.category_id] || {};

                const data = items.data.filter(item =>
                    search(
                        String(item.name[curLang] || item.name[Object.keys(item.name)[0]]),
                        this.props.search.keyword,
                    ) ||
                    search(
                        String(category_names[curLang] || category_names[Object.keys(category_names)[0]]),
                        this.props.search.keyword,
                    ),
                );

                return {
                    data,
                    category_id: items.category_id,
                };
            });
        }

        const servicesOutput = [];
        filtered_locations.forEach((items) => {
            const category_names = this.props.categories.names[items.category_id] || {};

            if (items.data.length) {
                let i = 0;
                items.data.forEach(item => {
                    ++i;
                    servicesOutput.push(
                        <ServiceItem
                            currency={this.props?.selected_location?.settings?.['salon.currency'] ?? '€'}
                            disabled={(this.props.order.services_by_id[item.id] || {}).disabled}
                            label={(i === 1 ? category_names[curLang] || (category_names[Object.keys(category_names)[0]] ?? '') : null)}
                            onChange={this.onServiceItemChange}
                            checked={this.props.order.ids.indexOf(item.id) !== -1}
                            discount_price={item.discount_price}
                            min_price={item.min_price}
                            max_price={item.max_price}
                            min_duration={item.min_duration}
                            max_duration={item.max_duration}
                            value={item.id}
                            category_id={item.category_id}
                            description={item.description[curLang] || item.description[Object.keys(item.description)[0]]}
                            key={item.category_id + '_' + item.id}>
                            {item.name[curLang] || item.name[Object.keys(item.name)[0]]}
                        </ServiceItem>,
                    );
                })
            }
        });

        if (!servicesOutput.length && this.props.search.keyword) {
            return (
                <NoSearchResults
                    text={t('general:no_search_results', {keyword: this.props.search.keyword})}
                    buttonText={t('general:back')}
                    onClick={() => this.props.onSearch('')} />
            )
        }

        return servicesOutput;
    };

    render() {
        return (
            <React.Fragment>
                {this.renderServices()}
            </React.Fragment>
        );
    }
}

ServiceItems.defaultProps = {
    items: [],
};

const mapStateToProps = (state) => {
    return {
        selected_location: state.salon_location.selected_location,
        group_settings: state.salon_location.group_settings,
        search: state.search,
        categories: state.categories,
        order: state.order,
    };
};

export default connect(mapStateToProps, {
    addServiceToOrder,
    removeServicesFromOrder,
    onSearch
})(
    withTranslation()(ServiceItems),
);
