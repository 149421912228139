import {
    GET_EMPLOYEE_SCHEDULE,
    ON_ACTIVE_DATE_CHANGE,
    ON_OVERLAY_LOADING,
    ON_SCHEDULE_DATE_SELECT,
    RESET_SCHEDULE,
    RESET_STATE,
    SET_NEW_WEEK_DATES,
    SET_SCHEDULE_EDIT_ACTIVE,
    SET_SCHEDULE_VIEW_ACTIVE,
} from '../actions/type'
import moment from 'moment'


const INITIAL_STATE = {
    items_loaded: false,
    overlay_loading: false,
    items: [],
    selected_date: null,
    active_date: '',
    week_start: null,
    week_end: null,
    active_schedule_view: false,
    edit_schedule_view: false,
    dates_loaded: false,
}

const scheduleReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_EMPLOYEE_SCHEDULE:
            return {
                ...state,
                items_loaded: true,
                items: action.payload.available_times,
                week_start: action.payload.start_date,
                week_end: action.payload.end_date,
                selected_date: action.payload.selected_date,
                dates_loaded: true,
            }
        case SET_NEW_WEEK_DATES:
            let dates_loaded = false

            if (action.payload.start_date && action.payload.end_date && state.selected_date) {
                const week_start = moment(action.payload.start_date)
                const week_end = moment(action.payload.end_date)

                const selected_date = moment(state.selected_date)

                dates_loaded = selected_date.isBetween(week_start, week_end)
            }

            return {
                ...state,
                week_start: action.payload.start_date,
                week_end: action.payload.end_date,
                dates_loaded,
            }
        case ON_SCHEDULE_DATE_SELECT:
            return {
                ...state,
                active_date: action.payload,
                selected_date: action.payload,
            }
        case SET_SCHEDULE_VIEW_ACTIVE:
            return {
                ...state,
                active_schedule_view: action.payload,
            }
        case SET_SCHEDULE_EDIT_ACTIVE:
            return {
                ...state,
                edit_schedule_view: action.payload,
            }
        case ON_ACTIVE_DATE_CHANGE:
            return {
                ...state,
                active_date: action.payload,
            }
        case ON_OVERLAY_LOADING:
            return {
                ...state,
                overlay_loading: action.payload,
            }
        case RESET_SCHEDULE:
        case RESET_STATE:
            return INITIAL_STATE
        default:
            return state
    }
}

export default scheduleReducer
