import React from 'react';
import CategoryItem from './CategoryItem';
import connect from 'react-redux/es/connect/connect';
import {onCategoriesDeselect, onCategoriesSelect} from '../actions/categories';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import search from '../helpers/search';
import NoSearchResults from "./NoSearchResults";
import {onSearch} from '../actions/search';

class CategoryItems extends React.Component {
    onCategoryItemChange = (value, isChecked) => {
        if (isChecked) {
            this.props.onCategoriesSelect(value);

            if (value === 0)
                this.props.history.push(this.props.services_path);
        } else {
            this.props.onCategoriesDeselect(value);
        }
    };

    renderCategories = () => {
        const {t, i18n} = this.props;
        const curLang = i18n.languages[0];

        let filtered_locations = [...this.props.items];

        if (this.props.categories.services_total) {
            filtered_locations.unshift({
                id: 0,
                name: {
                    [curLang]: t('categories:all_services'),
                },
                services_total: this.props.services_total,
            });
        }

        if (this.props.search.keyword) {
            filtered_locations = filtered_locations.filter(
                item => search(
                    String(item.name[curLang] || item.name[Object.keys(item.name)[0]]),
                    this.props.search.keyword,
                ),
            );
        }

        if (!filtered_locations.length && this.props.search.keyword) {
            return (
                <NoSearchResults
                    text={t('general:no_search_results', {keyword: this.props.search.keyword})}
                    buttonText={t('general:back')}
                    onClick={() => this.props.onSearch('')} />
            )
        }

        return filtered_locations.map((item) => {
            return (
                <CategoryItem
                    onChange={this.onCategoryItemChange}
                    checked={this.props.categories.ids.indexOf(item.id) !== -1}
                    value={item.id}
                    key={item.id}
                    services_total={item.services_total}>
                    {item.name[curLang] || item.name[Object.keys(item.name)[0]]}
                </CategoryItem>
            );
        });
    };

    render() {
        return (
            <React.Fragment>
                {this.renderCategories()}
            </React.Fragment>
        );
    }
}

CategoryItems.defaultProps = {
    items: [],
    services_total: 0,
};

const mapStateToProps = (state) => {
    return {
        services_path: state.routes.paths.services,
        search: state.search,
        categories: state.categories,
    };
};

const routerWrap = withRouter(CategoryItems);

export default connect(mapStateToProps, {
    onCategoriesSelect,
    onCategoriesDeselect,
    onSearch
})(
    withTranslation()(routerWrap),
);
