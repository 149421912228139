import {ON_SLUG_CHANGE} from './type';

export const slugChange = slug => {
    return dispatch => {
        dispatch({
            type: ON_SLUG_CHANGE,
            payload: slug,
        });
    };
};
