import {apiDomain, widgetDomain} from '../env'
import Backend from 'i18next-xhr-backend'
import requireMomentLocale from '../helpers/requireMomentLocale'

let default_language = 'en'
let preload_languages = ['et', 'en']

export const setDefaultLanguage = lang => {
    default_language = lang

    requireMomentLocale(lang)
}

export const setPreloadLanguages = langs => {
    preload_languages = langs
}

const i18n_options = group => {
    let transPath = apiDomain + '/online_booking/' + group + '/trans'
    if (!group) {
        transPath = widgetDomain + '/trans.json'
    }

    return {
        backend: {
            backend: Backend,
            backendOption: {
                loadPath: transPath + '?lang={{lng}}&ns={{ns}}',
                //addPath: apiDomain + 'locales/add/{{lng}}/{{ns}}',
                //loadPath: widgetDomain + '/locales/resources.json?lng={{lng}}&ns={{ns}}',
                //addPath: widgetDomain + 'locales/add/{{lng}}/{{ns}}',

                // define a custom xhr function
                // can be used to support XDomainRequest in IE 8 and 9
                //
                // 'url' will be passed the value of 'loadPath'
                // 'options' will be this entire options object
                // 'callback' is a function that takes two parameters, 'data' and 'xhr'.
                //            'data' should be the key:value translation pairs for the
                //            requested language and namespace, or null in case of an error.
                //            'xhr' should be a status object, e.g. { status: 200 }
                // 'data' will be a key:value object used when saving missing translations
                //ajax: function (url, options, callback, data) {},

                // adds parameters to resource URL. 'example.com' -> 'example.com?v=1.3.5'
                queryStringParams: {
                    v: Number((new Date().getTime()) / 1000).toFixed(0),
                },

                // path to post missing resources

                // your backend server supports multiloading
                // /locales/resources.json?lng=de+en&ns=ns1+ns2
                // Adapter is needed to enable MultiLoading https://github.com/i18next/i18next-multiload-backend-adapter
                // Returned JSON structure in this case is
                // {
                //  lang : {
                //   namespaceA: {},
                //   namespaceB: {},
                //   ...etc
                //  }
                // }
                allowMultiLoading: true, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading

                keySeparator: true,

                // parse data after it has been fetched
                // in example use https://www.npmjs.com/package/json5
                // here it removes the letter a from the json (bad idea)
                //parse: function(data) { return data.replace(/a/g, ''); },

                // allow cross domain requests
                crossDomain: true,

                // allow credentials on cross domain requests
                withCredentials: false,

                // overrideMimeType sets request.overrideMimeType("application/json")
                overrideMimeType: false,
            },
        },

        lng: default_language,
        fallbackLng: 'en',
        debug: false,
        // path where resources get loaded from, or a function
        // returning a path:
        // function(lngs, namespaces) { return customPath; }
        // the returned path will interpolate lng, ns if provided like giving a static path

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            prefix: '{{',
            suffix: '}}',
        },

        ns: ['general', 'location', 'categories', 'services', 'employees', 'schedule', 'customer', 'booking', 'cancel', 'error', 'review', 'terms'],

        preload: preload_languages,

        defaultNS: 'general',

        react: {
            wait: false,
            useSuspense: false,
        },
    }
}

export default i18n_options
