import {isWeb, salon_slug as envSalonSlug} from '../env';

/**
 * constant path_components defines path routes
 *
 * categories => /categories OR /:salon_slug/categories
 * services => /services OR /:salon_slug/services
 *
 * And so on...
 *
 * Exceptional is home path, which is always => /
 */
export const path_components = {
    home: 'Location',
    categories: 'Categories',
    services: 'Services',
    employees: 'Employees',
    schedule: 'Schedule',
    customer: 'Customer',
    terms: 'Terms',
    review: 'Review',
    booking: 'Booking',
    error: 'Error',
    cancel: 'Cancel',
};

export const getPath = (route, salon_slug) => {
    return (isWeb && salon_slug ? '/' + salon_slug : '') + route;
};

export const get_app_routes = (hasSalons = true) => {
    const app_routes = {};

    const pathPrefix = (isWeb && hasSalons ? '/:salon_slug' : '');

    Object.keys(path_components).forEach(key => {
        let path;
        let object_key;
        if (key === 'home') {
            object_key = '/';
            path = object_key;
        } else if (key === 'categories' && pathPrefix) {
            object_key = '/' + key;
            path = pathPrefix;
        } else if (key === 'cancel' && pathPrefix) {
            object_key = '/cancel-booking';
            path = pathPrefix + '/cancel-booking/:hash';
        } else {
            object_key = '/' + key;
            path = pathPrefix + object_key;
        }

        app_routes[object_key] = {
            path,
            component: path_components[key],
        }
    });

    return app_routes;
};

export const initPaths = (slug = '') => {
    if (isWeb && !slug)
        slug = envSalonSlug;

    const paths = {};

    Object.keys(path_components).forEach(key => {
        let path;
        if (key === 'home' || (key === 'categories' && slug))
            path = '/';
        else if (key === 'cancel' && slug) {
            key = 'cancel-booking';
            path = '/cancel-booking/:hash';
        } else
            path = '/' + key;

        paths[key] = getPath(path, (key !== 'home' ? slug : ''));
    });

    return paths;
};
