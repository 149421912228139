import axios from 'axios'

const CancelToken = axios.CancelToken
const cancelRequest = {}
const cancelTimeout = {}

export const registerTheRequest = name => {
    return new CancelToken(c => {
        cancelRequest[name] = c
    })
}

export const registerTimeout = (name, callback, timeout = 60000) => {
    if (typeof callback === 'function')
        cancelTimeout[name] = setTimeout(callback, timeout)
}

export const cancelTheRequest = name => {
    if (typeof cancelRequest[name] === 'function') {
        cancelRequest[name]()
        cancelRequest[name] = undefined
    }

    if (cancelTimeout[name])
        clearTimeout(cancelTimeout[name])
}
