import React, {Component, Fragment} from 'react'
import {Field, Form} from 'redux-form'
import style from '../scss/components/SalonCode.module.scss'
import DigitInput from './DigitInput'
import {ReactComponent as HourGlass} from '../assets/svg/hourglass.svg'
import {ReactComponent as Lock} from '../assets/svg/lock.svg'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'

class InsertCodeContent extends Component {
    renderLocked() {
        const {t} = this.props

        return (
            <Fragment>
                <HourGlass className={style.HourGlass}/>
                <div className={style.Title}>
                    {t('general:locked')}
                </div>
                <div className={style.Description}>
                    {t('general:locked_description')}
                </div>
            </Fragment>
        )
    }

    renderDefaultText() {
        const {t} = this.props

        return (
            <Fragment>
                <Lock className={style.Lock}/>
                <div className={style.Title}>
                    {t('general:code_protected')}
                </div>
                <div className={style.Description}>
                    {t('general:code_protected_description')}
                </div>
            </Fragment>
        )
    }

    renderInfo() {
        if (this.props.next_try && this.props.next_try > this.props.current_time)
            return this.renderLocked()

        return this.renderDefaultText()
    }

    renderError() {
        const {t} = this.props

        if (this.props.wrong_code)
            return (
                <div className={style.WrongCode}>
                    {t('general:wrong_code')}
                </div>
            )

        return null
    }

    render() {
        return (
            <Fragment>
                {this.renderInfo()}
                <Form onSubmit={this.props.handleSubmit(this.props.onSubmit)} className={style.InputWrapper}>
                    <Field name={'code'}
                           length={this.props.length}
                           component={DigitInput}
                           failed={this.props.wrong_code}
                           blurOnEnd={true}
                           disabled={this.props.next_try && this.props.next_try > this.props.current_time}
                           autoFocus={this.props.code_enabled && !this.props.code}
                           onChange={this.props.onChange}
                           isHidden={false}/>
                    {this.renderError()}
                </Form>
            </Fragment>
        )
    }
}

InsertCodeContent.propTypes = {
    onChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    code_enabled: PropTypes.bool.isRequired,
    code: PropTypes.string.isRequired,
    wrong_code: PropTypes.bool.isRequired,
    length: PropTypes.number.isRequired,
    next_try: PropTypes.number,
    current_time: PropTypes.any.isRequired,
}

InsertCodeContent = withTranslation(['general', 'location'])(InsertCodeContent)

export default InsertCodeContent
