import React from 'react';
import classNames from 'classnames';
import loadingStyle from '../scss/components/Loading.module.scss';

class Loading extends React.Component {
    render() {
        const className = classNames('Loading', loadingStyle.Loading, {
            [loadingStyle.full_screen]: this.props.full_screen,
        });

        return (
            <div className={className}>
                <div className={loadingStyle.Loader}/>
            </div>
        );
    }
}

Loading.defaultProps = {
    full_screen: false,
};

export default Loading;
