import React from 'react';
import style from "../scss/components/NoResults.module.scss";
import {ReactComponent as NoResultsIcon} from "../assets/svg/no-search-results.svg";
import PropTypes from "prop-types";

class NoSearchResults extends React.Component {
    render() {
        return (
            <div className={style.Wrap}>
                <NoResultsIcon/>
                <div className={style.Text}>
                    {this.props.text}
                </div>
                <div className={style.Button} onClick={this.props.onClick}>
                    {this.props.buttonText}
                </div>
            </div>
        )
    }
}

NoSearchResults.propTypes = {
    text: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default NoSearchResults;
