import {getSalonSlug, isWeb} from '../env';

const getApiPath = (routes_state, widget_state, location_state = null) => {
    const identifier = widget_state.identifier;
    const group_slug = widget_state.group_slug;

    let url = '/online_booking/' + identifier;
    if (group_slug)
        url = '/online_booking/' + group_slug;

    if (location_state) {
        let salon_identifier = location_state.value;

        const salon_slug = getSalonSlug();
        if (isWeb && salon_slug && salon_slug !== location_state?.selected_location?.slug && routes_state.active_payload !== '')
            salon_identifier = salon_slug;

        url += '/s/' + salon_identifier;
    }

    return url;
};

export default getApiPath
