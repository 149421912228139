import React from 'react';
import ReactPageComponent, {connect} from '../components/ReactPageComponent';
import {withTranslation} from 'react-i18next';
import {setTransGroup} from '../actions/userInterface';
import style from '../scss/components/Terms.module.scss';
import {Redirect} from 'react-router-dom';

class Terms extends ReactPageComponent {
    unmounted = false;
    state = {
        loading: true,
    };

    scrollPosKey = 'Terms';

    componentDidMount() {
        super.componentDidMount();

        this.props.setTransGroup('terms');
    }

    renderTerms() {
        const {t} = this.props;
        const content = t('content');

        if (this.props.settings['my_salon_group.customer.terms_of_service'])
            return (
                <div className={style.Block}
                     dangerouslySetInnerHTML={{__html: content}}/>
            );

        return null;
    }

    render() {
        if (this.props.errorCode)
            return <Redirect to={this.props.error_path}/>;

        return (
            <div className={style.Terms}>
                {this.renderTerms()}
            </div>
        );

    }
}

const mapStateToProps = (state) => {
    return {
        errorCode: state.booking.errorCode,
        error_path: state.routes.paths.error,
        settings: state.salon_location.group_settings,
    };
};

const TranslatedTerms = withTranslation(['terms'])(Terms);

export default connect(mapStateToProps, {
    setTransGroup,
})(TranslatedTerms);
