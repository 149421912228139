import React from 'react';
import styleFooter from '../scss/components/Footer.module.scss';
import connect from 'react-redux/es/connect/connect';
import {matchPath, withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {isSubmitting, isValid, submit} from 'redux-form';
import Loading from './Loading';
import {confirmBooking} from '../actions/booking';
import {withTranslation} from 'react-i18next';

class MoveForwardButton extends React.Component {
    onClick = nextPath => {
        if (!this.props.form)
            this.props.history.push(nextPath);
        else
            this.props.submitForm();
    };

    renderArrow() {
        if (this.props.isSubmitting)
            return <Loading/>;

        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path fillRule="nonzero"
                      d="M7.295.705a.998.998 0 0 0 0 1.41L12.17 7H1a1 1 0 0 0 0 2h11.17l-4.88 4.87a1.003 1.003 0 0 0 0 1.42 1.004 1.004 0 0 0 1.42 0l6.583-6.583a1 1 0 0 0 0-1.414L8.705.705a.997.997 0 0 0-1.41 0z"/>
            </svg>
        );
    }

    render() {
        let classes = styleFooter.NextSection;
        let nextPath = this.props.paths.home;
        let showSubmitButton = false;

        if (this.props.salon_location.value && this.routeIs('/')) {
            classes += ' ' + styleFooter.Show;
            nextPath = this.props.paths.categories;

            if (!this.props.group_settings['my_salon_group.services.show.categories']) {
                nextPath = this.props.paths.services;
            }
        } else if (this.props.categories.ids.length && this.routeIs('/categories')) {
            classes += ' ' + styleFooter.Show;
            nextPath = this.props.paths.services;
        } else if (this.props.order.ids.length && this.routeIs('/services')) {
            classes += ' ' + styleFooter.Show;
            nextPath = this.props.paths.employees;
        } else if (this.props.order.selected_employee && this.routeIs('/employees')) {
            classes += ' ' + styleFooter.Show;
            nextPath = this.props.paths.schedule;
        } else if (this.props.order.selected_date && this.props.order.selected_time && !this.props.edit_schedule_view && this.routeIs('/schedule')) {
            classes += ' ' + styleFooter.Show;
            nextPath = this.props.paths.customer;
        } else if (/*this.props.customerFormValid && */this.routeIs('/customer')) {
            if (!this.props.group_settings['my_salon_group.booking.ask_confirmation']) {
                classes += ' ' + styleFooter.Show + ' ' + styleFooter.ConfirmButton;
                showSubmitButton = true;
            } else {
                classes += ' ' + styleFooter.Show;
                nextPath = this.props.paths.review;
            }
        }

        if (this.props.isSubmitting)
            classes += ' ' + styleFooter.Disabled;

        if (showSubmitButton) {
            const {t} = this.props;

            return (
                <div onClick={() => this.props.submitForm()} className={classes}>
                    <span>{t('confirm_booking')}</span>
                </div>
            );
        }

        return (
            <div onClick={() => this.onClick(nextPath)} className={classes}>
                {this.renderArrow()}
            </div>
        );
    }

    routeIs = (path = '/') => {
        return matchPath(this.props.location.pathname, {
            path: this.props.app_routes[path].path,
            exact: true,
        });
    };
}

MoveForwardButton.defaultProps = {};

const mapStateToProps = (state, props) => {
    return {
        salon_location: state.salon_location,
        paths: state.routes.paths,
        app_routes: state.routes.app_routes,
        categories: state.categories,
        order: state.order,
        edit_schedule_view: state.schedule.edit_schedule_view,
        isSubmitting: props.form ? isSubmitting(props.form)(state) : false,
        customerFormValid: props.form ? state.form[props.form] && isValid(props.form)(state) : false,
        group_settings: state.salon_location.group_settings,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return bindActionCreators({
        submitForm: () => props.form ? submit(props.form) : () => {
        },
        confirmBooking,
    }, dispatch);
};

const routerTranslated = withTranslation(['review'])(MoveForwardButton);
const routerWrap = withRouter(routerTranslated);

export default connect(mapStateToProps, mapDispatchToProps)(routerWrap);
