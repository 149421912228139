import {GET_CATEGORIES, ON_CATEGORIES_DESELECT, ON_CATEGORIES_SELECT, RESET_STATE} from '../actions/type'

const INITIAL_STATE = {
    ids: [],
    items_loaded: false,
    items: [],
    services_total: 0,
    names: {},
}

const categoriesReducer = (state = INITIAL_STATE, action) => {
    let ids, items
    switch (action.type) {
        case ON_CATEGORIES_SELECT:
            ids = []
            if (action.payload.indexOf(0) === -1) {
                let stateIds = [...state.ids]
                if (state.ids.indexOf(0) !== -1) {
                    stateIds = stateIds.filter(id => id !== 0)
                }

                ids = [...stateIds, ...action.payload]
            } else {
                ids = [0]
            }

            return {
                ...state,
                ids,
            }
        case GET_CATEGORIES:
            const category_names = {}
            items = action.payload.data

            items.forEach(item => {
                category_names[item.id] = item.name
            })

            return {
                ...state,
                items_loaded: true,
                services_total: action.payload.services_total,
                items: items,
                names: category_names,
            }
        case ON_CATEGORIES_DESELECT:
            return {
                ...state,
                ids: state.ids.filter(id => !action.payload.includes(id)),
            }
        case RESET_STATE:
            return INITIAL_STATE
        default:
            return state
    }
}

export default categoriesReducer
