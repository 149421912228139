import {
    POST_EMPLOYEE_CODE,
    POST_SALON_CODE,
    RESET_SALON_CODE,
    RESET_STATE,
    SET_CODE_EMPLOYEE_ID,
    SET_EMPLOYEE_CODE_NEXT_TRY,
    SET_SALON_CODE_NEXT_TRY,
} from '../actions/type'

const INITIAL_STATE = {
    salon: {
        code: '',
        next_try: null,
    },
    employee: {
        item: {},
        code: '',
        next_try: null,
    },
}

const restrictionCodeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case POST_SALON_CODE:
            return {
                ...state,
                salon: {
                    ...state.salon,
                    ...action.payload,
                },
            }
        case RESET_SALON_CODE:
            return {
                ...state,
                salon: INITIAL_STATE.salon,
            }
        case POST_EMPLOYEE_CODE:
            return {
                ...state,
                employee: {
                    ...state.employee,
                    ...action.payload,
                },
            }
        case SET_SALON_CODE_NEXT_TRY:
            return {
                ...state,
                salon: {
                    ...state.salon,
                    next_try: action.payload,
                },
            }
        case SET_EMPLOYEE_CODE_NEXT_TRY:
            return {
                ...state,
                employee: {
                    ...state.employee,
                    next_try: action.payload,
                },
            }
        case SET_CODE_EMPLOYEE_ID:
            let employee_state = {...state.employee}

            if (state.employee.item?.id !== action.payload?.id)
                employee_state = {
                    ...INITIAL_STATE.employee,
                    next_try: state.employee.next_try,
                    item: action.payload,
                }

            return {
                ...state,
                employee: employee_state,
            }
        case RESET_STATE:
            return INITIAL_STATE
        default:
            return state
    }
}

export default restrictionCodeReducer
