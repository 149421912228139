import {
    GET_LOCATIONS,
    ON_GROUP_SETTINGS_UPDATE,
    ON_LOCATION_CHANGE,
    ON_START_NEW_BOOKING,
    RESET_STATE,
    SALON_CODE_IS_REQUIRED,
} from '../actions/type'

const INITIAL_STATE = {
    value: null,
    selected_location: {},
    items_loaded: false,
    items: [],
    group_settings: {},
    background: {},
    title: 'SalonLife',
}

const salonLocationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_LOCATIONS:
            return {
                ...state,
                items_loaded: true,
                items: action.payload.data,
                group_settings: action.payload.group_settings,
                background: action.payload.background,
                title: action.payload.title,
            }
        case ON_LOCATION_CHANGE:
            const selectedLocation = action.payload.selectedLocation

            return {
                ...state,
                value: selectedLocation?.id ?? null,
                selected_location: selectedLocation ?? {},
            }
        case ON_GROUP_SETTINGS_UPDATE:
            return {
                ...state,
                group_settings: {
                    ...state.group_settings,
                    ...action.payload,
                },
            }
        case SALON_CODE_IS_REQUIRED:
            return {
                ...state,
                selected_location: {
                    ...state.selected_location,
                    settings: {
                        ...state.selected_location.settings,
                        ...action.payload,
                    },
                },
            }
        case ON_START_NEW_BOOKING:
            return {
                ...INITIAL_STATE,
                background: state?.background ?? INITIAL_STATE.background,
            }
        case RESET_STATE:
            return {
                ...INITIAL_STATE,
                background: state?.background ?? INITIAL_STATE.background,
                items_loaded: state.items_loaded,
                items: state.items,
                group_settings: state.group_settings,
            }
        default:
            return state
    }
}

export default salonLocationReducer
