import {ON_SEARCH} from '../actions/type'

const INITIAL_STATE = {
    keyword: '',
}

const searchReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ON_SEARCH:
            return {
                ...state,
                keyword: action.payload,
            }
        default:
            return state
    }
}

export default searchReducer
