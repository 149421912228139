import {
    CLOSE_DIALOG,
    CONFIRM_REQUIRED,
    ON_LANGUAGE_CHANGE,
    ON_PATH_CHANGE,
    ON_PROGRESS_ACTIVE_CHANGE,
    ON_PROGRESS_CHANGE,
    ON_REGISTER_PERSIST_TIMESTAMP,
    ON_SALON_CHANGE,
    ON_TRANS_GROUP_CHANGE,
    OPEN_DIALOG,
    RESET_STATE,
    SCROLL_POS_CHANGE,
    TOGGLE_CONTAINER_SCROLL,
} from '../actions/type'

const INITIAL_STATE = {
    container_scrollable: true,
    trans_group: 'location',
    selected_language: null,
    salon_name: null,
    progress: {
        active: 0,
        total: 6,
    },
    confirm: {
        data: {
            title: '',
            description: '',
            confirm_text: null,
            cancel_text: null,
            onConfirm: null,
        },
        show: false,
    },
    dialog: {
        data: {
            title: '',
            description: '',
            employee: {},
        },
        show: false,
    },
    scroll_positions: {
        Categories: 0,
        Location: 0,
        Services: 0,
    },
    persist_timestamp: 0,
    version: require('../../package.json').version,
    currentPath: '/',
}

const userInterfaceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ON_TRANS_GROUP_CHANGE:
            return {
                ...state,
                trans_group: action.payload,
            }
        case ON_LANGUAGE_CHANGE:
            return {
                ...state,
                selected_language: action.payload,
            }
        case ON_SALON_CHANGE:
            return {
                ...state,
                salon_name: action.payload,
            }
        case ON_PROGRESS_CHANGE:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    total: action.payload,
                },
            }
        case ON_PROGRESS_ACTIVE_CHANGE:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    active: action.payload,
                },
            }
        case TOGGLE_CONTAINER_SCROLL:
            return {
                ...state,
                container_scrollable: action.payload,
            }
        case CONFIRM_REQUIRED:
            let confirmData = action.payload.data
            let showConfirm = true

            if (confirmData === null) {
                confirmData = INITIAL_STATE.confirm.data
                showConfirm = false
            }

            confirmData.onConfirm = action.payload.onConfirm

            return {
                ...state,
                confirm: {
                    data: confirmData,
                    show: showConfirm,
                },
            }
        case OPEN_DIALOG:
            return {
                ...state,
                dialog: {
                    data: action.payload,
                    show: true,
                },
            }
        case CLOSE_DIALOG:
            return {
                ...state,
                dialog: INITIAL_STATE.dialog,
            }
        case SCROLL_POS_CHANGE:
            return {
                ...state,
                scroll_positions: {
                    ...state.scroll_positions,
                    [action.payload.key]: action.payload.value,
                },
            }
        case ON_REGISTER_PERSIST_TIMESTAMP:
            return {
                ...state,
                persist_timestamp: action.payload.timestamp,
                version: action.payload.version,
            }
        case ON_PATH_CHANGE:
            return {
                ...state,
                currentPath: action.payload,
            }
        case RESET_STATE:
            return INITIAL_STATE
        default:
            return state
    }
}

export default userInterfaceReducer
