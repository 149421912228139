import {cancelTheRequest, registerTheRequest} from '../helpers/axiosCancel'
import salonlife from '../api/salonlife'
import getApiPath from '../helpers/getApiPath'
import {POST_EMPLOYEE_CODE, POST_SALON_CODE, SET_CODE_EMPLOYEE_ID, SET_SALON_CODE_NEXT_TRY} from './type'
import {addEmployeeToOrder} from './order'

export const setEmployee = item => {
    return async dispatch => {
        dispatch({
            type: SET_CODE_EMPLOYEE_ID,
            payload: item,
        })
    }
}

export const clearNextTry = (type = SET_SALON_CODE_NEXT_TRY) => {
    return async dispatch => {
        dispatch({
            type: SET_SALON_CODE_NEXT_TRY,
            payload: null,
        })
    }
}

export const postEmployeeCode = (employee_id, formValues) => {
    const uniqueKey = 'postEmployeeCode'
    cancelTheRequest(uniqueKey)

    return async (dispatch, getState) => {
        const state = getState()

        return await salonlife.post(getApiPath(state.routes, state.widget, state.salon_location) + '/employee_code/' + employee_id, formValues, {
            cancelToken: registerTheRequest(uniqueKey),
        })
            .then(response => {
                addEmployeeToOrder(getState().restriction_code.employee.item)(dispatch, getState)

                dispatch({
                    type: POST_EMPLOYEE_CODE,
                    payload: {
                        id: parseInt(employee_id),
                        code: String(formValues.code),
                        next_try: null,
                        item: {}
                    },
                })

                return response
            }).catch(error => {
                if (!error.response)
                    return error

                if (error.response.status === 401) {
                    dispatch({
                        type: POST_EMPLOYEE_CODE,
                        payload: {
                            code: '',
                            next_try: null,
                        }
                    });
                } else if (error.response.status === 403) {
                    dispatch({
                        type: POST_EMPLOYEE_CODE,
                        payload: {
                            code: '',
                            next_try: new Date(error.response.data.next_try * 1000).getTime(),
                        }
                    })
                }

                throw error
            })
    }
}

export const postSalonCode = formValues => {
    const uniqueKey = 'postSalonCode'
    cancelTheRequest(uniqueKey)

    return async (dispatch, getState) => {
        const state = getState()

        return await salonlife.post(getApiPath(state.routes, state.widget, state.salon_location) + '/salon_code', formValues, {
            cancelToken: registerTheRequest(uniqueKey),
        })
            .then(response => {
                dispatch({
                    type: POST_SALON_CODE,
                    payload: {
                        code: String(formValues.code),
                        next_try: null,
                    },
                })

                return response
            }).catch(error => {
                if (!error.response)
                    return error

                if (error.response.status === 401) {
                    dispatch({
                        type: POST_SALON_CODE,
                        payload: {
                            code: '',
                            next_try: null,
                        }
                    });
                } else if (error.response.status === 403) {
                    dispatch({
                        type: POST_SALON_CODE,
                        payload: {
                            code: '',
                            next_try: new Date(error.response.data.next_try * 1000).getTime(),
                        }
                    })
                }

                throw error
            })
    }
}
