export const invertHex = hex => {
    hex = hex.replace('#', '')

    return '#' + (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase()
}

export const rgbToHex = rgb => {
    return ((rgb.r).toString(16) + (rgb.g).toString(16) + (rgb.b).toString(16))
}

export const hexToRgb = color => {
    const colors = {
        r: 0,
        g: 0,
        b: 0,
    }

    if (color.match(/^rgb/)) {
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

        colors.r = color[1]
        colors.g = color[2]
        colors.b = color[3]
    } else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +('0x' + color.slice(1).replace(
            color.length < 5 && /./g, '$&$&'))

        colors.r = (color >> 16)
        colors.g = (color >> 8) & 255
        colors.b = color & 255
    }

    return colors
}

export const makeRgbValid = (object) => {
    ['r', 'g', 'b'].forEach(item => {
        if (object[item] < 0) {
            object[item] = 0
        } else if (object[item] > 255) {
            object[item] = 255
        }
    })

    return object
}

export const objectToRgba = (object, a = 1) => {
    object = makeRgbValid(object)

    if (a > 1)
        a = parseInt(a) / 100

    return 'rgba(' + object.r + ', ' + object.g + ', ' + object.b + ', ' + parseFloat(a).toFixed(2) + ')'
}

export const objectToRgb = (object) => {
    object = makeRgbValid(object)

    return 'rgb(' + object.r + ', ' + object.g + ', ' + object.b + ')'
}

export const getLuminance = rgb => {
    return 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b
}

export const isQuiteBlack = rgb => {
    return getLuminance(rgb) < 40
}

export const colorIsLight = rgb => {
    return (((rgb.r * 299) + (rgb.g * 587) + (rgb.b * 114)) / 1000) > 205 // Default was 155 instead of 205
}

const isHex = input => {
    input = String(input).toLowerCase()

    if (String(input).charAt(0) === '#')
        input = input.substr(1)

    const re = /[0-9A-Fa-f]{6}/g

    return re.test(input)
}

export const getColors = (main_color) => {
    if (isHex(main_color)) {
        main_color = hexToRgb(main_color)
    }

    const isBlack = isQuiteBlack(main_color)

    // rgb(115, 103, 249) - primary
    //  rgb 125 114 249

    const cornflower_alt = {
        r: main_color.r + 10,
        g: main_color.g + 11,
        b: main_color.b,
    }

    if (cornflower_alt.r > 255)
        cornflower_alt.r = main_color.r - 10

    if (cornflower_alt.g > 255)
        cornflower_alt.g = main_color.g - 11

    let lavender_blue = {
        r: main_color.r + 33,
        g: main_color.g + 37,
        b: main_color.b,
    }

    if (getLuminance(lavender_blue) < 150) {
        lavender_blue = {
            r: main_color.r + 33,
            g: main_color.g + 137,
            b: main_color.b + 100,
        }
    }

    if (lavender_blue.r > 255)
        lavender_blue.r = 255

    if (lavender_blue > 255)
        lavender_blue.g = 255

    const purpleish_color = {
        r: main_color.r - 25,
        g: main_color.g - 26,
        b: main_color.b - 24,
    }

    if (purpleish_color.r < 0)
        purpleish_color.r = main_color.r + 25

    if (purpleish_color.g < 0)
        purpleish_color.g = main_color.g + 26

    if (purpleish_color.b < 0)
        purpleish_color.b = main_color.b + 24

    const header_shadow = {
        r: main_color.r - 32,
        g: main_color.g - 30,
        b: main_color.b - 53,
    }
    if (isBlack) {
        header_shadow.r = 255
        header_shadow.g = 255
        header_shadow.b = 255
    } else {
        if (header_shadow.r < 0)
            header_shadow.r = main_color.r + 32

        if (header_shadow.g < 0)
            header_shadow.g = main_color.g + 30

        if (header_shadow.b < 0)
            header_shadow.b = main_color.b + 53
    }

    let secondary_color = {
        r: main_color.r + 36,
        g: main_color.g + 60,
        b: main_color.b + 31,
    }

    if (secondary_color.r > 255)
        secondary_color.r = 255

    if (secondary_color.g > 255)
        secondary_color.g = 255

    if (secondary_color.b > 255)
        secondary_color.b = 255

    const cornflower = {
        r: main_color.r - 7,
        g: main_color.g - 6,
        b: main_color.b - 9,
    }

    if (cornflower.r < 0) {
        cornflower.r = main_color.r + 7
    }

    if (cornflower.g < 0) {
        cornflower.g = main_color.g + 6
    }

    if (cornflower.b < 0) {
        cornflower.b = main_color.b + 9
    }

    let header_primary_font_color = '#fcfdfd'
    let white = '#ffffff'
    let white_alt = '#fcfcff'
    let brand_text = '#152D4A'
    let secondary_text = '#8e9aa8'
    let selected_color = '#eff2f4'
    let crossed_red = '#f5315d'
    let isLight = false
    if (colorIsLight(main_color)) {
        isLight = true
        header_primary_font_color = invertHex(header_primary_font_color)
        secondary_color = invertHex(rgbToHex(secondary_color))
        white = invertHex(white)
        white_alt = invertHex(white_alt)
        brand_text = invertHex(brand_text)
        secondary_text = invertHex(secondary_text)
        selected_color = invertHex(selected_color)
        crossed_red = '#db1010'
    } else {
        secondary_color = objectToRgba(secondary_color)
    }

    return {
        SalonLife__primary_color: objectToRgb(main_color),
        SalonLife__primary_color_alpha_22: objectToRgba(main_color, 22),
        SalonLife__primary_color_alpha_50: objectToRgba(main_color, 50),
        SalonLife__header_shadow: objectToRgba(header_shadow, 24),
        SalonLife__primary_shadow: objectToRgba(main_color, 35),
        SalonLife__secondary_color: secondary_color,
        SalonLife__cornflower: objectToRgb(cornflower),
        SalonLife__cornflower_alt: objectToRgb(cornflower_alt),
        SalonLife__purpleish_color: objectToRgb(purpleish_color),
        SalonLife__purpleish_color_alpha: objectToRgba(purpleish_color, 0.32),
        SalonLife__lavender_blue: objectToRgb(lavender_blue),
        SalonLife__header_font_color: header_primary_font_color,
        SalonLife__white: white,
        SalonLife__white_alpha_0: objectToRgba(hexToRgb(white), 0),
        SalonLife__white_alpha_60: objectToRgba(hexToRgb(white), 0.6),
        SalonLife__white_alpha_70: objectToRgba(hexToRgb(white), 0.7),
        SalonLife__white_alt: white_alt,
        SalonLife__brand_text: brand_text,
        SalonLife__brand_text_alpha_13: objectToRgba(hexToRgb(brand_text), 0.13),
        SalonLife__brand_text_alpha_50: objectToRgba(hexToRgb(brand_text), 0.50),
        SalonLife__secondary_text: secondary_text,
        SalonLife__secondary_text__alpha_60: objectToRgba(hexToRgb(secondary_text), 0.6),
        SalonLife__secondary_text__alpha_14: objectToRgba(hexToRgb(secondary_text), isLight ? 0.54 : 0.14),
        SalonLife__secondary_text__alpha_84: objectToRgba(hexToRgb(secondary_text), 0.84),
        SalonLife__selected_color: objectToRgba(hexToRgb(selected_color), 0.9),
        SalonLife_selected_border_color: objectToRgba(hexToRgb(brand_text), isLight ? 0.23 : 0.06),
        SalonLife_selected_border_color_alt: objectToRgba(hexToRgb(brand_text), isLight ? 0.55 : 0.15),
        SalonLife_crossed_red: crossed_red,
    }
}
