const arrDiff = (arr1, arr2) => {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
        throw new Error('arrDiff - both parameters must be arrays')
    }

    const ret = []
    arr1.sort()
    arr2.sort()

    for (let i = 0; i < arr1.length; ++i) {
        if (arr2.indexOf(arr1[i]) !== -1) {
            ret.push(arr1[i])
        }
    }

    return ret
}

export default arrDiff
