import {LOADING} from "./type";

export const loading = (key = 'getLocations', value = true) => {
    return dispatch => {
        dispatch({
            type: LOADING,
            payload: {
                key,
                value
            }
        });
    };
};
