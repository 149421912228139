import React from 'react';
import {Redirect} from 'react-router-dom';
import ReactPageComponent, {connect} from '../components/ReactPageComponent';
import LocationItems from '../components/LocationItems';
import {getLocations, onChange} from '../actions/salonLocation';
import {withTranslation} from 'react-i18next';
import {saveScrollPos, setActiveProgress, setSalonName, setTransGroup} from '../actions/userInterface';
import {onSearch} from '../actions/search';
import Loading from '../components/Loading';

class Location extends ReactPageComponent {
    scrollPosKey = 'Location';

    state = {
        redirect_to_categories: false,
    };

    componentDidMount() {
        super.componentDidMount();

        this.props.setTransGroup('location');
        this.props.setActiveProgress(0);
        this.props.onSearch('');
        this.props.getLocations();

        this.redirectToCategoriesIfNecessary();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);

        this.redirectToCategoriesIfNecessary();
    }

    redirectToCategoriesIfNecessary() {
        if (this.props.salon_location.items.length === 1 && !this.state.redirect_to_categories) {
            const location_item = this.props.salon_location.items[0];

            this.props.onChange(location_item.id);
            this.props.setSalonName(location_item.name);

            this.setState({
                redirect_to_categories: true,
            });
        }
    }

    render() {
        if ((!this.props.salon_location.items_loaded || this.props.loading) && !this.props.maintenance)
            return <Loading/>;

        if (this.props.errorCode)
            return <Redirect to={this.props.error_path}/>;

        if (!this.props.salon_location.items.length)
            return null;

        else if (this.state.redirect_to_categories)
            return <Redirect to={this.props.categories_path}/>;

        else if (this.props.widget.client_web && this.props.salon_location.selected_location?.id && !this.props.widget.params_initialized)
            return <Redirect to={this.props.categories_path}/>;

        return (
            <LocationItems items={this.props.salon_location.items} value={this.props.salon_location.value}/>
        );
    }
}

Location.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        maintenance: state.location_lock.maintenance,
        error_path: state.routes.paths.error,
        salon_location: state.salon_location,
        errorCode: state.booking.errorCode,
        loading: state.loading.getLocations,
        categories_path: state.routes.paths.categories,
        widget: state.widget,
    };
};

const TranslatedLocation = withTranslation(['location'])(Location);

export default connect(mapStateToProps, {
    setSalonName,
    onChange,
    setTransGroup,
    onSearch,
    setActiveProgress,
    saveScrollPos,
    getLocations,
})(TranslatedLocation);
