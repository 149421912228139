import {SIZE_CHANGED} from '../actions/type'

const INITIAL_STATE = {
    width: window.innerWidth,
    height: window.innerHeight,
}

const windowReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIZE_CHANGED:
            return {
                ...state,
                width: action.payload.width,
                height: action.payload.height,
            }
        default:
            return state
    }
}

export default windowReducer
