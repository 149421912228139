import psl from 'psl';
import isIp from 'is-ip';

require('dotenv').config();

const location = window.location;
const hostname = location.hostname;
const pslHost = psl.parse(hostname);
const domain = pslHost.domain;
const isProduction = process.env.REACT_APP_IS_PRODUCTION === 'true';
//const isProduction = domain !== 'salonlife.co';
const app_domains = [
    'localhost',
    '127.0.0.1',
    'salon.life',
    'salonlife.co',
    'salonlife.lar',
    'salonlife.dev',
];

//export const isLocal = (hostname === 'localhost' || hostname === '127.0.0.1' || domain === 'salonlife.lar');
export const isDev = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
export const isLocalhost = (domain === 'salonlife.lar' || domain === 'localhost' || domain === null);
export const isLocal = (isDev || isLocalhost);

export const envSecure = (location.protocol === 'https:');
export const apiDomain = (isLocal ? 'http://localhost/api' : (isProduction ? 'https://app.salon.life/api' : 'https://app.salonlife.co/api'));
export const mainDomain = (isLocal ? 'http://localhost' : (isProduction ? 'https://app.salon.life' : 'https://app.salonlife.co'));
export const widgetDomain = (isDev ? '' : (isLocal ? 'http://widget.salonlife.lar' : (isProduction ? 'https://widget.salon.life' : 'https://widget.salonlife.co')));
export const widgetScrollContentId = 'SalonLifeWidget__Content_m4kW2-';
export const subdomain = (app_domains.indexOf(domain) !== -1 && !isIp(hostname) ? pslHost.subdomain : null);
export let isWeb = (!domain || (app_domains.indexOf(domain) && pslHost.subdomain));
export const salon_slug = (isWeb ? window.location.pathname.split('/')[1] : null);

export const setIsWeb = (value) => isWeb = value;
export const getSalonSlug = () => {
    return (isWeb ? window.location.pathname.split('/')[1] : null)
};
