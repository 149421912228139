import React from 'react'
import classNames from 'classnames';
import inputStyle from '../scss/components/FormInput.module.scss';

const FormInputField = props => {
    const {input, label, type, logo, meta: {active, touched, error}} = props;

    let inputRef;

    const onInputClick  = () => {
        inputRef.focus();
    };

    return (
        <div className={classNames(inputStyle.FormInput, {
            [inputStyle.Selected]: active,
            [inputStyle.Error]: (touched || props.hasSubmissionError) && error,
        })} onClick={onInputClick}>
            <span className={inputStyle.Logo}>
                {logo}
            </span>
            <label className={inputStyle.Label}>
                <span className={inputStyle.Text}>{label}</span>
                <input {...input} ref={ref => inputRef = ref} type={type} spellCheck="false" autoComplete="new-password"/>
            </label>
        </div>
    )
};

export default FormInputField;