import React from 'react';
import styleItem from '../scss/components/InputCheckItem.module.scss';
import connect from 'react-redux/es/connect/connect';
import {openDialog} from '../actions/userInterface';
import {withTranslation} from 'react-i18next';
import striptags from 'striptags';

class ServiceItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: this.props.checked ?? false,
        };
    }

    callOnChange(isChecked) {
        if (this.props.onChange && typeof this.props.onChange === 'function') {
            this.props.onChange(this.props.value, this.props.category_id, isChecked);
        }
    }

    componentDidMount() {
        this.setCheckedFromPropToState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setCheckedFromPropToState();
    }

    setCheckedFromPropToState() {
        if (this.state.checked !== this.props.checked)
            this.setState({
                checked: this.props.checked,
            });
    }

    toggleChecked = (e) => {
        const shouldPrevent = e.target.dataset.prevent_select === 'true';

        if (!shouldPrevent) {
            const inputState = !this.state.checked;

            this.setState({
                checked: inputState,
            });

            this.callOnChange(inputState);
        }
    };

    renderDescription() {
        const {t, children} = this.props;
        let description = this.props.description;

        if (!description)
            return null;

        description = striptags(String(description));

        if (description.length >= 43 || description !== String(this.props.description)) {
            description = (
                <React.Fragment>
                    {description.substring(0, 40)}...
                    <div className={styleItem.ReadMore}
                         data-prevent_select={true}
                         onClick={() => this.openDialog(children, this.props.description)}>{t('read_more')}</div>
                </React.Fragment>
            );
        }

        return (
            <div className={styleItem.Description}>{description}</div>
        );
    }

    openDialog = (title, description) => {
        this.props.openDialog({
            title,
            description,
        });
    };

    renderDetailedDescription() {
        const {children, description} = this.props;

        if (!description)
            return null;

        return (
            <div className={styleItem.DetailedDescription} data-prevent_select={true}
                 onClick={() => this.openDialog(children, description)}>
                <svg xmlns="http://www.w3.org/2000/svg" data-prevent_select={true} viewBox="0 0 15 15">
                    <g fill="none" fillRule="evenodd" data-prevent_select={true} transform="translate(1 1)">
                        <circle className={styleItem.infoStroke} data-prevent_select={true} cx="6.5" cy="6.5" r="6.5"
                                strokeLinecap="square"/>
                        <path className={styleItem.infoStroke} data-prevent_select={true} strokeLinecap="square"
                              d="M6.476 5.91v3.545"/>
                        <circle className={styleItem.infoFill} data-prevent_select={true} cx="6.476" cy="3.545" r="1"
                                fillRule="nonzero"/>
                    </g>
                </svg>
            </div>
        );
    }

    renderActionButton() {
        if (!this.props.action_button_text || typeof this.props.onActionButtonClick !== 'function') {
            return null;
        }

        return (
            <div className={styleItem.ActionButton} onClick={this.props.onActionButtonClick}>
                {this.props.action_button_text}
            </div>
        );
    }

    renderLabel() {
        const {label} = this.props;

        if (!label)
            return null;

        return (
            <div className={styleItem.Label}>
                {label}
            </div>
        );
    }

    renderPrices() {
        const {t, settings} = this.props;

        if (!settings['my_salon_group.services.show.prices']) {
            return null;
        }

        let price = 0;
        const min_price = Number(this.props.min_price).toFixed(2);
        const max_price = Number(this.props.max_price).toFixed(2);
        const currency = this.props.currency;

        if (min_price !== max_price) {
            price = Math.floor(Number(min_price)) + '-' + Math.ceil(Number(max_price));
        } else {
            price = Number(min_price);
        }

        if (this.props.discount_price) {
            let discount_price = Number(this.props.discount_price).toFixed(2);
            if ((discount_price.indexOf('.00') !== -1))
                discount_price = discount_price.replace('.00', '');

            return (
                <React.Fragment>
                    <div className={styleItem.OldPrice}>{(price ? price + currency : t('free'))}</div>
                    <div
                        className={styleItem.Price}>{(discount_price !== '0.00' ? discount_price + currency : t('free'))}</div>
                </React.Fragment>
            );
        }

        return (
            <div className={styleItem.Price}>{(price ? price + currency : t('free'))}</div>
        );
    }

    renderDuration() {
        const {settings} = this.props;

        if (!settings['my_salon_group.services.show.duration']) {
            return null;
        }

        let duration = 0;
        const min_duration = parseInt(this.props.min_duration);
        const max_duration = parseInt(this.props.max_duration);

        if (min_duration !== max_duration) {
            duration = min_duration + '-' + max_duration + ' min';
        } else {
            duration = min_duration + ' min';
        }

        return <div className={styleItem.Duration}>{duration}</div>
    }

    render() {
        let classes = styleItem.InputCheckItem + ' ' + styleItem.Service;

        if (this.props.disabled) {
            classes += ' ' + styleItem.Disabled;
        } else if (this.state.checked) {
            classes += ' ' + styleItem.InputCheckItemChecked;
        }

        return (
            <div className={classes} data-label={this.props.label ? this.props.label : null}
                 onClick={(this.props.disabled ? null : this.toggleChecked)}>
                {this.renderLabel()}
                <div className={styleItem.Radio}>
                    <div className={styleItem.RadioButton}/>
                </div>
                <div className={styleItem.Content}>
                    <div className={styleItem.Title}>{this.props.children}</div>
                    {this.renderDescription()}
                </div>
                <div className={styleItem.Extra + ' ' + styleItem.ClearLeft}>
                    <div className={styleItem.ServiceInfo}>
                        {this.renderDetailedDescription()}
                        {this.renderPrices()}
                        {this.renderDuration()}
                    </div>
                </div>
            </div>
        );
    }
}

ServiceItem.defaultProps = {
    checked: false,
    children: 'N/A',
    inputProps: {},
    onChange: null,
    disabled: false,
    disabled_reason: '',
    description: '',
    value: 0,
    category_id: 0,
    label: '',

    min_duration: 0,
    max_duration: 0,

    currency: 'NOK',
    min_price: 0,
    max_price: 0,
    discount_price: null,

    action_button_text: '',
    onActionButtonClick: null,
};

const mapStateToProps = state => {
    return {
        settings: state.salon_location.group_settings,
    };
};

export default connect(mapStateToProps, {
    openDialog,
})(
    withTranslation()(ServiceItem),
);
