import {createStore} from 'redux';
import {persistReducer, persistStore} from 'redux-persist';
import {registerPersistTimestamp, reset} from './actions/userInterface';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'localforage';
//import storage from 'redux-persist/lib/storage'
import {combinedReducers, middleware} from './store';
import {salon_slug, subdomain, apiDomain, isWeb} from './env';
import {salon_group_id} from './localEnv';
import timestamp from './helpers/timestamp';

const persistConfig = {
    key: apiDomain + '_SalonLifeOB_' + (subdomain ? subdomain : salon_group_id) + '_' + require('../package.json').version,
    storage,
    stateReconciler: autoMergeLevel2,
    blacklist: ['routing', 'location_lock', 'window', 'form', 'booking', 'widget', 'languages', 'cancel'],
};

const persistedReducer = persistReducer(persistConfig, combinedReducers);

const persistStoreLocal = (forceReset = false) => {
    const store = createStore(persistedReducer, middleware);

    const persistor = persistStore(store, null, () => {
        if (store.getState().ui.persist_timestamp === 0)
            store.dispatch(registerPersistTimestamp());

        const state = store.getState();

        if (forceReset === true || (isWeb && salon_slug && state.salon_location?.selected_location?.slug !== salon_slug) || ((state.ui.persist_timestamp - (timestamp())) <= 0)) {
            persistor.purge();
            store.dispatch(reset());
            store.dispatch(registerPersistTimestamp());
        }
    });

    return {
        store,
        persistor,
    };
}

export default persistStoreLocal
