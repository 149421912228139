import React from 'react';
import Select from 'react-select';
import PhoneCodes from '../utils/PhoneCodes';
import phoneStyle from '../scss/components/FormPhoneField.module.scss';
import {connect} from './ReactPageComponent';

class FormPhonePrefixField extends React.Component {

    getSelectedValue = () => {
        const selectedValue = this.props.input.value || this.props.salon.phone_prefix;
        let val = PhoneCodes[0];
        PhoneCodes.map((item, index) => {
            if (item.value === selectedValue) {
                val = PhoneCodes[index];
            }

            return item;
        });

        return val;
    };

    state = {
        isOpen: false,
        value: this.getSelectedValue(),
    };

    componentDidMount() {
        this.props.input.onChange(this.state.value['value']);
    }

    toggleOpen = (e) => {
        e.preventDefault();
        this.setState(state => ({isOpen: !state.isOpen}));
    };

    onSelectChange = (value) => {
        this.setState({isOpen: false});
        this.setState({value: value});
        this.props.input.onChange(value['value']);
    };

    handleCloseMenu = (event) => {
        this.setState(({
            isOpen: false,
        }));

        //this.props.input.onBlur(this.state.value.value);

        //for mobile?
        /*setTimeout(() => {
            const { input } = props;
            input.onBlur(input.value);
        }, 1);*/
    };

    render() {

        const {isOpen, value} = this.state;

        return (

            <Dropdown
                isOpen={isOpen}
                onClose={this.toggleOpen}
                target={
                    <div className={phoneStyle.SelectedValueContainer} onClick={this.toggleOpen}>
                        <span>
                            {value ? value.value : ''}
                        </span>
                    </div>
                }
            >
                <Select
                    {...this.props.input}
                    autoFocus
                    backspaceRemovesValue={true}
                    components={{IndicatorSeparator: null}}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen
                    onChange={this.onSelectChange}
                    onBlur={this.handleCloseMenu}
                    options={PhoneCodes}
                    placeholder={null}
                    tabSelectsValue={false}
                    menuPlacement="auto"
                    //menuPosition="fixed"
                    value={value}
                    classNamePrefix="PhonePrefix"
                    getOptionLabel={option => `${option.value} (${option.label})`}
                    getOptionValue={option => option.value}
                />
            </Dropdown>

        );
    }
}


const Menu = props => {
    return (
        <div {...props} className={phoneStyle.Menu}/>
    );
};

const Dropdown = ({children, isOpen, target}) => (
    <div className={phoneStyle.PhonePrefix}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
    </div>
);

const mapStateToProps = (state) => {
    return {
        salon: state.salon_location.selected_location,
    };
};

export default connect(mapStateToProps, {})(FormPhonePrefixField);
