import React from 'react';
import LocationItem from './LocationItem';
import connect from 'react-redux/es/connect/connect';
import {onChange} from '../actions/salonLocation';
import {confirm, reset, setSalonName} from '../actions/userInterface';
import {withTranslation} from 'react-i18next';
import {Redirect} from 'react-router-dom';
import search from '../helpers/search';
import NoSearchResults from "./NoSearchResults";
import {onSearch} from '../actions/search';

class LocationItems extends React.Component {
    state = {
        redirect: false,
    };

    resetAndUpdateLocation = (value, salon_name) => {
        this.props.reset();
        this.updateLocationItem(value, salon_name);
    }

    onLocationItemChange = (value, isChecked, salon_name) => {
        if (isChecked) {
            if ((this.props.order.services.length || this.props.categories.ids.length || this.props.order.ids.length) && value !== this.props.salon_location.value) {
                if (this.props.salon_location.value === null)
                    this.resetAndUpdateLocation(value, salon_name);
                else
                    this.props.confirm({
                        title: this.props.t('location:confirm_title'),
                        description: this.props.t('location:confirm_description'),
                        confirm_text: null,
                        cancel_text: null,
                    }, () => {
                        this.resetAndUpdateLocation(value, salon_name);
                    });
            } else {
                this.updateLocationItem(value, salon_name);
            }
        }
    };

    updateLocationItem = (value, salon_name) => {
        this.props.setSalonName(salon_name);

        if (this.props.salon_location.value !== value) {
            this.props.onChange(value);
        }

        if (this.props.salon_location.value === null && value) {
            this.setState({
                redirect: true,
            });
        }
    };

    componentDidMount() {
        if (this.props.salon_location.value !== this.props.value)
            this.setValueFromPropsToState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value)
            this.setValueFromPropsToState();
    }

    setValueFromPropsToState() {
        this.props.onChange(this.props.value);
    }

    renderLocations = () => {
        const {t, i18n} = this.props;
        const curLang = i18n.languages[0];

        let filtered_locations = this.props.items;

        if (this.props.search.keyword) {
            filtered_locations = filtered_locations.filter(
                item => {
                    const address = (item.addresses && item.addresses[curLang]) ? item.addresses[curLang] : '';
                    const name = (item.location_names && item.location_names[curLang]) ? item.location_names[curLang] : item.name;

                    return (
                        search(name, this.props.search.keyword) ||
                        search(address, this.props.search.keyword)
                    )
                },
            );
        }

        if (!filtered_locations.length && this.props.search.keyword) {
            return (
                <NoSearchResults
                    text={t('general:no_search_results', {keyword: this.props.search.keyword})}
                    buttonText={t('general:back')}
                    onClick={() => this.props.onSearch('')} />
            )
        }

        return filtered_locations.map((item) => {
            const address = (item.addresses && item.addresses[curLang]) ? item.addresses[curLang] : '';
            const name = (item.location_names && item.location_names[curLang]) ? item.location_names[curLang] : item.name;

            return (
                <LocationItem
                    onChange={(value, isChecked) => this.onLocationItemChange(value, isChecked, item.name)}
                    checked={this.props.salon_location.value === item.id}
                    blurred={this.props.salon_location.value !== null && this.props.salon_location.value !== item.id}
                    value={item.id}
                    key={item.id}
                    address={address}
                    employees={item.employees}>
                    {name}
                </LocationItem>
            );
        });
    };

    render() {
        if (this.state.redirect)
            return <Redirect to={this.props.categories_path}/>;

        return (
            <React.Fragment>
                {this.renderLocations()}
            </React.Fragment>
        );
    }
}

LocationItems.defaultProps = {
    items: [],
    value: null,
};

const mapStateToProps = (state) => {
    return {
        search: state.search,
        salon_location: state.salon_location,
        categories_path: state.routes.paths.categories,
        categories: state.categories,
        order: state.order,
    };
};

export default connect(mapStateToProps, {
    onChange,
    setSalonName,
    confirm,
    reset,
    onSearch
})(
    withTranslation()(LocationItems),
);
