import {
    LOCATIONS_LOCK,
    ON_BOOKING_CONFIRM_ERROR,
    ON_BOOKING_CONFIRM_FIELDS_ERROR,
    ON_BOOKING_CONFIRM_SUCCESS,
    ON_SET_ERROR_CODE,
    ON_START_NEW_BOOKING,
    ON_SUBMITTING_BOOKING,
    RESET_SALON_CODE,
    RESET_STATE,
    SALON_CODE_IS_REQUIRED,
} from './type'
import salonlife from '../api/salonlife'
import {reset, SubmissionError} from 'redux-form'
import getApiPath from '../helpers/getApiPath'
import {updateGroupSettings} from './salonLocation'
import {setEmployee} from './restrictionCode'
import {addEmployeeToOrder} from './order'
import ReactGA from 'react-ga4'
import i18n from '../trans/i18n'
import {cancelTheRequest, registerTheRequest} from '../helpers/axiosCancel'
import FacebookPixel from '../helpers/facebookPixel'

const handleCustomerError = (error, dispatch, getState, doNotThrow = false) => {
    const response = error?.response ?? {}
    const statusCode = response.status
    const data = response.data

    if (statusCode === 422 && data) {
        if (data.settings)
            updateGroupSettings(data.settings)(dispatch, getState)

        if (data.fields && Array.isArray(data.fields) && data.fields.length) {
            const errors = {}

            data.fields.forEach(key => {
                errors[key] = true
            })

            if (doNotThrow)
                return errors
            else
                throw new SubmissionError(errors)
        }
    }
}

export const validateCustomer = formValues => {
    const uniqueKey = 'validateCustomer'
    cancelTheRequest(uniqueKey)

    return async (dispatch, getState) => {
        const state = getState()

        return await salonlife.post(getApiPath(state.routes, state.widget, state.salon_location) + '/validate_customer', formValues, {
            cancelToken: registerTheRequest(uniqueKey),
        })
            .then(response => {
                dispatch({
                    type: ON_BOOKING_CONFIRM_FIELDS_ERROR,
                    payload: undefined,
                })

                return response
            }).catch(error => {
                handleCustomerError(error, dispatch, getState)
            })
    }
}

export const confirmBooking = () => {
    const uniqueKey = 'confirmBooking'
    cancelTheRequest(uniqueKey)

    return async (dispatch, getState) => {
        const state = getState()

        const customer_data = {...state.form.customerForm?.values ?? {}}

        if (typeof customer_data.note !== typeof undefined)
            delete customer_data.note

        const salon_code = state.restriction_code?.salon?.code ?? ''
        const employee_code = state.restriction_code?.employee?.code ?? ''

        const hostname = window?.location?.hostname ?? ''
        const params = {
            is_connect: hostname === 'salonlife.com' || hostname === 'www.salonlife.com',
            service_ids: state.order.ids,
            employee_id: state.order.selected_employee.id,
            date: state.order.selected_date,
            time: state.order.selected_time,
            customer_data,
        }

        if (salon_code)
            params.salon_code = salon_code
        if (employee_code)
            params.employee_code = employee_code

        if (state.form.customerForm?.values?.note ?? null)
            params.note = state.form.customerForm.values.note

        dispatch({
            type: ON_SUBMITTING_BOOKING,
            payload: true,
        })

        params.active_lang = i18n.language ?? 'en'

        return await salonlife.post(getApiPath(state.routes, state.widget, state.salon_location) + '/confirm_booking', {
            params,
        }, {
            cancelToken: registerTheRequest(uniqueKey),
        }).then(response => {
            dispatch({
                type: ON_BOOKING_CONFIRM_SUCCESS,
                payload: response.data,
            })

            dispatch(reset('customerForm'))

            let selected_salon_id = null
            const selectedSalon = state.salon_location.selected_location

            if (selectedSalon?.settings?.['salon.google_analytics_id'] && state.salon_location.value)
                selected_salon_id = 'salon_' + state.salon_location.value

            const currentLanguage = i18n?.languages?.[0] ?? 'en'

            try {
                const services = state.order.ordered_services
                const services_info = state.order?.selected_employee?.services_info ?? {}
                let revenue = 0
                let salonCurrency = selectedSalon.settings['salon.currency']
                switch (salonCurrency) {
                    case '€':
                        salonCurrency = 'EUR'
                        break
                    case '$':
                        salonCurrency = 'USD'
                        break
                    case '£':
                        salonCurrency = 'GBP'
                        break
                    default:
                        break
                }

                const items = []
                let item_index = 0
                Object.keys(services).forEach(service_id => {
                    const service = services[service_id]
                    const category_names = state.categories.names[service.category_id] ?? {}
                    let price = parseFloat(service.max_price)

                    if (services_info && services_info?.[service_id]?.price)
                        price = parseFloat(services_info[service_id]?.price)

                    if (selected_salon_id)
                        items.push({
                            item_id: String(service.id),
                            item_name: service.name[currentLanguage] || service.name[Object.keys(service.name)[0]],
                            affiliation: selectedSalon.name + ' @SalonLife',
                            item_category: category_names[currentLanguage] || category_names[Object.keys(category_names)[0]],
                            quantity: 1,
                            index: item_index,
                            price: price,
                        })

                    revenue += parseFloat(price)
                    ++item_index
                })

                const facebook_id = selectedSalon?.settings?.['salon.facebook_pixel_id'] ?? null
                if (facebook_id)
                    FacebookPixel.trackSingleCustom(facebook_id, 'Purchase', {
                        value: revenue,
                        currency: salonCurrency,
                    })

                if (selected_salon_id) {
                    ReactGA.event('purchase', {
                        transaction_id: String(response.data.booking.id),
                        value: revenue,
                        shipping: 0,
                        tax: 0,
                        currency: salonCurrency,
                        affiliation: selectedSalon.name + ' @SalonLife',
                        items,
                        send_to: selected_salon_id,
                    })
                }
            } catch (err) {
            }
        }).catch(error => {
            if (!error.response)
                return error

            if (error.response.status === 500)
                dispatch({
                    type: ON_BOOKING_CONFIRM_ERROR,
                    payload: {
                        ...error.response,
                        data: {
                            ...error.response?.data ?? {},
                            code: 500,
                        },
                    },
                })
            else if (error.response.status === 422)
                dispatch({
                    type: ON_BOOKING_CONFIRM_FIELDS_ERROR,
                    payload: handleCustomerError(error, dispatch, getState, true),
                })
            else if (error.response.status === 401) {
                if (error.response?.data?.salon?.['salon.code_enabled']) {
                    dispatch({
                        type: SALON_CODE_IS_REQUIRED,
                        payload: error.response.data.salon,
                    })

                    if (!error.response?.data?.salon_code_ok)
                        dispatch({
                            type: RESET_SALON_CODE,
                        })
                }

                if (error.response?.data?.employee?.code_enabled) {
                    if (!error.response?.data?.employee_code_ok) {
                        setEmployee({})(dispatch, getState)
                        addEmployeeToOrder(null)(dispatch, getState)
                    }
                }

                dispatch({
                    type: ON_SUBMITTING_BOOKING,
                    payload: false,
                })
            } else
                dispatch({
                    type: ON_BOOKING_CONFIRM_ERROR,
                    payload: error.response,
                })
        })
    }
}

export const startNewBooking = () => {
    return dispatch => {
        dispatch({
            type: RESET_STATE,
            payload: true,
        })

        dispatch({
            type: LOCATIONS_LOCK,
            payload: false,
        })

        dispatch({
            type: ON_START_NEW_BOOKING,
            payload: true,
        })
    }
}

export const setBookingErrorCode = error_code => {
    return dispatch => {
        dispatch({
            type: ON_SET_ERROR_CODE,
            payload: error_code,
        })
    }
}
