import {
    ADD_DATETIME_TO_ORDER,
    ADD_EMPLOYEE_TO_ORDER,
    ADD_TO_ORDER,
    CLEAR_ORDER,
    GET_SERVICES,
    REMOVE_FROM_ORDER,
} from './type';
import salonlife from '../api/salonlife';
import getApiPath from '../helpers/getApiPath';
import {resetSchedule} from './schedule';
import {cancelTheRequest, registerTheRequest, registerTimeout} from '../helpers/axiosCancel';
import FacebookPixel from '../helpers/facebookPixel';

export const getServices = () => {
    const uniqueKey = 'getServices';
    cancelTheRequest(uniqueKey);
    const params = {};

    return async (dispatch, getState) => {
        const selected_categories = getState().categories.ids;
        const ordered_services = [];
        Object.keys(getState().order.ids_by_category).forEach(id => {
            ordered_services.push(...getState().order.ids_by_category[id]);
        });

        if (ordered_services.length) {
            params.services = ordered_services;
        }

        if (selected_categories.length && selected_categories.indexOf(0) === -1) {
            params.categories = selected_categories.map(item => {
                return item;
            });
        }

        const state = getState();

        return await salonlife.get(getApiPath(state.routes, state.widget, state.salon_location) + '/services', {
            params,
            cancelToken: registerTheRequest(uniqueKey),
        }).then(response => {
            if (!state.categories.ids.length)
                state.categories.ids.push(0);

            dispatch({
                type: GET_SERVICES,
                payload: {
                    data: response.data.data,
                    selected_categories: state.categories.ids,
                },
            });

            return response;
        }).catch(error => {
            if (error && error.response && error?.response?.status !== 404)
                registerTimeout(uniqueKey, () => {
                    getServices()(dispatch, getState);
                }, 60000);

            throw error;
        });
    };
};

export const addServiceToOrder = (id, category_id = 0) => {
    return async (dispatch, getState) => {
        addEmployeeToOrder(null)(dispatch, getState);

        if (id) {
            const facebook_id = getState().salon_location?.selected_location?.settings?.['salon.facebook_pixel_id'] ?? null;
            if (facebook_id)
                FacebookPixel.trackSingleCustom(facebook_id, 'AddToCart');
        }

        dispatch({
            type: ADD_TO_ORDER,
            payload: {
                id,
                category_id,
            },
        });
    };
};

export const removeServicesFromOrder = value => {
    return async (dispatch, getState) => {
        let ids = value;
        if (!Array.isArray(value)) {
            ids = [value];
        }

        let idsLength = 0;
        const orderIds = getState().order.ids;
        orderIds.forEach(id => {
            if (ids.indexOf(id) !== -1)
                ++idsLength;
        });

        if (idsLength === orderIds.length) {
            resetSchedule()(dispatch, getState);
            addEmployeeToOrder(null)(dispatch, getState);
        }

        dispatch({
            type: REMOVE_FROM_ORDER,
            payload: ids,
        });
    };
};

export const addEmployeeToOrder = employee => {
    return (dispatch, getState) => {
        resetSchedule()(dispatch, getState);

        dispatch({
            type: ADD_EMPLOYEE_TO_ORDER,
            payload: employee,
        });
    };
};

export const addDateTimeToOrder = (date, time) => {
    return {
        type: ADD_DATETIME_TO_ORDER,
        payload: {
            date: date,
            time: time,
        },
    }
};

export const clearOrder = () => {
    return (dispatch, getState) => {
        resetSchedule()(dispatch, getState);

        dispatch({
            type: CLEAR_ORDER,
            payload: [],
        });
    };
};
