import React from 'react';
import styleItem from '../scss/components/InputCheckItem.module.scss';

class LocationItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: this.props.checked ?? false,
        };
    }

    callOnChange(isChecked) {
        if (this.props.onChange && typeof this.props.onChange === 'function') {
            this.props.onChange(this.props.value, isChecked);
        }
    }

    componentDidMount() {
        this.setCheckedFromPropToState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setCheckedFromPropToState();
    }

    setCheckedFromPropToState() {
        if (this.state.checked !== this.props.checked)
            this.setState({
                checked: this.props.checked,
            });
    }

    toggleChecked = () => {
        const inputState = !this.state.checked;

        this.setState({
            checked: inputState,
        });

        this.callOnChange(inputState);
    };

    render() {
        let classes = styleItem.InputCheckItem;

        if (this.state.checked) {
            classes += ' ' + styleItem.InputCheckItemChecked;
        } else {
            if (this.props.blurred) {
                classes += ' ' + styleItem.Blur;
            }
        }

        return (
            <div className={classes} onClick={this.toggleChecked}>
                <div className={styleItem.Radio}>
                    <div className={styleItem.RadioButton}/>
                </div>
                <div className={styleItem.Content}>
                    <div className={styleItem.Title}>{this.props.children}</div>
                    {this.props.address ? <div className={styleItem.Description}>{this.props.address}</div> : null}
                </div>
                <div className={styleItem.Extra}>
                    <div className={styleItem.Employees}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                            <g fill="none" fillRule="evenodd" strokeLinecap="square">
                                <path
                                    d="M6.333 6.227H3.667C2.194 6.227 1 7.327 1 8.682v.409S2.389 9.5 5 9.5c2.611 0 4-.41 4-.41v-.408c0-1.356-1.194-2.455-2.667-2.455zM2.778 2.545C2.778 1.416 3.773.5 5 .5c1.227 0 2.222.916 2.222 2.045C7.222 3.675 6.227 5 5 5 3.773 5 2.778 3.675 2.778 2.545z"/>
                            </g>
                        </svg>

                        {this.props.employees}
                    </div>
                </div>
            </div>
        );
    }
}

LocationItem.defaultProps = {
    checked: false,
    address: '',
    employees: 0,
    children: 'N/A',
    inputProps: {},
    onChange: null,
    blurred: false,
    value: 0,
};

export default LocationItem;
