import React from 'react';
import ReactPageComponent, {connect} from '../components/ReactPageComponent';
import {withTranslation} from 'react-i18next';
import {Redirect, withRouter} from 'react-router-dom';
import {setActiveProgress, setSalonName, setTransGroup} from '../actions/userInterface';
import {getEmployeeSchedule, onSelectDate, setScheduleEditActive, setScheduleViewActive} from '../actions/schedule';
import Loading from '../components/Loading';
import ScheduleMonthSelection from '../components/ScheduleMonthSelection';
import ScheduleWeekSelection from '../components/ScheduleWeekSelection';
import moment from 'moment';
import ScheduleList from '../components/ScheduleList';
import classNames from 'classnames';
import styleList from '../scss/components/ScheduleList.module.scss';

class Schedule extends ReactPageComponent {
    unmounted = false;
    state = {
        loading: true,
        selected_date: this.props.order?.selected_date ?? this.props?.employee?.first_available_date,
        editView: false,
    };

    scrollPosKey = 'Schedule';

    getWeekStartDate = date => {
        return moment(date).isoWeekday(this.props.settings['salon.week_start']).format('YYYY-MM-DD');
    };

    getWeekEndDate = date => {
        return moment(date).isoWeekday(this.props.settings['salon.week_start']).add(6, 'days').format('YYYY-MM-DD')
    };

    getDaysBetweenDates = (startDate, endDate) => {
        let dates = [startDate];

        let currDate = moment(startDate).startOf('day');
        let lastDate = moment(endDate).startOf('day');

        while (currDate.add(1, 'days').diff(lastDate) <= 0) {
            dates.push(currDate.clone().format('YYYY-MM-DD'));
        }

        return dates;
    };

    initScheduleViewTasks() {
        this.props.getEmployeeSchedule(this.getWeekStartDate(this.state.selected_date), this.getWeekEndDate(this.state.selected_date)).then(() => {
            this.props.onSelectDate(this.state.selected_date);

            if (!this.unmounted)
                this.setState({
                    loading: false,
                });
        });

        this.props.setScheduleViewActive(true);

        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        if (params && params.get('view') === 'preview_booking') {
            this.props.setScheduleEditActive(true);
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.props.setTransGroup('schedule');
        this.props.setActiveProgress(4);

        if (this.props.settings) {
            this.initScheduleViewTasks();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.settings && !prevProps.settings) {
            this.initScheduleViewTasks();
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this.props.setScheduleViewActive(false);
        this.props.setScheduleEditActive(false);
        this.unmounted = true;
    }

    renderSaveButton = () => {
        if (this.props.schedule.edit_schedule_view && this.props.order.selected_date && this.props.order.selected_time) {
            return (
                <div className={styleList.SaveButtonContainer}>
                    <div className={classNames(styleList.SaveButton, styleList.Show)}
                         onClick={() => this.props.history.push(this.props.review_path)}>
                        {this.props.t('save')}
                    </div>
                </div>

            );
        }
    };

    render() {
        if ((this.state.loading || !this.props.settings) && !this.props.maintenance)
            return <Loading/>;

        if (this.props.errorCode)
            return <Redirect to={this.props.error_path}/>;

        if (!this.props.employee)
            return <Redirect to={this.props.employees_path}/>;

        if (!this.props.schedule.items_loaded)
            return null;

        const times = this.props.schedule.items[this.props.schedule.selected_date] || [];

        return (
            <div>
                <ScheduleMonthSelection/>
                <ScheduleWeekSelection
                    dates={this.getDaysBetweenDates(this.props.schedule.week_start, this.props.schedule.week_end)}/>
                <ScheduleList times={times}/>
                {this.renderSaveButton()}
            </div>
        );
    }
}

Schedule.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        maintenance: state.location_lock.maintenance,
        errorCode: state.booking.errorCode,
        error_path: state.routes.paths.error,
        review_path: state.routes.paths.review,
        employees_path: state.routes.paths.employees,
        employee: state.order.selected_employee,
        schedule: state.schedule,
        settings: state.salon_location.selected_location.settings,
        order: state.order,
    };
};

const TranslatedSchedule = withTranslation(['general', 'schedule'])(Schedule);

export default withRouter(connect(mapStateToProps, {
    setTransGroup,
    setSalonName,
    setActiveProgress,
    getEmployeeSchedule,
    setScheduleViewActive,
    setScheduleEditActive,
    onSelectDate,
})(TranslatedSchedule));
