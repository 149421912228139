import React from 'react';
import style from '../scss/components/WebWrapper.module.scss';
import '../scss/web.scss';
import connect from 'react-redux/es/connect/connect';
import {getLocations} from '../actions/salonLocation';

class WebWrapper extends React.Component {
    componentDidMount() {
        this.props.getLocations();
    }

    render() {
        if (this.props.maintenance || this.props.languages_loaded)
            return (
                <div className={style.WebWrapper}>
                    {this.props.children}
                </div>
            );

        if (!this.props.languages_loaded)
            return null;
    }
}

const mapStateToProps = state => {
    return {
        maintenance: state.location_lock.maintenance,
        languages_loaded: state.languages.languages_loaded,
        loading: state.loading.getLocations,
    };
};

export default connect(mapStateToProps, {
    getLocations,
})(WebWrapper);
