import {reducer as formReducer} from 'redux-form'
import widgetReducer from './widgetReducer'
import windowReducer from './windowReducer'
import searchReducer from './searchReducer'
import salonLocationReducer from './salonLocationReducer'
import languageReducer from './languageReducer'
import categoriesReducer from './categoriesReducer'
import orderReducer from './orderReducer'
import userInterfaceReducer from './userInterfaceReducer'
import employeeReducer from './employeeReducer'
import scheduleReducer from './scheduleReducer'
import bookingReducer from './bookingReducer'
import loadingReducer from './loadingReducer'
import locationLockReducer from './locationLockReducer'
import cancelReducer from './cancelReducer'
import routesReducer from './routesReducer'
import restrictionCodeReducer from './restrictionCodeReducer'

const Reducers = {
    form: formReducer,
    loading: loadingReducer,
    salon_location: salonLocationReducer,
    routes: routesReducer,
    location_lock: locationLockReducer,
    categories: categoriesReducer,
    order: orderReducer,
    widget: widgetReducer,
    window: windowReducer,
    search: searchReducer,
    languages: languageReducer,
    ui: userInterfaceReducer,
    employees: employeeReducer,
    schedule: scheduleReducer,
    booking: bookingReducer,
    cancel: cancelReducer,
    restriction_code: restrictionCodeReducer,
}

export default Reducers
