import {
    ADD_DATETIME_TO_ORDER,
    ADD_EMPLOYEE_TO_ORDER,
    ADD_TO_ORDER,
    CLEAR_ORDER,
    GET_SERVICES,
    REMOVE_FROM_ORDER,
    RESET_STATE,
} from '../actions/type'
import arrDiff from '../arrDiff'
import deleteKeyFromObject from '../deleteKeyFromObject'

const INITIAL_STATE = {
    ids: [],
    ids_by_category: {},
    original_services: [],
    services: [],
    services_by_id: {},
    ordered_services: {},
    services_loaded: false,
    allowed_providers: [],
    selected_employee: null,
    selected_date: null,
    selected_time: null,
    selected_categories: [],
}

const getServicesData = (loaded_services, state, selected_categories) => {
    let services_by_id = {}
    const services = []

    const ordered_services_ids = []
    const ordered_services = {}

    Object.keys(state.ids_by_category).forEach(id => {
        ordered_services_ids.push(...state.ids_by_category[id])
    })

    loaded_services.forEach(items => {
        const category_validation = selected_categories.indexOf(items.category_id) !== -1 || selected_categories.indexOf(0) !== -1

        items.data.forEach(item => {
            if (ordered_services_ids.indexOf(item.id) !== -1)
                ordered_services[item.id] = item

            if (category_validation)
                services_by_id[item.id] = item
        })

        if (category_validation)
            services.push(items)
    })

    return {
        services,
        services_by_id,
        ordered_services,
    }
}

const orderReducer = (state = INITIAL_STATE, action) => {
    let ids = []
    let new_allowed_providers = []
    let allowed_providers = []
    let ids_by_category = {}
    let data = {}
    switch (action.type) {
        case ADD_TO_ORDER:
            ids = [...state.ids]
            new_allowed_providers = []
            allowed_providers = [...state.allowed_providers]
            ids_by_category = {...state.ids_by_category}

            const service = state.services_by_id[action.payload.id] || null

            if (service) {
                if (allowed_providers.length)
                    new_allowed_providers = arrDiff(allowed_providers, service.providers)
                else
                    new_allowed_providers = [...service.providers]
            }

            if (new_allowed_providers.length === 0)
                new_allowed_providers = [...allowed_providers]

            Object.keys(state.services_by_id).forEach(id => {
                const service_item = state.services_by_id[id]
                let isDisabled = undefined

                new_allowed_providers.forEach(allowed_provider => {
                    if (isDisabled === true || isDisabled === undefined)
                        isDisabled = service_item.providers.indexOf(allowed_provider) === -1
                })

                service_item.disabled = isDisabled

                if (!service_item.disabled && parseInt(service_item.id) === parseInt(action.payload.id)) {
                    // Add service to order if has allowed provider
                    if (ids.indexOf(action.payload.id) === -1) {
                        ids.push(action.payload.id)

                        let category_id = action.payload.category_id
                        if (!category_id) {
                            category_id = service_item.category_id
                        }

                        if (!ids_by_category.hasOwnProperty(category_id)) {
                            ids_by_category[category_id] = []
                        }

                        if (ids_by_category[category_id].indexOf(action.payload.id) === -1)
                            ids_by_category[category_id].push(action.payload.id)
                    }
                }
            })

            data = getServicesData(state.original_services, {
                ...state,
                ids,
                ids_by_category,
            }, state.selected_categories)

            return {
                ...state,
                allowed_providers: new_allowed_providers,
                ids_by_category,
                ids,
                services: data.services,
                services_by_id: data.services_by_id,
                ordered_services: data.ordered_services,
            }
        case REMOVE_FROM_ORDER:
            ids = state.ids.filter(id => action.payload.indexOf(id) === -1)
            new_allowed_providers = []
            allowed_providers = []
            ids_by_category = {...state.ids_by_category}

            Object.keys(ids_by_category).forEach(category_id => {
                ids_by_category[category_id] = ids_by_category[category_id].filter(id => action.payload.indexOf(id) === -1)

                if (!ids_by_category[category_id].length) {
                    ids_by_category = deleteKeyFromObject(ids_by_category, category_id)
                }
            })

            if (ids.length > 0) {
                ids.forEach(id => {
                    const service_item = state.services_by_id[id]

                    if (service_item) {
                        if (new_allowed_providers.length)
                            new_allowed_providers = arrDiff(new_allowed_providers, service_item.providers)
                        else
                            new_allowed_providers = [...service_item.providers]
                    }
                })
            }

            Object.keys(state.services_by_id).forEach(id => {
                const service_item = state.services_by_id[id]
                let isDisabled = undefined

                if (new_allowed_providers.length) {
                    new_allowed_providers.forEach(allowed_provider => {
                        if (isDisabled === true || isDisabled === undefined)
                            isDisabled = service_item.providers.indexOf(allowed_provider) === -1
                    })
                }

                service_item.disabled = isDisabled
            })
            data = getServicesData(state.original_services, {
                ...state,
                ids,
                ids_by_category,
            }, state.selected_categories)

            return {
                ...state,
                allowed_providers: new_allowed_providers,
                ids,
                ids_by_category,
                services: data.services,
                services_by_id: data.services_by_id,
                ordered_services: data.ordered_services,
            }
        case ADD_EMPLOYEE_TO_ORDER:
            return {
                ...state,
                selected_employee: action.payload,
                selected_date: null,
                selected_time: null,
            }
        case ADD_DATETIME_TO_ORDER:
            return {
                ...state,
                selected_date: action.payload.date,
                selected_time: action.payload.time,
            }
        case CLEAR_ORDER:
            return {
                ...state,
                ids: [],
                ids_by_category: {},
                allowed_providers: [],
                selected_employee: null,
                selected_date: null,
                selected_time: null,
                ordered_services: {},
            }
        case GET_SERVICES:
            data = getServicesData(action.payload.data, state, action.payload.selected_categories)

            return {
                ...state,
                services_loaded: true,
                services: data.services,
                services_by_id: data.services_by_id,
                ordered_services: data.ordered_services,
                original_services: action.payload.data,
                selected_categories: action.payload.selected_categories,
            }
        case RESET_STATE:
            return INITIAL_STATE
        default:
            return state
    }
}

export default orderReducer
