import React from 'react';
import connect from "react-redux/es/connect/connect";
import {onSearch} from "../actions/search";
import {withTranslation} from "react-i18next";
import searchStyle from "../scss/components/Search.module.scss";

class Search extends React.Component {
    inputRef = React.createRef();

    onIconClick = () => {
        if (this.inputRef.current)
            this.inputRef.current.focus();
    };

    onSearchChange = (e) => {
        this.props.onSearch(e.target.value);
    };

    componentDidMount() {
        this.props.onSearch('');
    }

    render() {
        const {t} = this.props;

        return (
            <div className={searchStyle.Search}>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" onClick={ this.onIconClick }>
                    <g fill="none" fillRule="evenodd" strokeLinecap="square" strokeWidth="2" transform="translate(1 1)">
                        <path d="M19 19l-5.787-5.787"/>
                        <circle cx="7.741" cy="7.741" r="7.741"/>
                    </g>
                </svg>
                <input type={this.props.type}
                       placeholder={this.props.placeholder || t(this.props.trans_group + ':search_placeholder') }
                       onChange={ this.onSearchChange }
                       value={ this.props.search.keyword }
                       ref={this.inputRef}
                />
            </div>
        );
    }
}

Search.defaultProps = {
    placeholder: '',
    type: 'text'
};

const mapStateToProps = (state) => {
    return {
        search: state.search,
        trans_group: state.ui.trans_group
    };
};

const TranslatedSearch = withTranslation(['general', 'location'])(Search);

export default connect(mapStateToProps, {
    onSearch
})(TranslatedSearch);
