import React from 'react';
import Select from 'react-select';
import selectStyle from '../scss/components/FormSelectField.module.scss';
import classNames from 'classnames';
import inputStyle from '../scss/components/FormInput.module.scss';

export const FormSelectField = props => {
    const {input, options, label, logo, meta: {active, touched, error}} = props;

    let inputRef;

    const onInputClick  = () => {
        inputRef.focus();
    };

    return (
        <div className={classNames(inputStyle.FormInput, {
            [inputStyle.Selected]: active,
            [inputStyle.Error]: (touched || props.hasSubmissionError) && error,
        })} onClick={onInputClick}>
            <span className={inputStyle.Logo}>
                {logo}
            </span>
            <label className={inputStyle.Label}>
                <span className={inputStyle.Text}>{label}</span>
                <div className={selectStyle.SelectContainer}>
                    <Select
                        {...input}
                        ref={ref => inputRef = ref}
                        value={options.find(obj => {
                            return obj.value === input.value
                        })}
                        onChange={value => input.onChange(value['value'])}
                        onBlur={() => input.onBlur()}
                        options={options}
                        classNamePrefix="Select"
                        menuPlacement="auto"
                        //menuPosition="fixed"
                        placeholder={null}
                    />
                </div>
            </label>
        </div>
    )
};
