import {GET_CATEGORIES, ON_CATEGORIES_DESELECT, ON_CATEGORIES_SELECT} from './type';
import salonlife from '../api/salonlife';
import getApiPath from '../helpers/getApiPath';
import {cancelTheRequest, registerTheRequest, registerTimeout} from '../helpers/axiosCancel';

export const getCategories = () => {
    const uniqueKey = 'getCategories';
    cancelTheRequest(uniqueKey);

    const params = {};

    return async (dispatch, getState) => {
        const state = getState();
        return await salonlife.get(getApiPath(state.routes, state.widget, state.salon_location) + '/categories', {
            params,
            cancelToken: registerTheRequest(uniqueKey),
        }).then(response => {
            dispatch({
                type: GET_CATEGORIES,
                payload: {
                    data: response.data.data,
                    services_total: response.data.services_total,
                },
            });

            return response;
        }).catch(error => {
            if (error && error.response && error?.response?.status !== 404)
                registerTimeout(uniqueKey, () => {
                    getCategories()(dispatch, getState);
                }, 60000);

            throw error;
        });
    };
};

export const onCategoriesSelect = (value) => {
    return dispatch => {
        let ids = value;
        if (!Array.isArray(value)) {
            ids = [value];
        }

        dispatch({
            type: ON_CATEGORIES_SELECT,
            payload: ids,
        });
    };
};

export const onCategoriesDeselect = (value) => {
    return async dispatch => {
        let ids = value;
        if (!Array.isArray(value)) {
            ids = [value];
        }

        dispatch({
            type: ON_CATEGORIES_DESELECT,
            payload: ids,
        });
    };
};
