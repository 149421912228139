import React from 'react'
import EmployeeItem from './EmployeeItem'
import connect from 'react-redux/es/connect/connect'
import search from '../helpers/search'

class EmployeeList extends React.Component {

    renderEmployees = () => {
        const total_employees = this.props.items.length
        let filtered_employees = this.props.items

        if (this.props.search.keyword)
            filtered_employees = filtered_employees.filter(
                item => search(item.name, this.props.search.keyword),
            )

        return filtered_employees.map(item => {
            return (
                <EmployeeItem
                    item={item}
                    key={item.id}
                    code_enabled={!!item?.code_enabled}
                    selected={this.props.selected_employee && item.id === this.props.selected_employee.id}/>
            )
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.renderEmployees()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selected_employee: state.order.selected_employee,
        search: state.search,
    }
}

export default connect(mapStateToProps)(EmployeeList)
