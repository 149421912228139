import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {withTranslation} from 'react-i18next';
import styleItem from '../scss/components/ScheduleItem.module.scss';
import moment from 'moment';
import classNames from 'classnames';
import {addDateTimeToOrder} from '../actions/order';

class ScheduleItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: this.props.selected ?? false,
        };
    }

    toggleSelected = (e) => {

        if (this.props.order.selected_time !== this.props.time) {
            this.props.addDateTimeToOrder(this.props.schedule.selected_date, this.props.time);
        } else {
            this.props.addDateTimeToOrder(null, null);
        }
    };

    componentDidMount() {
        this.setCheckedFromPropToState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setCheckedFromPropToState();
    }

    setCheckedFromPropToState() {
        if (this.state.selected !== this.props.selected)
            this.setState({
                selected: this.props.selected,
            });
    }

    renderTimeFormat = (time) => {
        if (this.props.settings['salon.time_format'] === 12) {
            return moment(time, 'HH:mm').format('a');
        }

        return null;
    };

    renderTime = (time) => {
        if (this.props.settings['salon.time_format'] === 12) {
            return moment(time, 'HH:mm').format('h:mm');
        }

        return time;
    };

    render() {
        return (
            <div className={classNames(styleItem.ScheduleItem, {
                [styleItem.ItemSelected]: this.state.selected,
            })} onClick={this.toggleSelected}>
                <span>{this.renderTime(this.props.time)}</span>
                <span className={styleItem.TimeFormat}>{this.renderTimeFormat(this.props.time)}</span>
            </div>
        )
    }

}

ScheduleItem.defaultProps = {
    selected: false,
};

const mapStateToProps = (state) => {
    return {
        schedule: state.schedule,
        order: state.order,
        selected_time: state.order.selected_time,
        settings: state.salon_location.selected_location.settings,
    };
};

export default connect(mapStateToProps, {
    addDateTimeToOrder,
})(
    withTranslation()(ScheduleItem),
);
