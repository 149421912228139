import {applyMiddleware, combineReducers, compose, createStore} from 'redux'
import reducers from './reducers'
import {routerReducer} from 'react-router-redux'
import reduxThunk from 'redux-thunk'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const combinedReducers = combineReducers({
    ...reducers,
    routing: routerReducer,
})

export const middleware = composeEnhancers(applyMiddleware(reduxThunk))

export default createStore(
    combinedReducers,
    middleware,
)
