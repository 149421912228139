import React from 'react'
import ReactPageComponent, {connect} from '../components/ReactPageComponent'
import {withTranslation} from 'react-i18next'
import {Redirect} from 'react-router-dom'
import {setActiveProgress, setSalonName, setTransGroup} from '../actions/userInterface'
import {onSearch} from '../actions/search'
import {getEmployees} from '../actions/employees'
import Loading from '../components/Loading'
import EmployeeBlock from '../components/EmployeeBlock'
import EmployeeList from '../components/EmployeeList'
import {withCookies} from 'react-cookie'
import style from '../scss/components/NoResults.module.scss'
import {ReactComponent as NoResultsIcon} from '../assets/svg/no-search-results.svg'
import {addEmployeeToOrder} from '../actions/order'

class Employees extends ReactPageComponent {
    unmounted = false
    state = {
        loading: true,
    }

    scrollPosKey = 'Employees'

    componentDidMount() {
        super.componentDidMount()

        this.props.setTransGroup('employees')
        this.props.onSearch('')
        this.props.setActiveProgress(3)

        if (this.props.order.ids.length) {
            this.props.getEmployees().then((response) => {
                if (!this.unmounted) {
                    this.setState({
                        loading: false,
                    })

                    if ((response?.data?.all?.employees ?? []).length === 1) {
                        const employee = response.data.all.employees[0]
                        if (!employee.code_enabled) {
                            this.props.addEmployeeToOrder(employee)
                        }
                    }
                }
            })
        } else
            this.setState({
                loading: false,
            })
    }

    componentWillUnmount() {
        super.componentWillUnmount()

        this.unmounted = true
    }

    renderEmployeeBlocks = () => {
        const blocks = []
        const {t, cookies} = this.props
        const key = 'favorite_employees_' + this.props.salon.id
        const favorites_enabled = this.props.group_settings['my_salon_group.staff.show.favorites']

        let favorites = cookies.get(key) || []

        if (favorites_enabled && favorites && favorites.length) {
            const items = []
            favorites.forEach(item => {
                const employee = this.props.employees.all_employees.find(emp => emp.id === item.id)

                if (undefined !== employee) {
                    items.push(employee)
                }
            })

            if (items.length) {
                blocks.push(
                    <EmployeeBlock
                        title={t('favorites')}
                        total={items.length}
                        items={items}
                        key="favorites"
                    />,
                )
            }
        }

        if (this.props.employees.employees_this_week_total) {
            blocks.push(
                <EmployeeBlock
                    title={t('available_this_week')}
                    total={this.props.employees.employees_this_week_total}
                    items={this.props.employees.employees_this_week}
                    key="this_week"
                />,
            )
        }

        if (this.props.employees.employees_next_week_total) {
            blocks.push(
                <EmployeeBlock
                    title={t('available_next_week')}
                    total={this.props.employees.employees_next_week_total}
                    items={this.props.employees.employees_next_week}
                    key="next_week"
                />,
            )
        }

        if (this.props.employees.other_employees_total) {
            blocks.push(
                <EmployeeBlock
                    title={t('others')}
                    total={this.props.employees.other_employees_total}
                    items={this.props.employees.other_employees}
                    key="other"
                />,
            )
        }

        return blocks
    }

    render() {
        const {t} = this.props

        if (this.state.loading)
            return <Loading/>

        if (!this.props.order.ids.length)
            return <Redirect to={this.props.services_path}/>

        if (this.props.errorCode)
            return <Redirect to={this.props.error_path}/>

        if (!this.props.employees.all_employees.length || !this.props.employees.items_loaded)
            return (
                <div className={style.Wrap}>
                    <NoResultsIcon/>
                    <div className={style.Text}>
                        {t('general:no_available_times')}
                    </div>
                    <div className={style.Button} onClick={() => this.props.history.push(this.props.services_path)}>
                        {t('general:back')}
                    </div>
                </div>
            )

        if (this.props.search.keyword) {
            return (
                <React.Fragment>
                    <EmployeeList items={this.props.employees.all_employees}/>
                </React.Fragment>
            )
        }

        return this.renderEmployeeBlocks()
    }
}

Employees.defaultProps = {}

const mapStateToProps = (state) => {
    return {
        errorCode: state.booking.errorCode,
        error_path: state.routes.paths.error,
        services_path: state.routes.paths.services,
        employees: state.employees,
        order: state.order,
        search: state.search,
        salon: state.salon_location.selected_location,
        group_settings: state.salon_location.group_settings,
    }
}

const TranslatedEmployees = withTranslation(['employees'])(Employees)

export default withCookies(connect(mapStateToProps, {
    setTransGroup,
    setSalonName,
    onSearch,
    getEmployees,
    setActiveProgress,
    addEmployeeToOrder,
})(TranslatedEmployees))
