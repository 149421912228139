import React from 'react';
import styleItem from '../scss/components/InputCheckItem.module.scss';

class CategoryItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: this.props.checked ?? false,
        };
    }

    callOnChange(isChecked) {
        if (this.props.onChange && typeof this.props.onChange === 'function') {
            this.props.onChange(this.props.value, isChecked);
        }
    }

    componentDidMount() {
        this.setCheckedFromPropToState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setCheckedFromPropToState();
    }

    setCheckedFromPropToState() {
        if (this.state.checked !== this.props.checked)
            this.setState({
                checked: this.props.checked,
            });
    }

    toggleChecked = () => {
        const inputState = !this.state.checked;

        this.setState({
            checked: inputState,
        });

        this.callOnChange(inputState);
    };

    renderDisabledReason() {
        if (!this.props.disabled)
            return null;

        if (!this.props.disabled_reason)
            return null;

        return (
            <div className={styleItem.Note}>{this.props.disabled_reason}</div>
        );
    }

    renderNote() {
        if (!this.props.note)
            return null;

        return (
            <div className={styleItem.Note}>{this.props.note}</div>
        );
    }

    renderActionButton() {
        if (!this.props.action_button_text || typeof this.props.onActionButtonClick !== 'function') {
            return null;
        }

        return (
            <div className={styleItem.ActionButton} onClick={this.props.onActionButtonClick}>
                {this.props.action_button_text}
            </div>
        );
    }

    render() {
        let classes = styleItem.InputCheckItem + ' ' + styleItem.Category;

        if (this.props.disabled) {
            classes += ' ' + styleItem.Disabled;
        } else if (this.state.checked) {
            classes += ' ' + styleItem.InputCheckItemChecked;
        }

        return (
            <div className={classes} onClick={(this.props.disabled ? null : this.toggleChecked)}>
                <div className={styleItem.Radio}>
                    <div className={styleItem.RadioButton}/>
                </div>
                <div className={styleItem.Content}>
                    <div className={styleItem.Title}>{this.props.children}</div>
                    {this.renderDisabledReason()}
                    {this.renderNote()}
                </div>
                <div className={styleItem.Extra + ' ' + styleItem.ClearLeft}>
                    <div className={styleItem.Total}>
                        {this.props.services_total}
                    </div>
                    {this.renderActionButton()}
                </div>
            </div>
        );
    }
}

CategoryItem.defaultProps = {
    checked: false,
    services_total: 0,
    children: 'N/A',
    inputProps: {},
    onChange: null,
    disabled: false,
    disabled_reason: '',
    note: '',
    value: 0,

    action_button_text: '',
    onActionButtonClick: null,
};

export default CategoryItem;
