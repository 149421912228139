import {
    CLOSE_DIALOG,
    CONFIRM_REQUIRED,
    ON_LANGUAGE_CHANGE,
    ON_MAINTENANCE_MODE,
    ON_PATH_CHANGE,
    ON_PROGRESS_ACTIVE_CHANGE,
    ON_PROGRESS_CHANGE,
    ON_REGISTER_PERSIST_TIMESTAMP,
    ON_SALON_CHANGE,
    ON_SET_ERROR_CODE,
    ON_TRANS_GROUP_CHANGE,
    OPEN_DIALOG,
    RESET_STATE,
    SCROLL_POS_CHANGE,
    TOGGLE_CONTAINER_SCROLL,
} from './type'
import timestamp from '../helpers/timestamp'
import i18n_options, {setDefaultLanguage, setPreloadLanguages} from '../trans/i18n_options'
import i18n from '../trans/i18n'

export const onPathChange = value => {
    return dispatch => {
        dispatch({
            type: ON_PATH_CHANGE,
            payload: value,
        })
    }
}

export const maintenanceMode = value => {
    return dispatch => {
        if (value === true) {
            document.getElementById('meta-manifest-href').href = 'manifest.json'

            const languages = ['en']
            const language = 'en'

            setPreloadLanguages(languages)
            setDefaultLanguage(language)
            changeLanguage(language)
            i18n.init(i18n_options(null))
            document.documentElement.lang = language
        } else
            dispatch({
                type: ON_SET_ERROR_CODE,
                payload: null,
            })

        setTimeout(() => dispatch({
            type: ON_MAINTENANCE_MODE,
            payload: value,
        }))
    }
}

export const reset = () => {
    return {
        type: RESET_STATE,
        payload: null,
    }
}

export const registerPersistTimestamp = (clear = false) => {
    return dispatch => {
        if (!clear)
            dispatch({
                type: ON_REGISTER_PERSIST_TIMESTAMP,
                payload: {
                    timestamp: timestamp() + (15 * 60000),
                    version: require('../../package.json').version,
                },
            })
        else
            dispatch({
                type: ON_REGISTER_PERSIST_TIMESTAMP,
                payload: 0,
            })
    }
}

export const setTransGroup = value => {
    return {
        type: ON_TRANS_GROUP_CHANGE,
        payload: value,
    }
}

export const changeLanguage = lang => {
    return {
        type: ON_LANGUAGE_CHANGE,
        payload: lang,
    }
}

export const setSalonName = value => {
    return dispatch => {
        dispatch({
            type: ON_SALON_CHANGE,
            payload: value,
        })
    }
}

export const setTotalProgress = integer => {
    return {
        type: ON_PROGRESS_CHANGE,
        payload: integer,
    }
}


export const setActiveProgress = integer => {
    return (dispatch, getState) => {
        const total_progress = getState().ui.progress.total

        if (total_progress === 5)
            integer = integer - 1
        else if (total_progress === 4)
            integer = integer - 2

        if (integer < 0)
            integer = 0

        dispatch({
            type: ON_PROGRESS_ACTIVE_CHANGE,
            payload: integer,
        })
    }
}

export const preventContainerScroll = () => {
    return {
        type: TOGGLE_CONTAINER_SCROLL,
        payload: false,
    }
}

export const releaseContainerScroll = () => {
    return {
        type: TOGGLE_CONTAINER_SCROLL,
        payload: true,
    }
}

export const openDialog = data => {
    return {
        type: OPEN_DIALOG,
        payload: data,
    }
}

export const closeDialog = () => {
    return {
        type: CLOSE_DIALOG,
        payload: true,
    }
}

export const confirm = (data, onConfirm) => {
    return {
        type: CONFIRM_REQUIRED,
        payload: {
            data: data,
            onConfirm: onConfirm,
        },
    }
}

export const hideConfirm = () => {
    return {
        type: CONFIRM_REQUIRED,
        payload: {
            data: null,
            onConfirm: null,
        },
    }
}

export const saveScrollPos = (key, value) => {
    return {
        type: SCROLL_POS_CHANGE,
        payload: {
            key,
            value,
        },
    }
}
