import {LANGUAGE_SWITCH_TOGGLE, SET_WIDGET_LANGUAGE} from './type';

export const showLanguageSwitch = (value) => {
    return {
        type: LANGUAGE_SWITCH_TOGGLE,
        payload: value,
    }
};

export const setWidgetLanguage = value => {
    return {
        type: SET_WIDGET_LANGUAGE,
        payload: value,
    }
};
