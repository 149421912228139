import {GET_EMPLOYEES, RESET_STATE} from '../actions/type'

const INITIAL_STATE = {
    items_loaded: false,
    employees_this_week: [],
    employees_this_week_total: [],
    employees_next_week: [],
    employees_next_week_total: [],
    other_employees: [],
    other_employees_total: 0,
    all_employees: [],
}

const employeeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_EMPLOYEES:
            return {
                ...state,
                items_loaded: true,
                employees_this_week: action.payload.this_week.employees,
                employees_this_week_total: action.payload.this_week.count,
                employees_next_week: action.payload.next_week.employees,
                employees_next_week_total: action.payload.next_week.count,
                other_employees: action.payload.others.employees,
                other_employees_total: action.payload.others.count,
                all_employees: action.payload.all.employees,
            }
        case RESET_STATE:
            return INITIAL_STATE
        default:
            return state
    }
}

export default employeeReducer
