import React, {Component, Fragment} from 'react'
import style from '../scss/components/SalonCode.module.scss'
import {connect} from 'react-redux'
import {reduxForm} from 'redux-form'
import {withTranslation} from 'react-i18next'
import classNames from 'classnames'
import {clearNextTry as clearSalonNextTry, postSalonCode, setEmployee} from '../actions/restrictionCode'
import InsertCodeContent from './InsertCodeContent'

class SalonCode extends Component {
    autoUpdateInterval = undefined
    componentUnmounted = false
    state = {
        current_time: (new Date()).getTime(),
        wrong_code: false,
    }

    onSubmit = formValues => {
        return this.props.postSalonCode(formValues).then(() => {
            if (!this.componentUnmounted) {
                if (this.state.wrong_code)
                    this.setState({
                        wrong_code: false,
                    })

                this.props.reset()
            }
        }).catch(error => {
            if (!this.componentUnmounted) {
                if (error.response.status === 401 && !this.state.wrong_code)
                    this.setState({
                        wrong_code: true,
                    })
                else if (error.response.status === 403 && this.state.wrong_code)
                    this.setState({
                        wrong_code: false,
                    })

                this.props.reset()
                if (this.wrapperRef)
                    this.wrapperRef.focus()
            }
        })
    }

    onChange = value => {
        setTimeout(() => {
            if (String(value).replaceAll(' ', '').length === this.props.length)
                this.props.submit()
        })
    }

    autoUpdateTime() {
        if (this.autoUpdateInterval)
            this.autoUpdateInterval = clearInterval(this.autoUpdateInterval)

        this.autoUpdateInterval = setInterval(() => {
            if (!this.componentUnmounted)
                this.setState({
                    current_time: (new Date()).getTime(),
                })
        }, 1000)
    }

    componentDidMount() {
        if (!this.props.salon_restriction.code && this.props.code_enabled)
            this.autoUpdateTime()

        if (this.props.code_enabled && !this.props.salon_restriction.code && this.props.employee_restriction?.item?.id)
            this.props.setEmployee({})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.salon_restriction.code && this.props.code_enabled && !this.autoUpdateInterval)
            this.autoUpdateTime()

        if (this.props.code_enabled && !this.props.salon_restriction.code && this.props.employee_restriction?.item?.id)
            this.props.setEmployee({})

        if (this.props.salon_restriction.next_try && this.props.salon_restriction.next_try <= this.state.current_time)
            this.props.clearSalonNextTry()
    }

    componentWillUnmount() {
        this.componentUnmounted = true
        if (this.autoUpdateInterval)
            this.autoUpdateInterval = clearInterval(this.autoUpdateInterval)
    }

    render() {
        if (!this.props.code_enabled)
            return null

        return (
            <Fragment>
                <div className={classNames(style.Background, {
                    [style.Show]: this.props.code_enabled && !this.props.salon_restriction.code,
                })}/>
                <div className={classNames(style.Wrapper, {
                    [style.Show]: this.props.code_enabled && !this.props.salon_restriction.code,
                })} ref={ref => this.wrapperRef = ref} tabIndex={-1}>
                    <InsertCodeContent onChange={this.onChange}
                                       handleSubmit={this.props.handleSubmit}
                                       onSubmit={this.onSubmit}
                                       code_enabled={!!this.props.code_enabled}
                                       code={this.props.salon_restriction.code}
                                       wrong_code={this.state.wrong_code}
                                       length={this.props.length}
                                       next_try={this.props.salon_restriction.next_try}
                                       current_time={this.state.current_time}/>
                </div>
            </Fragment>
        )
    }
}

const form = 'salonPasswordForm'

SalonCode = reduxForm({
    form: form,
    enableReinitialize: true,
})(SalonCode)

const mapStateToProps = state => {
    const initialValues = {
        code: '',
    }

    return {
        initialValues,
        salon_restriction: state.restriction_code.salon,
        employee_restriction: state.restriction_code.employee,
        code_enabled: state.salon_location.selected_location?.settings?.['salon.code_enabled'] ?? false,
        length: state.salon_location.selected_location?.settings?.['salon.code_length'] ?? 0,
    }
}

SalonCode = connect(mapStateToProps, {
    setEmployee,
    postSalonCode,
    clearSalonNextTry,
})(SalonCode)

SalonCode = withTranslation(['general', 'location'])(SalonCode)

export default SalonCode
