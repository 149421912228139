import React from 'react';
import langStyle from '../scss/components/LanguageSwitcher.module.scss';
import {showLanguageSwitch} from '../actions/languages';
import {changeLanguage, releaseContainerScroll} from '../actions/userInterface';
import connect from 'react-redux/es/connect/connect';
import {withTranslation} from 'react-i18next';
import classNames from 'classnames';
import requireMomentLocale from '../helpers/requireMomentLocale';

class LanguageSwitcher extends React.Component {
    languageAreaRef = React.createRef();

    closeLanguageSwitcher = () => {
        this.props.showLanguageSwitch(false);
        this.props.releaseContainerScroll();
    };

    changeLanguage = (lang) => {
        const {i18n} = this.props;

        i18n.changeLanguage(lang);
        this.props.changeLanguage(lang);
        this.closeLanguageSwitcher();

        document.documentElement.lang = lang;

        requireMomentLocale(lang);
    };

    renderLanguages() {
        const {i18n} = this.props;
        const languages = this.props.languages.items;

        return Object.keys(languages).map(key => {
            const language = languages[key];

            return (
                <div onClick={() => this.changeLanguage(language.code)}
                     key={language.code}
                     className={classNames(langStyle.Language, {
                         [langStyle.Active]: i18n.languages[0] === language.code,
                     })}>
                    {language.name || 'N / A'}
                </div>
            );
        });
    }

    render() {
        if (this.props.maintenance)
            return null;

        const {t} = this.props;

        let classes = langStyle.LanguageSwitcher;
        let boxClasses = langStyle.Box;

        if (this.props.languages.language_switch_open) {
            classes += ' ' + langStyle.Show;
            boxClasses += ' ' + langStyle.Show;
        }

        return (
            <React.Fragment>
                <div className={classes} onClick={this.closeLanguageSwitcher}/>
                <div className={boxClasses} ref={this.languageAreaRef}>
                    <div className={langStyle.Heading}>{t('language')}</div>
                    <div className={langStyle.Container}>
                        {this.renderLanguages()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        maintenance: state.location_lock.maintenance,
        languages: state.languages,
    };
};

const TranslatedLanguageSwitcher = withTranslation()(LanguageSwitcher);

export default connect(mapStateToProps, {
    showLanguageSwitch,
    releaseContainerScroll,
    changeLanguage,
})(TranslatedLanguageSwitcher);
