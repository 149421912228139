import React from 'react'
import inputStyle from '../scss/components/FormInput.module.scss';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

const FormTextareaField = (props) => {
    const {input, label, logo, meta: {touched, active, error}} = props;

    let inputRef;

    const onInputClick  = () => {
        inputRef.focus();
    };

    return (
        <div className={classNames(inputStyle.FormInput, inputStyle.TextArea, {
            [inputStyle.Selected]: active,
            [inputStyle.Error]: (touched || props.hasSubmissionError) && error,
        })} onClick={onInputClick}>
            <span className={inputStyle.Logo}>
                {logo}
            </span>
            <label className={inputStyle.Label}>
                <span className={inputStyle.Text}>{label}</span>
                <TextareaAutosize {...input} ref={ref => inputRef = ref} spellCheck={false}/>
                {touched && error && <span>{error}</span>}
            </label>
        </div>
    )
};

export default FormTextareaField;