import React from 'react';
import {withRouter} from 'react-router-dom';
import dialogStyle from '../scss/components/Dialog.module.scss';
import {withTranslation} from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import {closeDialog} from '../actions/userInterface';
import classNames from 'classnames';
import getNameInitals from '../getNameInitials';
import {addEmployeeToOrder} from '../actions/order';
import {setEmployee as setEmployeeCode} from '../actions/restrictionCode'

class Dialog extends React.Component {

    scheduleButtonOnClick = () => {
        if (this.props.code_enabled && (!this.props.employee_restriction?.code || (this.props.employee_restriction?.code && this.props.employee_restriction?.id !== this.props.employee.id))) {
            this.props.setEmployeeCode(this.props.employee);
        } else {
            this.props.addEmployeeToOrder(this.props.employee);
            this.dismiss();
            this.props.history.push(this.props.schedule_path);
        }
    };

    renderEmployeeImage() {
        const {employee} = this.props;

        if (!employee.image)
            return (
                <div className={dialogStyle.NoImage}>
                    <span>{getNameInitals(employee.name)}</span>
                </div>
            );

        return (
            <img src={employee.image} alt=""/>
        );
    }

    renderClose() {
        if (Object.keys(this.props.employee).length)
            return (
                <div className={dialogStyle.Close} onClick={this.dismiss}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                        <path
                            d="M17.707.293a1 1 0 0 0-1.414 0L9 7.586 1.707.293A1 1 0 1 0 .293 1.707L7.586 9 .293 16.293a1 1 0 1 0 1.414 1.414L9 10.414l7.293 7.293a.997.997 0 0 0 1.414 0 1 1 0 0 0 0-1.414L10.414 9l7.293-7.293a1 1 0 0 0 0-1.414z"/>
                    </svg>
                </div>
            );

        return null;
    }

    renderTitle() {

        if (Object.keys(this.props.employee).length) {

            return (
                <div>
                    <div className={dialogStyle.EmployeeImage}>
                        {this.renderEmployeeImage()}
                    </div>
                    <div className={classNames({
                        [dialogStyle.Title]: true,
                        [dialogStyle.EmployeeTitle]: true,
                    })} dangerouslySetInnerHTML={{__html: this.props.employee.name}}/>
                </div>
            );
        } else {
            if (!this.props.title)
                return null;

            return (
                <div className={dialogStyle.Title} dangerouslySetInnerHTML={{__html: this.props.title}}/>
            );
        }
    }

    renderDescription() {

        const {i18n} = this.props;
        const curLang = i18n.languages[0];

        if (Object.keys(this.props.employee).length) {
            const descriptions = this.props.employee.descriptions;
            const description = descriptions[curLang] ?? null;

            return (
                <div className={classNames({
                    [dialogStyle.Description]: true,
                    [dialogStyle.EmployeeDescription]: true,
                })} dangerouslySetInnerHTML={{__html: description}}/>
            );
        } else {
            if (!this.props.description)
                return null;

            return (
                <div className={dialogStyle.Description} dangerouslySetInnerHTML={{__html: this.props.description}}/>
            );
        }
    }

    renderFooter() {

        const {t} = this.props;

        if (Object.keys(this.props.employee).length) {
            return (
                <button className={dialogStyle.ScheduleButton}
                        onClick={this.scheduleButtonOnClick}>{t('schedule')}</button>
            )
        }

        return (
            <div className={dialogStyle.Dismiss} onClick={this.dismiss}>
                <svg viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16.087.325L5.625 10.4 1.913 6.825a1.117 1.117 0 0 0-1.576 0c-.45.433-.45 1.083 0 1.517l4.5 4.333c.226.217.45.325.788.325.337 0 .563-.108.788-.325l11.25-10.833c.45-.434.45-1.084 0-1.517a1.117 1.117 0 0 0-1.576 0z"
                        fillRule="nonzero"/>
                </svg>
            </div>
        );

    }

    dismissBg = (event) => {
        if (event.target === event.currentTarget) {
            this.dismiss();
        }
    };

    dismiss = () => {
        this.props.closeDialog();
    };

    render() {

        let classes = dialogStyle.Confirm;

        if (this.props.dialog.show)
            classes += ' ' + dialogStyle.Show;

        let contentClasses = dialogStyle.ConfirmBox;

        if (Object.keys(this.props.employee).length)
            contentClasses += ' ' + dialogStyle.Employee;

        return (
            <div className={classes} onClick={this.dismissBg}>
                <div className={contentClasses}>
                    {this.renderClose()}

                    {this.renderTitle()}

                    {this.renderDescription()}

                    {this.renderFooter()}
                </div>
            </div>
        );
    }
}

Dialog.defaultProps = {
    title: '',
    description: '',
    employee: {},
    code_enabled: false,
};

const mapStateToProps = (state) => {
    return {
        schedule_path: state.routes.paths.schedule,
        dialog: state.ui.dialog,
        employee_restriction: state.restriction_code.employee,
    };
};

const routerWrap = withRouter(Dialog);

const TranslatedDialog = withTranslation(['general'])(routerWrap);

export default connect(mapStateToProps, {
    closeDialog,
    addEmployeeToOrder,
    setEmployeeCode,
})(TranslatedDialog);
