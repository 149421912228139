import React from 'react'
import {connect} from './ReactPageComponent'
import {withTranslation} from 'react-i18next'
import {Field, Fields, Form, reduxForm} from 'redux-form'
import FormInputField from './FormInputField'
import FormTextareaField from './FormTextareaField'
import FormTermsField from './FormTermsField'
import FormDateField from './FormDateField'
import FormPhoneField from './FormPhoneField'
import {email, number, required} from '../validation'
import {ReactComponent as PersonLogo} from '../assets/svg/team-icon.svg'
import {ReactComponent as PhoneLogo} from '../assets/svg/phone.svg'
import {ReactComponent as EmailLogo} from '../assets/svg/email.svg'
import {ReactComponent as PencilLogo} from '../assets/svg/pencil.svg'
import {ReactComponent as BirthdayLogo} from '../assets/svg/cake.svg'
import {ReactComponent as CountryLogo} from '../assets/svg/country.svg'
import {ReactComponent as LocationLogo} from '../assets/svg/pin.svg'
import {ReactComponent as GenderLogo} from '../assets/svg/gender.svg'
import {FormSelectField} from './FormSelectField'
import {confirmBooking, validateCustomer} from '../actions/booking'
import {Redirect, withRouter} from 'react-router-dom'
import style from '../scss/components/CustomerForm.module.scss'
import Loading from './Loading'
import FacebookPixel from '../helpers/facebookPixel'

class CustomerForm extends React.Component {
    getLogoByName = name => {
        if (name === 'first_name' || name === 'last_name')
            return <PersonLogo/>
        else if (name === 'phone')
            return <PhoneLogo/>
        else if (name === 'email')
            return <EmailLogo/>
        else if (name === 'birth_date')
            return <BirthdayLogo/>
        else if (name === 'gender')
            return <GenderLogo/>
        else if (name === 'country')
            return <CountryLogo/>

        return <LocationLogo/>
    }

    renderFields = () => {
        const {t} = this.props
        const customer = this.props.fields['Customer']
        const _this = this

        const genderOptions = [
            {
                label: t(_this.props.trans_group + ':male'),
                value: 'Male',
            },
            {
                label: t(_this.props.trans_group + ':female'),
                value: 'Female',
            },
        ]

        let fields = Object.keys(customer).map(function (key) {
            if (key === 'data' && customer[key].length) {
                return customer[key].map(item => {
                    return <Field
                        name={item}
                        type="text"
                        component={FormInputField}
                        label={t(_this.props.trans_group + ':' + item)}
                        props={{disabled: true}}
                        key={item}
                        logo={_this.getLogoByName(item)}
                        hasSubmissionError={_this.props.errorFields[customer[key]]}
                    />
                })
            } else if (customer[key] === 'birth_date') {
                return <Field
                    name={customer[key]}
                    type="text"
                    component={FormDateField}
                    label={t(_this.props.trans_group + ':' + customer[key])}
                    key={customer[key]}
                    logo={_this.getLogoByName(customer[key])}
                    hasSubmissionError={_this.props.errorFields[customer[key]]}
                />
            } else if (customer[key] === 'phone') {
                return <Fields
                    names={['phone_prefix', 'phone']}
                    component={FormPhoneField}
                    label={t(_this.props.trans_group + ':' + customer[key])}
                    key={customer[key]}
                    logo={_this.getLogoByName(customer[key])}
                    hasSubmissionError={{
                        phone: _this.props.errorFields[customer['phone']],
                        phone_prefix: _this.props.errorFields[customer['phone_prefix']],
                    }}
                />
            } else if (customer[key] === 'gender') {
                return <Field
                    name={customer[key]}
                    component={FormSelectField}
                    label={t(_this.props.trans_group + ':' + customer[key])}
                    key={customer[key]}
                    logo={_this.getLogoByName(customer[key])}
                    options={genderOptions}
                    hasSubmissionError={_this.props.errorFields[customer[key]]}
                />
            } else {
                return (
                    <Field
                        name={customer[key]}
                        type={customer[key] === 'email' ? 'email' : 'text'}
                        component={FormInputField}
                        label={t(_this.props.trans_group + ':' + customer[key])}
                        key={customer[key]}
                        logo={_this.getLogoByName(customer[key])}
                        hasSubmissionError={_this.props.errorFields[customer[key]]}
                    />
                )
            }
        })

        if (this.props.fields['CalendarEntry'] && this.props.fields['CalendarEntry'].length) {
            const field = <Field
                name="note"
                component={FormTextareaField}
                label={t(_this.props.trans_group + ':note')}
                key="note"
                logo={<PencilLogo/>}
                hasSubmissionError={_this.props.errorFields?.note}
            />

            fields = [...fields, field]
        }

        if (this.props.settings['my_salon_group.customer.terms_of_service']) {
            const termsField = <Field
                name="terms"
                component={FormTermsField}
                label={t('label')}
                key="terms"
                hasSubmissionError={_this.props.errorFields?.terms}
            />

            fields = [...fields, termsField]
        }

        return fields
    }

    sendLeadEvent() {
        const facebook_id = this.props.salon?.settings?.['salon.facebook_pixel_id'] ?? null
        if (facebook_id)
            FacebookPixel.trackSingleCustom(facebook_id, 'Lead')
    }

    onSubmit = (formValues) => {
        return this.props.validateCustomer(formValues).then(() => {
            if (this.props.settings['my_salon_group.booking.ask_confirmation']) {
                this.props.history.push(this.props.paths.review)
                this.sendLeadEvent()
            } else {
                this.props.confirmBooking()
                this.sendLeadEvent()
            }
        })
    }

    render() {

        if (this.props.booking)
            return <Redirect to={this.props.paths.booking}/>

        if (this.props.submittingBooking) {
            return <Loading/>
        }

        return (
            <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                {this.renderFields()}
                <button type={'submit'} className={style.HiddenSubmit}/>
            </Form>
        )
    }
}

const getFieldValidationByName = (value, name, fields) => {
    let requiredFieldKeys = fields
    if (Array.isArray(requiredFieldKeys)) {
        requiredFieldKeys = {...requiredFieldKeys}
        fields = {...fields}
    }

    let hasError
    requiredFieldKeys = Object.keys(requiredFieldKeys)

    for (let i = 0; i < requiredFieldKeys.length; ++i) {
        const key = requiredFieldKeys[i]

        if (key === 'data' && fields[key].length) {
            for (let k = 0; k < fields[key].length; ++k) {
                const item = fields[key][k]

                if (item === name)
                    return required(value)
            }
        } else if (fields[key] === name) {
            if (name === 'email') {
                hasError = required(value)

                if (!hasError)
                    return email(value)
                else
                    return hasError
            } else if (name === 'phone') {
                hasError = required(value)

                if (!hasError)
                    return number(value)
                else
                    return hasError
            } else
                return required(value)
        }
    }

    return false
}

const mapStateToProps = (state) => {
    return {
        errorFields: state.booking.errorFields ?? {},
        paths: state.routes.paths,
        salon: state.salon_location.selected_location,
        trans_group: state.ui.trans_group,
        settings: state.salon_location.group_settings,
        booking: state.booking.booking,
        submittingBooking: state.booking.submitting,
    }
}

const validate = (formValues, props) => {
    const errors = {}

    Object.keys(props.fields['Customer']).forEach(i => {
        const key = props.fields['Customer'][i]

        if (Array.isArray(key)) {
            key.forEach(dataKey => {
                errors[dataKey] = getFieldValidationByName(formValues[dataKey], dataKey, props.requiredFields['Customer'])
            })
        } else {
            errors[key] = getFieldValidationByName(formValues[key], key, props.requiredFields['Customer'])

            if (key === 'email' && formValues[key] && !errors[key]) {
                errors[key] = email(formValues[key])
            }
        }
    })

    if (props.fields['CalendarEntry'] && props.fields['CalendarEntry'].length && props.requiredFields['CalendarEntry'] && props.requiredFields['CalendarEntry'].length)
        errors.note = required(formValues.note)

    if (props.settings['my_salon_group.customer.terms_of_service'])
        errors.terms = required(formValues.terms)

    return errors
}

CustomerForm = withRouter(CustomerForm)

CustomerForm = withTranslation(['terms'])(CustomerForm)
CustomerForm = reduxForm({
    form: 'customerForm',
    destroyOnUnmount: false,
    enableReinitialize: true,
    validate: validate,
})(CustomerForm)

CustomerForm = connect(mapStateToProps, {
    validateCustomer,
    confirmBooking,
})(CustomerForm)

export default CustomerForm
