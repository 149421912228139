import axios from 'axios';
import {apiDomain} from '../env';

const salonlife = (() => {
    let headers = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    };

    return axios.create({
        baseURL: apiDomain,
        headers: headers,
    });
})();

export default salonlife;
