import React from 'react';
import { ReactSVG } from 'react-svg'
import { widgetDomain } from '../env';

class Flag extends React.Component {
    render() {
        const style = {};
        const {code, width, height, ...rest } = this.props;

        if (width)
            style.width = width;

        if (height)
            style.height = height;

        return (
            <ReactSVG src={widgetDomain + '/flags/' + code + '.svg'} style={style} {...rest} />
        );
    }
}

Flag.defaultProps = {

};

export default Flag;
