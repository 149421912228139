import {
    GET_EMPLOYEE_SCHEDULE,
    ON_ACTIVE_DATE_CHANGE,
    ON_OVERLAY_LOADING,
    ON_SCHEDULE_DATE_SELECT,
    RESET_SCHEDULE,
    SET_NEW_WEEK_DATES,
    SET_SCHEDULE_EDIT_ACTIVE,
    SET_SCHEDULE_VIEW_ACTIVE,
} from './type';
import salonlife from '../api/salonlife';
import moment from 'moment';
import getApiPath from '../helpers/getApiPath';
import {cancelTheRequest, registerTheRequest, registerTimeout} from '../helpers/axiosCancel';

export const setNewWeekDates = (start_date, end_date) => {
    return dispatch => {
        dispatch({
            type: SET_NEW_WEEK_DATES,
            payload: {
                start_date,
                end_date,
            },
        });
    };
};

export const showOverlayLoading = (shouldShow = true) => {
    return dispatch => {
        dispatch({
            type: ON_OVERLAY_LOADING,
            payload: shouldShow,
        });
    };
};

export const getEmployeeSchedule = (start, end, selected_date = null) => {
    const uniqueKey = 'getEmployeeSchedule';
    cancelTheRequest(uniqueKey);

    return async (dispatch, getState) => {
        const state = getState();
        const params = {
            service_ids: state.order.ids,
            start: start,
            end: end,
        };

        const employee_id = state.order?.selected_employee?.id;
        if (!employee_id)
            return;

        showOverlayLoading(true)(dispatch, getState);

        setNewWeekDates(start, end)(dispatch, getState);
        if (selected_date !== null) {
            setActiveDate(selected_date)(dispatch, getState);
        } else {
            setActiveDate(start)(dispatch, getState);
        }

        return await salonlife.get(getApiPath(state.routes, state.widget, state.salon_location) + '/employee/' + employee_id + '/schedule', {
            params,
            cancelToken: registerTheRequest(uniqueKey),
        }).then(response => {
            if (selected_date !== null) {
                setActiveDate(selected_date)(dispatch, getState);

                if (response.data.available_times[selected_date]) {
                    dispatch({
                        type: GET_EMPLOYEE_SCHEDULE,
                        payload: response.data,
                    });

                    onSelectDate(selected_date)(dispatch, getState);
                    showOverlayLoading(false)(dispatch, getState);
                } else {
                    const newStart = moment(start).clone().add(1, 'week').format('YYYY-MM-DD');
                    const newEnd = moment(end).clone().add(1, 'week').format('YYYY-MM-DD');

                    getEmployeeSchedule(newStart, newEnd)(dispatch, getState);
                }
            } else {
                dispatch({
                    type: GET_EMPLOYEE_SCHEDULE,
                    payload: response.data,
                });

                if (response.data.selected_date)
                    setActiveDate(response.data.selected_date)(dispatch, getState);
                else
                    setActiveDate(start)(dispatch, getState);

                showOverlayLoading(false)(dispatch, getState);
            }

            return response;
        }).catch(error => {
            if (error && error.response && error?.response?.status !== 404)
                registerTimeout(uniqueKey, () => {
                    getEmployeeSchedule(start, end, selected_date)(dispatch, getState);
                }, 60000);

            throw error;
        });
    };
};

export const onSelectDate = date => {
    return dispatch => {
        dispatch({
            type: ON_SCHEDULE_DATE_SELECT,
            payload: date,
        });
    };
};

export const setActiveDate = date => {
    return dispatch => {
        dispatch({
            type: ON_ACTIVE_DATE_CHANGE,
            payload: date,
        });
    };
};

export const setScheduleViewActive = bool => {
    return {
        type: SET_SCHEDULE_VIEW_ACTIVE,
        payload: bool,
    }
};

export const setScheduleEditActive = bool => {
    return {
        type: SET_SCHEDULE_EDIT_ACTIVE,
        payload: bool,
    }
};

export const resetSchedule = () => {
    return dispatch => {
        dispatch({
            type: RESET_SCHEDULE,
            payload: {},
        });
    };
};
