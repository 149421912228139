import {GET_CANCEL_BOOKING, ON_CANCEL_BOOKING_SUCCESS, ON_CANCELLING_BOOKING, RESET_STATE} from '../actions/type'

const INITIAL_STATE = {
    booking: null,
    submitting: false,
}

const cancelReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_CANCEL_BOOKING:
            return {
                ...state,
                booking: action.payload,
                submitting: false,
            }
        case ON_CANCELLING_BOOKING:
            return {
                ...state,
                submitting: true,
            }
        case ON_CANCEL_BOOKING_SUCCESS:
            return {
                ...state,
                booking: action.payload,
                submitting: false,
            }
        case RESET_STATE:
            return INITIAL_STATE
        default:
            return state
    }
}

export default cancelReducer
