import React from 'react';
import confirmStyle from "../scss/components/Confirm.module.scss";
import {withTranslation} from "react-i18next";
import connect from "react-redux/es/connect/connect";
import {hideConfirm} from "../actions/userInterface";

class Confirm extends React.Component {
    confirmText = () => {
        const {t} = this.props;

        if (!this.props.confirm_text)
            return t('yes');

        return this.props.confirm_text;
    };

    cancelText = () => {
        const {t} = this.props;

        if (!this.props.cancel_text)
            return t('no');

        return this.props.cancel_text;
    };

    renderTitle() {
        if (! this.props.title)
            return null;

        return (
            <div className={confirmStyle.Title} dangerouslySetInnerHTML={{__html: this.props.title}} />
        );
    }

    renderDescription() {
        if (! this.props.description)
            return null;

        return (
            <div className={confirmStyle.Description} dangerouslySetInnerHTML={{__html: this.props.description}} />
        );
    }

    onCancel = (event) => {
        if (event.target === event.currentTarget) {
            this.props.hideConfirm();
        }
    };

    onConfirm = () => {
        if (typeof this.props.onConfirm === 'function')
            this.props.onConfirm();

        this.props.hideConfirm();
    };

    render() {
        let classes = confirmStyle.Confirm;

        if (this.props.confirm.show)
            classes += ' ' + confirmStyle.Show;

        return (
            <div className={classes} onClick={this.onCancel}>
                <div className={confirmStyle.ConfirmBox}>
                    { this.renderTitle() }

                    { this.renderDescription() }

                    <div className={confirmStyle.Buttons}>
                        <div className={confirmStyle.Button}>
                            <div className={confirmStyle.SecondaryButton} onClick={this.onCancel}>
                                {this.cancelText()}
                            </div>
                        </div>
                        <div className={confirmStyle.Button}>
                            <div className={confirmStyle.PrimaryButton} onClick={this.onConfirm}>
                                {this.confirmText()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Confirm.defaultProps = {
    title: '',
    description: '',
    confirm_text: null,
    cancel_text: null,
    onConfirm: null
};

const mapStateToProps = (state) => {
    return {
        confirm: state.ui.confirm
    };
};

const TranslatedConfirm = withTranslation(['general'])(Confirm);

export default connect(mapStateToProps, {
    hideConfirm
})(TranslatedConfirm);
