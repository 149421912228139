import React from 'react';
import {withTranslation} from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import styleHeader from '../scss/components/Header.module.scss';
import Flag from './Flag'
import {widgetScrollContentId} from '../env';
import {showLanguageSwitch} from '../actions/languages';
import {preventContainerScroll, releaseContainerScroll} from '../actions/userInterface';
import MoveBackButton from './MoveBackButton';

class Header extends React.Component {
    scrollContentId = widgetScrollContentId;

    showLanguageSwitchComponent = () => {
        const isOpen = !this.props.languages.language_switch_open;

        this.props.showLanguageSwitch(isOpen);

        if (isOpen)
            this.props.preventContainerScroll();
        else
            this.props.releaseContainerScroll();
    };

    renderAddress() {
        const {i18n} = this.props;

        const selected_location = this.props.salon_location.selected_location;
        if (!selected_location || Object.keys(selected_location).length === 0)
            return null;

        const curLang = i18n.languages[0];
        const name = selected_location?.location_names[curLang] ?? selected_location.name;

        return (
            <div className={styleHeader.SalonName}>{name}</div>
        );
    }

    renderFlag = () => {
        const {i18n, languages} = this.props;

        if (languages?.items && Object.keys(languages?.items).length > 1) {
            return (
                <Flag code={this.props.languages?.items[i18n.languages[0]]?.flag ?? 'gb'}
                      width="24px"
                      height="18px"
                      onClick={this.showLanguageSwitchComponent}/>
            );
        }
    }

    render() {
        if (!this.props.languages_loaded)
            return null;

        const {t} = this.props;

        return (
            <div className={styleHeader.Header}>
                <div className={styleHeader.Navigator}>
                    <MoveBackButton/>
                </div>
                <div className={styleHeader.Heading}>
                    <div className={styleHeader.Title}>{t(this.props.ui.trans_group + ':heading')}</div>
                    {this.renderAddress()}
                </div>
                <div className={styleHeader.Lang}>
                    {this.renderFlag()}
                </div>
            </div>
        );
    }
}

Header.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        salon_location: state.salon_location,
        languages: state.languages,
        languages_loaded: state.languages.languages_loaded,
        ui: state.ui,
    };
};

const TranslatedHeader = withTranslation()(Header);

export default connect(mapStateToProps, {
    showLanguageSwitch,
    preventContainerScroll,
    releaseContainerScroll,
})(TranslatedHeader);
