const search = (haystack, needle) => {
    haystack = haystack.toLowerCase()
    const needles = needle.toLowerCase().split(' ')

    for (let i = 0; i < needles.length; ++i) {
        needle = needles[i]
        if (haystack.indexOf(needle) === -1)
            return false
    }

    return true
}

export default search
