import React from 'react';
import styleItem from '../scss/components/ScheduleMonthSelection.module.scss';
import Moment from 'react-moment';
import {connect} from './ReactPageComponent';
import {getEmployeeSchedule} from '../actions/schedule';
import {addDateTimeToOrder} from '../actions/order';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import Swipe from 'react-easy-swipe';

class ScheduleMonthSelection extends React.Component {
    first_date = '';
    swipe_position = 0;

    state = {
        swipe_position: 0,
    };

    constructor(props) {
        super(props);

        this.setFirstDate();
    }

    setFirstDate() {
        const date = this.props.order?.selected_employee?.first_available_date ?? this.props.selected_date;
        if (date && !this.first_date)
            this.first_date = date;
    }

    componentDidMount() {
        this.setFirstDate();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const date = this.props.order?.selected_employee?.first_available_date ?? this.props.selected_date;
        if (!this.first_date && date)
            this.first_date = date;
    }

    onPreviousMonthClick = () => {
        let daysDiff = 0;
        if (this.props.active_date)
            daysDiff = moment(this.props.active_date).diff(moment(this.first_date), 'days');

        if (daysDiff > 0) {
            const firstYearMonth = moment(this.first_date).unix();

            let prev_month_date = moment(this.props.active_date).subtract(1, 'months').startOf('month');

            const selectedYearMonth = prev_month_date.unix();

            if (firstYearMonth >= selectedYearMonth) {
                prev_month_date = moment(this.first_date);
            }

            this.props.getEmployeeSchedule(
                prev_month_date.clone().isoWeekday(this.props.settings['salon.week_start']).format('YYYY-MM-DD'),
                prev_month_date.clone().isoWeekday(this.props.settings['salon.week_start']).add(6, 'days').format('YYYY-MM-DD'),
                prev_month_date.format('YYYY-MM-DD'),
            );

            this.props.addDateTimeToOrder(null, null);
        }
    };

    onNextMonthClick = () => {
        const next_month_date = moment(this.props.active_date).add(1, 'months').startOf('month');

        this.props.getEmployeeSchedule(
            next_month_date.clone().isoWeekday(this.props.settings['salon.week_start']).format('YYYY-MM-DD'),
            next_month_date.clone().isoWeekday(this.props.settings['salon.week_start']).add(6, 'days').format('YYYY-MM-DD'),
            next_month_date.format('YYYY-MM-DD'),
        );

        this.props.addDateTimeToOrder(null, null);
    };

    onSwipeMove = (position) => {
        this.swipe_position = position.x;

        this.setState({
            swipe_position: position.x + 'px',
        });
    };

    onSwipeEnd = () => {
        if (this.swipe_position <= -80) {
            this.onNextMonthClick();
        } else if (this.swipe_position >= 80) {
            this.onPreviousMonthClick();
        }

        this.swipe_position = 0;

        this.setState({
            swipe_position: 0,
        });
    };

    render() {
        const {i18n} = this.props;

        const monthItemStyle = {
            transform: 'translate3d(' + this.state.swipe_position + ', 0, 0)',
        };

        return (
            <Swipe className={styleItem.ScheduleMonthSelection}
                   onSwipeMove={this.onSwipeMove}
                   onSwipeEnd={this.onSwipeEnd}>
                <div className={styleItem.Content}>
                    <div className={styleItem.Arrow} onClick={this.onPreviousMonthClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 11">
                            <path fillRule="nonzero" d="M5.5 6.5h3.536a1 1 0 1 0 0-2H5.5V.55L.55 5.5l4.95 4.95V6.5z"/>
                        </svg>
                    </div>
                    <div className={styleItem.MonthName} style={monthItemStyle}><Moment element="div"
                                                                                        format="MMMM, YYYY"
                                                                                        locale={i18n.languages[0]}>{this.props.active_date}</Moment>
                    </div>
                    <div className={styleItem.Arrow} onClick={this.onNextMonthClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 11">
                            <path fillRule="nonzero" d="M4.5 4.5H.964a1 1 0 1 0 0 2H4.5v3.95L9.45 5.5 4.5.55V4.5z"/>
                        </svg>
                    </div>
                </div>
            </Swipe>
        );
    }
}

const mapStateToProps = (state) => {
    const schedule = state.schedule || {};
    const active_date = schedule.active_date || '';
    const selected_date = schedule.selected_date || '';

    return {
        schedule,
        active_date,
        selected_date,
        order: state.order,
        settings: state.salon_location.selected_location.settings,
    };
};

ScheduleMonthSelection = withTranslation()(ScheduleMonthSelection);

export default connect(mapStateToProps, {
    getEmployeeSchedule,
    addDateTimeToOrder,
})(ScheduleMonthSelection);
