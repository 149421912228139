const requiredLocales = ['en']

const requireMomentLocale = locale => {
    if (requiredLocales.indexOf(locale) === -1) {
        try {
            require('moment/locale/' + locale)

            requiredLocales.push(locale)
        } catch (err) {
            console.warn('Missing locale: moment/locale/' + locale)
        }
    }
}

export default requireMomentLocale
