import React, {Fragment, Suspense} from 'react';
import connect from 'react-redux/es/connect/connect';
import MainLayout from '../layout/Main';
import {getColors} from '../colors';

class Web extends React.Component {
    loadingBackgroundEl = null;
    loading_background_animation_start = undefined;
    appContentRef = React.createRef();
    steps = 5;
    initialColor = '';
    background_opacity_timeout = undefined;
    unmounted = false;

    convertColors(constructor = false) {
        const colors = getColors(this.props.color);

        if (!constructor && !this.unmounted)
            this.setState({
                backgroundLoadingColor: colors.SalonLife__primary_color_alpha_22,
            });

        Object.keys(colors).forEach(color_key => {
            document.documentElement.style.setProperty('--' + color_key, colors[color_key]);
        });

        return colors.SalonLife__primary_color_alpha_22;
    }

    constructor(props) {
        super(props);

        this.initialColor = this.props.color;
        const state = {
            background: {},
            background_opacity: 0,
            backgroundLoadingColor: 'rgba(117, 42, 216, 0.22)',
        };

        state.backgroundLoadingColor = this.convertColors(true);

        this.state = state;
    }

    preloadBackgroundImage() {
        if (this.props.background && this.props.background.src) {
            const img = new Image();

            img.src = this.props.background.src;
            img.onload = () => {
                if (!this.unmounted)
                    this.setState({
                        background: this.props.background,
                    }, () => {
                        if (!this.unmounted)
                            this.background_opacity_timeout = setTimeout(() => {
                                if (!this.unmounted)
                                    this.setState({
                                        background_opacity: 1,
                                    })
                            }, 0);
                    });
            };
        }
    }

    componentDidMount() {
        if (this.initialColor !== this.props.color)
            this.convertColors();

        if (this.props.background?.src)
            this.preloadBackgroundImage();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.color !== prevProps.color)
            this.convertColors();

        if ((prevProps.background?.src !== this.props.background?.src || (prevProps.window.width <= 700 && this.props.window.width > 700)))
            this.preloadBackgroundImage();
    }

    componentWillUnmount() {
        this.unmounted = true;

        if (this.background_opacity_timeout)
            this.background_opacity_timeout = clearTimeout(this.background_opacity_timeout);
    }

    getLighten() {
        const bg_lighten = this.state.background.lighten ?? 0;

        const lighten = bg_lighten / 2 * (100 / this.steps);

        return (100 + lighten) / 100;
    }

    getDarken() {
        const bg_darken = this.state.background.darken ?? 0;

        const darken = bg_darken / 2 * (100 / this.steps);

        return (100 - darken) / 100;
    }

    loadingBackgroundRef = el => {
        this.loadingBackgroundEl = el;

        if (this.loadingBackgroundEl && !this.state.background?.src && typeof requestAnimationFrame === 'function')
            requestAnimationFrame(this.animationLoop);
    };

    animationLoop = timestamp => {
        if (!this.loadingBackgroundEl || this.state.background?.src) {
            return;
        }

        if (!this.loading_background_animation_start)
            this.loading_background_animation_start = timestamp;

        const animationDuration = 1500;
        const progress = ((timestamp - this.loading_background_animation_start) % animationDuration) / animationDuration;
        const x = (progress - 0.5) * 200;

        this.loadingBackgroundEl.style.transform = `translateX(${x}%) rotateZ(10deg) scaleY(1.5)`;

        requestAnimationFrame(this.animationLoop);
    };

    renderLoadingBackground() {
        return (
            <div style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                background: this.state.backgroundLoadingColor,
                overflow: 'hidden',
                pointerEvents: 'none',
                transition: 'opacity 0.5s',
                opacity: 1,
            }}>
                <div ref={this.loadingBackgroundRef} style={{
                    position: 'absolute',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    left: 0,
                    top: 0,
                    background: 'linear-gradient(90deg,rgba(255,255,255,0) 0,rgba(255,255,255,0.3) 50%,rgba(255,255,255,0) 100%)',
                    backgroundSize: '50% 100%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}/>
            </div>
        );
    }

    renderBackground() {
        if (this.props.window.width <= 700)
            return null;

        if (!this.state.background.src)
            return this.renderLoadingBackground();

        const darken = this.getDarken() || 0.1;
        const lighten = this.getLighten();
        const blur = this.state.background.blur ?? 1;

        const bg_width = this.state.background.width ?? this.props.window.width;
        const bg_height = this.state.background.height ?? this.props.window.height;

        let scale;
        const scale1 = this.props.window.width / bg_width;
        const scale2 = this.props.window.height / bg_height;

        if (scale1 > scale2)
            scale = scale1;
        else
            scale = scale2;

        return (
            <Fragment>
                {this.renderLoadingBackground()}
                <svg
                    viewBox={'0 0 ' + this.props.window.width + ' ' + this.props.window.height}
                    style={{opacity: this.state.background_opacity}}>
                    <defs>
                        <filter id="DesignBackground" x="-25%" y="-25%" width="150%" height="150%">
                            <feComponentTransfer in="SourceGraphic" result="darken">
                                <feFuncR type="linear" slope={darken}/>
                                <feFuncG type="linear" slope={darken}/>
                                <feFuncB type="linear" slope={darken}/>
                            </feComponentTransfer>
                            <feComponentTransfer in="darken" result="lighten">
                                <feFuncR type="linear" slope={lighten}/>
                                <feFuncG type="linear" slope={lighten}/>
                                <feFuncB type="linear" slope={lighten}/>
                            </feComponentTransfer>
                            <feGaussianBlur in="lighten" stdDeviation={blur}/>
                        </filter>
                    </defs>

                    <image filter="url(#DesignBackground)"
                           xlinkHref={this.state.background.src}
                           width={this.state.background.width}
                           height={this.state.background.height}
                           x={this.props.window.width / 2}
                           y={this.props.window.height / 2}
                           style={{
                               transform: 'scale(' + scale + ') translate3d(-50%, -50%, 0)',
                               WebkitTransform: 'scale(' + scale + ') translate3d(-50%, -50%, 0)',
                           }}
                           preserveAspectRatio="none"/>
                </svg>
            </Fragment>
        );
    }

    render() {
        return (
            <Fragment>
                {this.renderBackground()}
                <Suspense fallback={null}>
                    <MainLayout routerHistory={this.props.routerHistory}
                                language={this.props.language}
                                appContentRef={this.appContentRef}
                                is_web={window.is_web}/>
                </Suspense>
            </Fragment>
        );
    }
}

Web.defaultProps = {
    language: 'en',
    routerHistory: {},
};

const mapStateToProps = state => {
    return {
        widget: state.widget,
        window: state.window,
        background: state.salon_location.background,
        color: state.salon_location.background.color ?? '#7367f9',
    };
};

export default connect(mapStateToProps, {})(Web);
