import React from 'react';
import {Redirect} from 'react-router-dom';
import ReactPageComponent, {connect} from '../components/ReactPageComponent';
import {withTranslation} from 'react-i18next';
import {setTransGroup} from '../actions/userInterface';
import {startNewBooking} from '../actions/booking';
import {cancelBooking, getCancelBooking} from '../actions/cancel';
import style from '../scss/components/Cancel.module.scss';
import {ReactComponent as XLogo} from '../assets/svg/delete.svg';
import classNames from 'classnames';
import Loading from '../components/Loading';

class Cancel extends ReactPageComponent {
    scrollPosKey = 'Cancel';
    _isMounted = false;

    state = {
        loading: true,
    };

    getHash() {
        return this.props.match.params.hash;
    }

    componentDidMount() {
        super.componentDidMount();
        this._isMounted = true;

        this.props.setTransGroup('cancel');
        this.props.getCancelBooking(this.getHash()).then(response => {
            if (this._isMounted) {
                this.setState({
                    loading: false,
                })
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderButtons() {
        const {t} = this.props;

        const actionAfter = this.props.settings['my_salon_group.cancel.action_after'];
        let externalLink = this.props.settings['my_salon_group.cancel.external_link'];

        if (externalLink) {
            if (!/^https?:\/\//i.test(externalLink)) {
                externalLink = 'http://' + externalLink;
            }
        }

        if (actionAfter === 'new_booking') {
            return (
                <button onClick={() => this.props.history.push('/')}>
                    {t('booking:new_booking')}
                </button>
            );
        } else {
            return (
                <button onClick={() => window.location.href = externalLink}>
                    {t('booking:back_to_website')}
                </button>
            );
        }
    }

    renderCanceledView() {
        const {t} = this.props;

        return (
            <div className={style.Cancel}>
                <div className={style.Image}>
                    <div className={style.Icon}>
                        <XLogo/>
                    </div>
                </div>
                <div className={style.Header}>
                    {t('success')}
                </div>
                <div className={classNames(style.Buttons, style.NoSubHeader)}>
                    {this.renderButtons()}
                </div>
            </div>
        );
    }

    render() {

        const {t} = this.props;

        if (this.state.loading)
            return <Loading/>;

        if (!this.props.booking)
            return <Redirect to={'/'}/>;

        if (this.props.errorCode)
            return <Redirect to={this.props.error_path}/>;

        if (this.props.booking && this.props.booking.status === 'canceled') {
            return this.renderCanceledView();
        }

        return (
            <div className={style.Cancel}>
                <div className={style.Image}>
                    <div className={style.Icon}>
                        <XLogo/>
                    </div>
                </div>
                <div className={style.Header}>
                    {t('question_heading')}
                </div>
                <div className={style.SubHeader}>
                    {t('question')}
                </div>
                <div className={style.Buttons}>
                    <button disabled={this.props.submitting} onClick={() => this.props.history.push('/')}>
                        {t('general:no')}
                    </button>
                    <button disabled={this.props.submitting} onClick={() => this.props.cancelBooking(this.getHash())}>
                        {t('general:yes')}
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error_path: state.routes.paths.error,
        booking: state.cancel.booking,
        submitting: state.cancel.submitting,
        errorCode: state.booking.errorCode,
        settings: state.salon_location.group_settings,
    };
};

const TranslatedCancel = withTranslation(['cancel', 'general', 'booking'])(Cancel);

export default connect(mapStateToProps, {
    setTransGroup,
    startNewBooking,
    getCancelBooking,
    cancelBooking,
})(TranslatedCancel);