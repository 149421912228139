import React, {Fragment} from 'react';
import {connect} from './ReactPageComponent';
import ScheduleItem from './ScheduleItem';
import styleItem from '../scss/components/ScheduleItem.module.scss';
import moment from 'moment';
import Loading from './Loading';

class ScheduleList extends React.Component {

    renderScheduleTimes = () => {
        const time_gap = this.props?.selected_employee?.time_gap ?? 15;

        let gap;
        return this.props.times.map((item, index) => {
            gap = null;

            if (undefined !== this.props.times[index + 1]) {
                const diff = moment(this.props.times[index + 1], 'HH:mm').diff(moment(item, 'HH:mm'), 'minutes');

                if (/*index !== 0 && */parseInt((diff).toFixed(0)) > time_gap) {
                    gap = (
                        <div className={styleItem.Separator} key={'separator_' + index}/>
                    );
                }
            }

            return (
                <Fragment key={'fragment_' + index}>
                    <ScheduleItem time={item} key={index}
                                  selected={this.props.selected_time && item === this.props.selected_time}/>
                    {gap}
                </Fragment>
            );
        });
    };

    render() {
        if (this.props.schedule.overlay_loading)
            return <Loading/>;

        return this.renderScheduleTimes();
    }
}

const mapStateToProps = (state) => {
    return {
        schedule: state.schedule,
        settings: state.salon_location.group_settings,
        selected_time: state.order.selected_time,
        selected_employee: state.order.selected_employee,
    };
};

export default connect(mapStateToProps)(ScheduleList);
