import React from 'react';
import styleItem from '../scss/components/ScheduleWeekSelection.module.scss';
import classNames from 'classnames';
import Moment from 'react-moment';
import {withTranslation} from 'react-i18next';
import {connect} from './ReactPageComponent';
import {getEmployeeSchedule, onSelectDate} from '../actions/schedule';
import {addDateTimeToOrder} from '../actions/order';
import moment from 'moment';
import Swipe from 'react-easy-swipe';

class ScheduleWeekSelection extends React.Component {
    first_date = '';
    swipe_position = 0;
    previousWeekClickEnabled = false;

    state = {
        swipe_position: 0,
    };

    constructor(props) {
        super(props);

        this.setFirstDate();
    }

    setFirstDate() {
        let date = (this.props.order?.selected_employee?.first_available_date ?? (this.props?.dates?.[0] ?? null));
        if ((this.props.order.selected_employee || Array.isArray(this.props.dates)) && date && !this.first_date)
            this.first_date = date;

        if (Array.isArray(this.props.dates) && this.props.dates.length)
            date = this.props.dates[0];

        const daysDiff = moment(date).diff(moment(this.first_date), 'days');

        if (daysDiff > 0 && !this.previousWeekClickEnabled)
            this.previousWeekClickEnabled = true;
        else if (daysDiff <= 0 && this.previousWeekClickEnabled)
            this.previousWeekClickEnabled = false;
    }

    componentDidMount() {
        this.setFirstDate();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let first_date = '';

        const date = (this.props.order?.selected_employee?.first_available_date ?? (this.props?.dates?.[0] ?? null));
        if ((this.props.order.selected_employee || Array.isArray(this.props.dates)) && date)
            first_date = date;

        if (Array.isArray(this.props.dates) && this.props.dates.length)
            first_date = this.props.dates[0];

        if (!this.first_date && first_date)
            this.first_date = first_date;

        if (first_date) {
            const daysDiff = moment(first_date).diff(moment(this.first_date), 'days');

            if (daysDiff > 0 && !this.previousWeekClickEnabled)
                this.previousWeekClickEnabled = true;
            else if (daysDiff <= 0 && this.previousWeekClickEnabled)
                this.previousWeekClickEnabled = false;
        }
    }

    onDateClick = (e) => {
        e.preventDefault();

        const dateValue = e.currentTarget.getAttribute('data-value');
        this.props.onSelectDate(dateValue);
        if (this.props.order.selected_date !== dateValue)
            this.props.addDateTimeToOrder(dateValue, null);
    };

    onNextWeekClick = () => {
        const lastDate = this.props.dates[this.props.dates.length - 1];
        const weekStart = moment(lastDate).clone().add(1, 'days');
        const weekEnd = moment(lastDate).clone().add(1, 'weeks');

        this.props.getEmployeeSchedule(
            weekStart.format('YYYY-MM-DD'),
            weekEnd.format('YYYY-MM-DD'),
        );

        this.props.addDateTimeToOrder(null, null);
    };

    onPreviousWeekClick = () => {
        if (this.previousWeekClickEnabled === true) {
            const firstDate = this.props.dates[0];
            const weekStart = moment(firstDate).clone().subtract(1, 'weeks');
            const weekEnd = moment(firstDate).clone().subtract(1, 'days');

            this.props.getEmployeeSchedule(
                weekStart.format('YYYY-MM-DD'),
                weekEnd.format('YYYY-MM-DD'),
            );

            this.props.addDateTimeToOrder(null, null);
        }
    };

    renderDates = () => {
        const {i18n} = this.props;
        const availableTimes = this.props.schedule.items;

        const dateItemStyle = {
            transform: 'translate3d(' + this.state.swipe_position + ', 0, 0)',
        };

        return this.props.dates.map((item, index) => {
            if (availableTimes[item]) {
                let classes = styleItem.DateItem;
                if (item === this.props.schedule.selected_date) {
                    classes += ' ' + styleItem.Selected;
                }

                const active_date_month = moment(this.props.active_date).format('MM');
                const item_date_month = moment(item).format('MM');

                if (active_date_month !== item_date_month) {
                    classes += ' ' + styleItem.AnotherMonth;
                }

                return (
                    <div className={classes} key={index} onClick={this.onDateClick} data-value={item}
                         style={dateItemStyle}>
                        <div className={styleItem.Date}>
                            <Moment element="div" format="DD" locale={i18n.languages[0]}>{item}</Moment>
                        </div>
                        <div className={styleItem.Weekday}>
                            <Moment element="div" format="ddd" locale={i18n.languages[0]}>{item}</Moment>
                        </div>
                    </div>
                );
            } else {
                const notAvailableException = !this.props.schedule.overlay_loading || this.props.schedule.dates_loaded;

                return (
                    <div className={classNames(styleItem.DateItem, {
                        [styleItem.NotAvailable]: notAvailableException,
                        [styleItem.AnotherMonth]: !notAvailableException,
                    })} key={index} style={dateItemStyle}>
                        <div className={styleItem.Date}>
                            <Moment element="div" format="DD" locale={i18n.languages[0]}>{item}</Moment>
                        </div>
                        <div className={styleItem.Weekday}>
                            <Moment element="div" format="ddd" locale={i18n.languages[0]}>{item}</Moment>
                        </div>
                        {notAvailableException ?
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                                <path fill="none" fillRule="evenodd" strokeLinecap="round"
                                      strokeLinejoin="round" strokeWidth="2"
                                      d="M11 11l9.792-9.792L11 11 1.208 1.208 11 11zm0 0L1 21l10-10 10 10-10-10z"
                                      opacity=".266"/>
                            </svg> : null}
                    </div>
                );
            }
        });
    };

    onSwipeMove = (position) => {
        this.swipe_position = position.x;

        this.setState({
            swipe_position: position.x + 'px',
        });
    };

    onSwipeEnd = () => {
        if (this.swipe_position <= -40) {
            this.onNextWeekClick();
        } else if (this.swipe_position >= 40) {
            this.onPreviousWeekClick();
        }

        this.swipe_position = 0;

        this.setState({
            swipe_position: 0,
        });
    };

    render() {
        return (
            <div className={styleItem.ScheduleWeekSelection}>
                <Swipe className={styleItem.DatesSideOffset}
                       onSwipeMove={this.onSwipeMove}
                       onSwipeEnd={this.onSwipeEnd}>
                    <div className={styleItem.Content}>
                        <div className={styleItem.Dates}>
                            <div className={classNames(styleItem.Arrow, styleItem.Left)}
                                 onClick={this.onPreviousWeekClick}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5 8">
                                    <path fillRule="nonzero"
                                          d="M4.79.195a.75.75 0 0 0-1.01 0L.21 3.53a.636.636 0 0 0 0 .942l3.57 3.334a.751.751 0 0 0 1.002-.008.635.635 0 0 0 .009-.935L1.724 4l3.067-2.862a.636.636 0 0 0 0-.943z"/>
                                </svg>
                            </div>

                            {this.renderDates()}

                            <div className={classNames(styleItem.Arrow, styleItem.Right)}
                                 onClick={this.onNextWeekClick}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5 8">
                                    <path fillRule="nonzero"
                                          d="M.21 7.797a.75.75 0 0 0 1.01 0l3.57-3.334a.636.636 0 0 0 0-.942L1.22.187A.751.751 0 0 0 .217.195a.635.635 0 0 0-.009.935l3.067 2.862L.209 6.854a.636.636 0 0 0 0 .943z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </Swipe>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const schedule = state.schedule || {};
    const active_date = schedule.active_date || '';

    return {
        schedule,
        active_date,
        order: state.order,
        settings: state.salon_location.selected_location.settings,
    };
};

ScheduleWeekSelection = withTranslation(['schedule'])(ScheduleWeekSelection);

export default connect(mapStateToProps, {
    onSelectDate,
    getEmployeeSchedule,
    addDateTimeToOrder,
})(ScheduleWeekSelection);
