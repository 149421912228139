import {IDENTIFY_SALON, IDENTIFY_SALON_BY_DOMAIN, PARAMS_INITIALIZED, WIDGET_SET_PARAMS, WIDGET_TOGGLE} from './type';

export const toggleWidget = isOpen => {
    return {
        type: WIDGET_TOGGLE,
        payload: isOpen,
    }
};

export const identifySalon = identifier => {
    return {
        type: IDENTIFY_SALON,
        payload: identifier,
    }
};

export const identifySalonBySlug = identifier => {
    return {
        type: IDENTIFY_SALON_BY_DOMAIN,
        payload: identifier,
    }
};

export const setWidgetParams = params => {
    return {
        type: WIDGET_SET_PARAMS,
        payload: params,
    }
}

export const setParamsInitialized = bool => {
    return {
        type: PARAMS_INITIALIZED,
        payload: bool,
    }
}
