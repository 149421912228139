import React from 'react'
import styleItem from '../scss/components/EmployeeBlock.module.scss'
import EmployeeList from './EmployeeList'

class EmployeeBlock extends React.Component {

    render() {

        return (
            <div className={styleItem.EmployeeBlock}>
                <div className={styleItem.Header}>
                    <div className={styleItem.Title}>{this.props.title}</div>
                    <div className={styleItem.Count}>
                        <div className={styleItem.CountWrapper}>
                            <div className={styleItem.CountText}>{this.props.total}</div>
                        </div>
                    </div>
                </div>
                <EmployeeList items={this.props.items}/>
            </div>
        )
    }
}

EmployeeBlock.defaultProps = {
    total: 0,
    items: [],
}

export default EmployeeBlock
