import React, {Component, Fragment} from 'react'
import style from '../scss/components/EmployeeCode.module.scss'
import {connect} from 'react-redux'
import {reduxForm} from 'redux-form'
import {withTranslation} from 'react-i18next'
import classNames from 'classnames'
import {clearNextTry as clearSalonNextTry, postEmployeeCode, setEmployee} from '../actions/restrictionCode'
import InsertCodeContent from './InsertCodeContent'
import {matchPath, withRouter} from 'react-router-dom'
import {ReactComponent as CloseIcon} from '../assets/svg/close-icon.svg'

class EmployeeCode extends Component {
    autoUpdateInterval = undefined
    componentUnmounted = false
    state = {
        current_time: (new Date()).getTime(),
        wrong_code: false,
    }

    onSubmit = formValues => {
        return this.props.postEmployeeCode(this.props.employee_restriction.item.id, formValues).then(() => {
            if (!this.componentUnmounted) {
                if (this.state.wrong_code)
                    this.setState({
                        wrong_code: false,
                    })

                this.props.reset()
            }
        }).catch(error => {
            if (!this.componentUnmounted) {
                if (error.response.status === 401 && !this.state.wrong_code)
                    this.setState({
                        wrong_code: true,
                    })
                else if (error.response.status === 403 && this.state.wrong_code)
                    this.setState({
                        wrong_code: false,
                    })

                this.props.reset()
                if (this.wrapperRef)
                    this.wrapperRef.focus()
            }
        })
    }

    onChange = value => {
        setTimeout(() => {
            if (String(value).replaceAll(' ', '').length === this.props.length)
                this.props.submit()
        })
    }

    autoUpdateTime() {
        if (this.autoUpdateInterval)
            this.autoUpdateInterval = clearInterval(this.autoUpdateInterval)

        this.autoUpdateInterval = setInterval(() => {
            if (!this.componentUnmounted)
                this.setState({
                    current_time: (new Date()).getTime(),
                })
        }, 1000)
    }

    routeIs = (path = '/') => {
        if (!this.props.app_routes[path])
            return false

        return matchPath(this.props.location.pathname, {
            path: this.props.app_routes[path].path,
            exact: true,
        })
    }

    closeDialog = () => {
        this.props.setEmployee({})
    }

    resetEmployeeCodeDialogIfNecessary() {
        /*if (!this.routeIs('/employees') && this.props.employee_restriction.item?.id)
            this.closeDialog()*/
    }

    componentDidMount() {
        this.resetEmployeeCodeDialogIfNecessary()

        if (!this.props.employee_restriction.code && this.props.code_enabled && this.routeIs('/employees'))
            this.autoUpdateTime()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.employee_restriction.code && this.props.code_enabled && this.routeIs('/employees') && !this.autoUpdateInterval)
            this.autoUpdateTime()

        if (this.props.employee_restriction.next_try && this.props.employee_restriction.next_try <= this.state.current_time)
            this.props.clearSalonNextTry()

        this.resetEmployeeCodeDialogIfNecessary()
    }

    componentWillUnmount() {
        this.componentUnmounted = true
        if (this.autoUpdateInterval)
            this.autoUpdateInterval = clearInterval(this.autoUpdateInterval)
    }

    render() {
        if (!this.props.code_enabled)
            return null

        return (
            <Fragment>
                <div className={classNames(style.Background, {
                    [style.Show]: this.props.code_enabled && !this.props.employee_restriction.code && this.routeIs('/employees'),
                })} onClick={this.closeDialog}/>
                <div className={classNames(style.Wrapper, {
                    [style.Show]: this.props.code_enabled && !this.props.employee_restriction.code && this.routeIs('/employees'),
                })} ref={ref => this.wrapperRef = ref} tabIndex={-1}>
                    <div className={style.Close} onClick={this.closeDialog}>
                        <CloseIcon/>
                    </div>
                    <InsertCodeContent onChange={this.onChange}
                                       handleSubmit={this.props.handleSubmit}
                                       onSubmit={this.onSubmit}
                                       code_enabled={this.props.code_enabled && !!this.routeIs('/employees')}
                                       code={this.props.employee_restriction.code}
                                       wrong_code={this.state.wrong_code}
                                       length={this.props.length}
                                       next_try={this.props.employee_restriction.next_try}
                                       current_time={this.state.current_time}/>
                </div>
            </Fragment>
        )
    }
}

const form = 'employeePasswordForm'

EmployeeCode = reduxForm({
    form: form,
    enableReinitialize: true,
})(EmployeeCode)

const mapStateToProps = state => {
    const initialValues = {
        code: '',
    }

    return {
        initialValues,
        app_routes: state.routes.app_routes,
        employee_restriction: state.restriction_code.employee,
        code_enabled: state.restriction_code.employee?.item?.code_enabled ?? false,
        length: state.restriction_code.employee?.item?.code_length ?? 0,
    }
}

EmployeeCode = connect(mapStateToProps, {
    postEmployeeCode,
    clearSalonNextTry,
    setEmployee,
})(EmployeeCode)

EmployeeCode = withTranslation(['general', 'location'])(EmployeeCode)

EmployeeCode = withRouter(EmployeeCode)

export default EmployeeCode
