import './polyfill'
import './isWeb'
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import {Provider} from 'react-redux'
import getStore from './persistStore'
import {syncHistoryWithStore} from 'react-router-redux'
import {createBrowserHistory} from 'history'
import networkService from './api/network-service'
import {subdomain} from './env'
import {salon_group_id} from './localEnv'
import {identifySalon, identifySalonBySlug} from './actions/widget'
import WebWrapper from './components/WebWrapper'
import Web from './components/Web'
import {PersistGate} from 'redux-persist/integration/react'
import dateSync from './helpers/dateSync'

dateSync()

const stores = getStore()
const store = stores.store
const persistor = stores.persistor

const browserHistory = createBrowserHistory()
const history = syncHistoryWithStore(browserHistory, store)
networkService.setupInterceptors(store, history)

if (subdomain)
    store.dispatch(identifySalonBySlug(subdomain))
else
    store.dispatch(identifySalon(salon_group_id))

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <React.Suspense fallback={null}>
                <WebWrapper>
                    <Web routerHistory={history}/>
                </WebWrapper>
            </React.Suspense>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
