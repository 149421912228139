import React from 'react';
import registerListener from '../helpers/registerListener';
import reduxConnect from 'react-redux/es/connect/connect';
import {saveScrollPos} from '../actions/userInterface';

class ReactPageComponent extends React.Component {
    scrollPosKey = this.__proto__.constructor.name;
    scrollHeight = 0;
    timeout = undefined;

    componentDidMount() {
        this.timeout = setTimeout(() => {
            if (this.props.appContentRef?.current) {
                this.scrollHeight = this.props.appContentRef?.current?.scrollHeight;
                this.props.appContentRef.current.scrollTop = this.props.scroll_positions[this.scrollPosKey] || 0;

                registerListener('scroll', this.onScroll, this.props.appContentRef.current);
            }
        }, 0);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.widget.open !== prevProps.widget.open) {
            this.scrollHeight = this.props.appContentRef?.current?.scrollHeight;
        }


        if (this.props.appContentRef?.current && this.scrollHeight !== this.props.appContentRef?.current?.scrollHeight) {
            this.scrollHeight = this.props.appContentRef.current.scrollHeight;

            this.props.appContentRef.current.scrollTop = this.props.scroll_positions[this.scrollPosKey] || 0;
        }
    }

    componentWillUnmount() {
        if (this.timeout)
            clearTimeout(this.timeout);

        if (this.props.appContentRef?.current)
            this.props.appContentRef.current.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
        if (this.props.appContentRef?.current) {
            let scrollTop = this.props.appContentRef.current.scrollTop;
            if (scrollTop < 0)
                scrollTop = 0;

            this.props.saveScrollPos(this.scrollPosKey, scrollTop);
        }
    };
}

export function connect(mapStateToProps, mapDispatchToProps = {}, mergeProps, _ref2) {
    const mapStateToPropsWithRequired = (state) => {
        const mappedToProps = mapStateToProps(state);

        return {
            widget: state.widget,
            ...mappedToProps,
            scroll_positions: state.ui.scroll_positions,
        }
    };

    const mapDispatchToPropsWithRequired = {
        ...mapDispatchToProps,
        saveScrollPos,
    };

    return reduxConnect(mapStateToPropsWithRequired, mapDispatchToPropsWithRequired, mergeProps, _ref2);
}

export default ReactPageComponent;
