import i18n from 'i18next'
import BackendMultiload from 'i18next-multiload-backend-adapter'
//import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next'

i18n
    .use(BackendMultiload)
    //.use(LanguageDetector)
    .use(initReactI18next)
// for all options read: https://www.i18next.com/overview/configuration-options;

export default i18n
