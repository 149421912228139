import {SIZE_CHANGED} from './type';

export const sizeChanged = () => {
    return {
        type: SIZE_CHANGED,
        payload: {
            width: window.innerWidth,
            height: window.innerHeight,
        },
    }
};
