const getNameInitials = name => {
    //let initials = name.match(/\b\w/g) || [];

    //initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

    //return initials;
    return String(name).split(' ').map((n) => n[0]).join('').toUpperCase();
};

export default getNameInitials
