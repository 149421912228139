import {LOADING} from '../actions/type'

const INITIAL_STATE = {
    getLocations: true,
}

const loadingReducer = (state = INITIAL_STATE, action) => {
    if (action.type === LOADING)
        return {
            ...state,
            [action.payload.key]: action.payload.value,
        }

    return state
}

export default loadingReducer
