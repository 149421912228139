import {LANGUAGE_SWITCH_TOGGLE, SET_LANGUAGES, SET_WIDGET_LANGUAGE} from '../actions/type'

const INITIAL_STATE = {
    language_switch_open: false,
    languages_loaded: false,
    primary_language: 'en',
    widget_language: '',
    items: {
        en: {
            code: 'en',
            flag: 'gb',
            name: 'in English',
        },
    },
}

const getFlag = language_code => {
    switch (language_code) {
        case 'en':
            return 'gb'
        case 'et':
            return 'ee'
        case 'sv':
            return 'se'
        default:
            return language_code
    }
}

const formatLanguages = languages => {
    const items = {}

    Object.keys(languages).forEach(key => {
        const language = languages[key]
        const flag = getFlag(language.code)

        items[key] = {
            ...language,
            flag,
        }
    })

    return items
}

const languageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LANGUAGES:
            return {
                ...state,
                languages_loaded: true,
                primary_language: action.payload.primary_language,
                items: formatLanguages(action.payload.languages),
            }
        case SET_WIDGET_LANGUAGE:
            return {
                ...state,
                widget_language: action.payload,
            }
        case LANGUAGE_SWITCH_TOGGLE:
            return {
                ...state,
                language_switch_open: action.payload,
            }
        default:
            return state
    }
}

export default languageReducer
