import React from 'react'
import styleItem from '../scss/components/EmployeeItem.module.scss'
import connect from 'react-redux/es/connect/connect'
import {withTranslation} from 'react-i18next'
import {addEmployeeToOrder} from '../actions/order'
import {openDialog} from '../actions/userInterface'
import getNameInitials from '../getNameInitials'
import classNames from 'classnames'
import {ReactComponent as LockIcon} from '../assets/svg/lock-filled.svg'
import {setEmployee as setEmployeeCode} from '../actions/restrictionCode'
import moment from 'moment'

class EmployeeItem extends React.Component {
    unmounted = false
    loadingImage = false

    constructor(props) {
        super(props)

        this.state = {
            selected: this.props.selected ?? false,
            image: '',
        }
    }

    toggleSelected = e => {
        const shouldPrevent = e.target.getAttribute('data-prevent_select') === 'true'
        if (shouldPrevent) {
            return
        }

        if (this.props.code_enabled && (!this.props.employee_restriction.code || (this.props.employee_restriction.code && this.props.employee_restriction.id !== this.props.item.id))) {
            this.props.setEmployeeCode(this.props.item)
        } else {
            if (this.props.selected_employee !== this.props.item) {
                this.props.addEmployeeToOrder(this.props.item)
            } else {
                if (this.props.all_employees.length > 1) {
                    this.props.addEmployeeToOrder(null)
                }
            }
        }
    }

    componentDidMount() {
        this.setCheckedFromPropToState()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setCheckedFromPropToState()
    }

    setCheckedFromPropToState() {
        if (this.state.selected !== this.props.selected && !this.unmounted)
            this.setState({
                selected: this.props.selected,
            })
    }

    componentWillUnmount() {
        this.unmounted = true
        this.loadingImage = false
    }

    openDialog = (employee) => {
        this.props.openDialog({
            employee,
            code_enabled: this.props.code_enabled,
        })
    }

    onImgLoad = (image) => {
        this.setState({
            image,
        });
    }

    renderEmployeeImage = employee => {
        return (
            <div className={styleItem.ImagePlaceholder}>
                <span>{getNameInitials(employee.name)}</span>
                <img src={employee.image} className={classNames({
                    [styleItem.Show]: this.state.image,
                })} onLoad={() => this.onImgLoad(employee.image)} alt=""/>
            </div>
        )
    }

    renderDescription() {
        const {t, i18n} = this.props
        const curLang = i18n.languages[0]
        const descriptions = this.props.item.descriptions
        if (!descriptions)
            return null

        let description = String(descriptions[curLang] ?? '')
        if (!description)
            return null

        description = description.replace(/(<([^>]+)>)/ig, ' ')
        if (description.length >= 43) {
            description = (
                <React.Fragment>
                    {description.substring(0, 40)}...
                    <div className={styleItem.ReadMore}
                         data-prevent_select={true}
                         onClick={() => this.openDialog(this.props.item)}>{t('read_more')}</div>
                </React.Fragment>
            )
        }

        return (
            <div className={styleItem.Description}>{description}</div>
        )
    }

    render() {
        const {t} = this.props

        return (
            <div className={classNames(styleItem.EmployeeItem, {
                [styleItem.EmployeeItemSelected]: this.state.selected,
            })} onClick={this.toggleSelected}>
                <div className={styleItem.Image}>
                    {this.renderEmployeeImage(this.props.item)}
                </div>
                <div className={styleItem.Content}>
                    <div className={styleItem.Name}>
                        {this.props.code_enabled && <LockIcon className={styleItem.Lock}/>}
                        {this.props.item.name}
                    </div>
                    {(this.props.item?.first_available_date && this.props.item?.first_available_time && this.props.all_employees.length > 1 ? (
                        <div className={styleItem.FirstAvailableTime}>
                            <div className={styleItem.Label}>
                                {t('employees:first_available_time')}
                            </div>
                            <div className={styleItem.WrapCol}>
                                <div className={styleItem.Value}>
                                    {t('employees:date_at_time', {
                                        date: moment(this.props.item?.first_available_date).format('DD.MM'),
                                        time: this.props.item?.first_available_time,
                                    })}
                                </div>
                                <div className={classNames(styleItem.Value, styleItem.Opacity)}>
                                    {t('employees:more_times')}
                                </div>
                            </div>
                        </div>
                    ) : null)}
                    <div className={styleItem.Description}>
                        {this.renderDescription()}
                    </div>
                    {this.props.group_settings['my_salon_group.staff.show.total_price'] &&
                    <div className={styleItem.Price}>
                        {this.props.item.price_for_services}{this.props.settings['salon.currency']}
                    </div>
                    }
                    <div className={styleItem.Arrow}/>
                </div>
            </div>
        )
    }
}

EmployeeItem.defaultProps = {
    selected: false,
}

const mapStateToProps = (state) => {
    return {
        settings: state.salon_location.selected_location.settings,
        group_settings: state.salon_location.group_settings,
        selected_employee: state.order.selected_employee,
        employee_restriction: state.restriction_code.employee,
        all_employees: (state.employees.all_employees ?? []),
    }
}

export default connect(mapStateToProps, {
    addEmployeeToOrder,
    openDialog,
    setEmployeeCode,
})(
    withTranslation()(EmployeeItem),
)
