import {GET_EMPLOYEES} from './type';
import salonlife from '../api/salonlife';
import getApiPath from '../helpers/getApiPath';
import {cancelTheRequest, registerTheRequest, registerTimeout} from '../helpers/axiosCancel';

export const getEmployees = () => {
    const uniqueKey = 'getEmployees';
    cancelTheRequest(uniqueKey);

    return async (dispatch, getState) => {
        const state = getState();

        const params = {
            service_ids: state.order.ids,
        };

        return await salonlife.get(getApiPath(state.routes, state.widget, state.salon_location) + '/employees', {
            params,
            cancelToken: registerTheRequest(uniqueKey),
        }).then(response => {
            dispatch({
                type: GET_EMPLOYEES,
                payload: response.data,
            });

            return response;
        }).catch(error => {
            if (error && error.response && error?.response?.status !== 404)
                registerTimeout(uniqueKey, () => {
                    getEmployees()(dispatch, getState);
                }, 60000);

            throw error;
        });
    };
};
