import {
    GET_CANCEL_BOOKING,
    ON_CANCELLING_BOOKING,
    ON_SET_ERROR_CODE,
    ON_CANCEL_BOOKING_SUCCESS
} from './type';
import salonlife from '../api/salonlife';
import getApiPath from '../helpers/getApiPath';

export const getCancelBooking = (hash) => {
    return async (dispatch, getState) => {
        const state = getState();

        return await salonlife.get(getApiPath(state.routes, state.widget) + '/cancel/' + hash).then(response => {
            dispatch({
                type: GET_CANCEL_BOOKING,
                payload: response.data
            });

            return response;
        }).catch(error => {
            dispatch({
                type: GET_CANCEL_BOOKING,
                payload: null
            });
        });
    };
};

export const cancelBooking = (hash) => {
    return async (dispatch, getState) => {
        const state = getState();

        return await salonlife.post(getApiPath(state.routes, state.widget) + '/cancel/' + hash).then(response => {
            dispatch({
                type: ON_CANCELLING_BOOKING,
                payload: true
            });

            dispatch({
                type: ON_CANCEL_BOOKING_SUCCESS,
                payload: response.data
            });

            return response;
        }).catch(error => {

            dispatch({
                type: ON_SET_ERROR_CODE,
                payload: 100,
            });
        });
    };
};