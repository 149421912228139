import React from 'react'
import positionStickySupport from '../helpers/positionStickySupport'
import Sticky from 'react-sticky-el'
import {isWeb, widgetScrollContentId} from '../env'
import styleFooter from '../scss/components/Footer.module.scss'
import Progress from './Progress'
import {withTranslation} from 'react-i18next'
import connect from 'react-redux/es/connect/connect'
import MoveForwardButton from './MoveForwardButton'
import classNames from 'classnames'

class Footer extends React.Component {
    scrollContentIdHash = '#' + widgetScrollContentId

    renderFooter = (addClasses = '') => {
        const {t} = this.props

        const classes = classNames(styleFooter.Footer, addClasses, {
            [styleFooter.Simple]: this.props.simple,
        })

        const target = '_blank'
        let rel = ''

        if (isWeb)
            rel = 'nofollow'

        return (
            <div className={classes}>
                {this.props.simple === false ? <MoveForwardButton form={this.props.form}/> : null}
                <a href={'https://salon.life/?utm_source=' + encodeURIComponent(this.props.salon_name) + '&utm_medium=powered_by&utm_campaign=online_booking'}
                   target={target} rel={'noopener noreferrer ' + rel}
                   className={styleFooter.copyright}>
                    {t('general:powered_by')}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 7">
                        <path fillRule="nonzero"
                              d="M2.793 2.793l-2.5 2.5a1 1 0 1 0 1.414 1.414l2.5-2.5L7 7V0H0l2.793 2.793z"/>
                    </svg>
                </a>
                {this.props.simple === false ? <Progress/> : null}
            </div>
        )
    }

    render() {
        if (positionStickySupport())
            return this.renderFooter('sticky')

        return (
            <Sticky
                mode={'bottom'}
                positionRecheckInterval={150}
                scrollElement={this.scrollContentIdHash}>
                {this.renderFooter()}
            </Sticky>
        )
    }
}

Footer.defaultProps = {
    styleClass: null,
    simple: false,
}


const mapStateToProps = state => {
    return {
        salon_name: (state.salon_location?.selected_location ?? {})?.name ?? 'Salon Group - ' + state.salon_location.name,
    }
}

const TranslatedFooter = withTranslation(['general'])(Footer)

export default connect(mapStateToProps, {})(TranslatedFooter)
