const deleteKeyFromObject = (object, key) => {
    try {
        delete object[key]
    } catch (e) {
        object[key] = undefined

        object = JSON.parse(JSON.stringify(object))
    }

    return object
}

export default deleteKeyFromObject
