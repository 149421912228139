import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {matchPath, withRouter} from 'react-router-dom';

class MoveBackButton extends React.Component {
    render() {
        if (this.routeIs() || this.props.submittingBooking ||
            (this.routeIs('/categories') && this.props.salon_location.items.length === 1) ||
            (this.routeIs('/services') && this.props.salon_location.items.length === 1 && !this.props.settings['my_salon_group.services.show.categories']))
            return null;

        let backPath = this.props.paths.home;
        if (this.routeIs('/categories'))
            backPath = this.props.paths.home;
        else if (this.routeIs('/services')) {
            backPath = this.props.paths.categories;
            if (!this.props.salon_location.group_settings['my_salon_group.services.show.categories']) {
                backPath = this.props.paths.home;
            }
        } else if (this.routeIs('/employees'))
            backPath = this.props.paths.services;
        else if (this.routeIs('/schedule'))
            backPath = this.props.paths.employees;
        else if (this.routeIs('/customer'))
            backPath = this.props.paths.schedule;
        else if (this.routeIs('/terms') || this.routeIs('/review'))
            backPath = this.props.paths.customer;

        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                 onClick={() => this.props.history.push(backPath)}>
                <path fillRule="nonzero"
                      d="M8.705 15.295a.998.998 0 0 0 0-1.41L3.83 9H15a1 1 0 0 0 0-2H3.83l4.88-4.87a1.003 1.003 0 0 0 0-1.42 1.004 1.004 0 0 0-1.42 0L.707 7.293a1 1 0 0 0 0 1.414l6.588 6.588c.39.39 1.02.39 1.41 0z"/>
            </svg>
        );
    }

    routeIs = (path = '/') => {
        return matchPath(this.props.location.pathname, {
            path: this.props.app_routes[path].path,
            exact: true,
        });
    };
}

MoveBackButton.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        salon_location: state.salon_location,
        paths: state.routes.paths,
        app_routes: state.routes.app_routes,
        submittingBooking: state.booking.submitting,
        settings: state.salon_location.group_settings,
    };
};

const routerWrap = withRouter(MoveBackButton);

export default connect(mapStateToProps, {})(routerWrap);