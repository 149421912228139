import {LOCATIONS_LOCK, ON_MAINTENANCE_MODE} from '../actions/type'

const INITIAL_STATE = {
    maintenance: false,
    request_locked: false,
}

const locationLockReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOCATIONS_LOCK:
            return {
                ...state,
                request_locked: action.payload,
            }
        case ON_MAINTENANCE_MODE:
            return {
                ...state,
                maintenance: action.payload,
            }
        default:
            return state
    }
}

export default locationLockReducer
