import salonlife from '../api/salonlife'

const dateSync = () => {
    window.serverDateOffset = 0

    const timeChanged = () => {
        salonlife.get('/')
    }

    const timeChecker = () => {
        const oldTime = timeChecker.oldTime || new Date(),
            newTime = new Date(),
            timeDiff = newTime - oldTime

        timeChecker.oldTime = newTime

        if (Math.abs(timeDiff) >= 5000) { // Five second latency
            timeChanged()
        }
    }

    setInterval(timeChecker, 1000)
}

export default dateSync
