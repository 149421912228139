import React from 'react';
import ReactPageComponent, {connect} from '../components/ReactPageComponent';
import {withTranslation} from 'react-i18next';
import {setActiveProgress, setTransGroup} from '../actions/userInterface';
import CustomerForm from '../components/CustomerForm';
import {Redirect} from 'react-router-dom';
import {withCookies} from 'react-cookie';
import FacebookPixel from '../helpers/facebookPixel';

class Customer extends ReactPageComponent {
    scrollPosKey = 'Customer';

    state = {
        InitiateCheckoutEventSent: false,
    };

    componentDidMount() {
        super.componentDidMount();

        this.props.setTransGroup('customer');
        this.props.setActiveProgress(5);

        this.sendInitiateCheckoutEvent();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);

        this.sendInitiateCheckoutEvent();
    }

    sendInitiateCheckoutEvent() {
        if (!this.props.booking && this.props.order.ids.length && !this.state.InitiateCheckoutEventSent) {
            const facebook_id = this.props.salon?.settings?.['salon.facebook_pixel_id'] ?? null;
            if (facebook_id)
                FacebookPixel.trackSingleCustom(facebook_id, 'InitiateCheckout');

            this.setState({
                InitiateCheckoutEventSent: true,
            });
        }
    }

    render() {
        if (this.props.booking)
            return <Redirect to={this.props.paths.booking}/>;
        else if (!this.props.order.ids.length)
            return <Redirect to={this.props.paths.services}/>;
        else if (this.props.errorCode)
            return <Redirect to={this.props.error_path}/>;

        const {cookies} = this.props;
        const customer_key = 'customer_data_' + this.props.salon.id;
        const customerData = cookies.get(customer_key) || null;

        return <CustomerForm fields={this.props.fields}
                             requiredFields={this.props.requiredFields}
                             initialValues={customerData}/>;
    }
}

Customer.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        paths: state.routes.paths,
        booking: state.booking.booking,
        order: state.order,
        errorCode: state.booking.errorCode,
        error_path: state.routes.paths.error,
        fields: state.salon_location.group_settings['my_salon_group.customer.fields'],
        requiredFields: state.salon_location.group_settings['my_salon_group.customer.required_fields'],
        salon: state.salon_location.selected_location,
    };
};

const TranslatedCustomer = withTranslation(['customer'])(Customer);

export default withCookies(connect(mapStateToProps, {
    setTransGroup,
    setActiveProgress,
})(TranslatedCustomer));
