import React, {Component, Fragment} from 'react'
import classNames from 'classnames'
import shoppingCartStyle from '../scss/components/ShoppingCart.module.scss'
import {withTranslation} from 'react-i18next'
import connect from 'react-redux/es/connect/connect'
import {matchPath, withRouter} from 'react-router-dom'
import {preventContainerScroll, releaseContainerScroll} from '../actions/userInterface'
import {clearOrder, removeServicesFromOrder} from '../actions/order'
import {getEmployeeSchedule} from '../actions/schedule'

const DEFAULT_HEIGHT = 89

class ShoppingCart extends Component {
    contentsRef = React.createRef()
    ref = undefined

    state = {
        open: false,
        height: DEFAULT_HEIGHT,
    }

    componentDidMount() {
        if (this.ref)
            this.ref.style.setProperty('height', this.state.height + 'px', 'important')
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside)
        this.props.releaseContainerScroll()
    }

    handleEventListeners = () => {
        if (this.state.open)
            document.addEventListener('click', this.handleClickOutside)
        else
            document.removeEventListener('click', this.handleClickOutside)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.open && !this.state.open) {
            this.props.releaseContainerScroll()
            this.handleEventListeners()
        } else if (this.state.open && !prevState.open) {
            this.props.preventContainerScroll()
            this.handleEventListeners()
        }

        if (this.props.schedule.active_schedule_view === true && this.props.order.ids.length && prevProps.order.ids.length > this.props.order.ids.length)
            this.props.getEmployeeSchedule(this.props.schedule.week_start, this.props.schedule.week_end)

        if (this.props.order.ids.length !== 0 && prevProps.order.ids.length !== this.props.order.ids.length && this.state.open)
            this.setState({
                height: DEFAULT_HEIGHT + this.contentsRef.current.clientHeight,
            })

        if (prevProps.order.ids.length !== 0 && this.props.order.ids.length === 0 && this.state.open)
            this.setState({
                open: false,
                height: DEFAULT_HEIGHT,
            })

        if (prevState.height !== this.state.height && this.ref)
            this.ref.style.setProperty('height', this.state.height + 'px', 'important')
    }

    toggleShoppingCart = (willOpen = undefined) => {
        willOpen = willOpen ?? !this.state.open
        let height = DEFAULT_HEIGHT

        if (willOpen) {
            height = DEFAULT_HEIGHT + this.contentsRef.current.clientHeight
        }

        this.setState({
            open: willOpen,
            height: height,
        })
    }

    handleClickOutside = e => {
        if (this.contentsRef.current && !this.contentsRef.current.contains(e.target) && (typeof e.target.className === 'string' && e.target.className.indexOf(shoppingCartStyle.Remove) === -1))
            this.toggleShoppingCart(false)
    }

    removeService = id => {
        this.props.removeServicesFromOrder(id)
    }

    renderServices() {
        const {t, i18n, settings} = this.props
        const curLang = i18n.languages[0]

        const shoppingItems = []

        const ids_by_category = this.props.order.ids_by_category
        const categories = Object.keys(ids_by_category)

        let sum_total = 0

        if (categories.length) {
            const currency = this.props?.selected_location?.settings?.['salon.currency'] ?? '€'

            categories.forEach(category_id => {
                const category_names = this.props.categories.names[category_id] || {}

                shoppingItems.push(
                    <div className={shoppingCartStyle.Category} key={'cat_' + category_id}>
                        <span>{category_names[curLang] || category_names[Object.keys(category_names)[0]]}</span>
                    </div>,
                )

                const categoryItems = []

                ids_by_category[category_id].forEach(id => {
                    const item = this.props.order?.ordered_services[id] ?? null

                    if (item) {
                        let price = item.min_price
                        if (item.discount_price) {
                            price = item.discount_price
                        }

                        if (this.props.order.selected_employee) {
                            const services_info = this.props.order.selected_employee.services_info

                            if (services_info && services_info[id])
                                price = services_info[id].price
                        }

                        sum_total = Math.round((sum_total + price) * 100) / 100

                        price = Number(price).toFixed(2)

                        categoryItems.push(
                            <div className={shoppingCartStyle.Service} key={id}>
                                <div className={shoppingCartStyle.Remove} onClick={() => this.removeService(id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                                        <path fillRule="nonzero"
                                              d="M13.7.3c-.4-.4-1-.4-1.4 0L7 5.6 1.7.3C1.3-.1.7-.1.3.3c-.4.4-.4 1 0 1.4L5.6 7 .3 12.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3L7 8.4l5.3 5.3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4L8.4 7l5.3-5.3c.4-.4.4-1 0-1.4z"/>
                                    </svg>
                                </div>
                                <div className={shoppingCartStyle.Name}>
                                    {item.name[curLang] || item.name[Object.keys(item.name)[0]]}
                                </div>
                                {settings['my_salon_group.services.show.prices'] &&
                                <div className={shoppingCartStyle.Price}>
                                    {(price !== '0.00' ? price + currency : t('free'))}
                                </div>}
                            </div>,
                        )
                    }
                })

                shoppingItems.push(
                    <div className={shoppingCartStyle.Services} key={'services_' + category_id}>
                        {categoryItems}
                    </div>,
                )
            })

            sum_total = Number(sum_total).toFixed(2)
            shoppingItems.push(
                <div className={shoppingCartStyle.Footer} key={'cart_total'}>
                    <div className={shoppingCartStyle.Clear} onClick={this.props.clearOrder}>
                        {t('clear_all')}
                    </div>
                    {settings['my_salon_group.services.show.prices'] && <div className={shoppingCartStyle.Total}>
                        {this.props.order.selected_employee ? t('sum') : t('price_from')}
                        <strong>{sum_total + currency}</strong>
                    </div>}
                </div>,
            )
        }

        if (shoppingItems.length)
            return shoppingItems

        return null
    }

    render() {
        let onClick = null
        const {t} = this.props
        const selectedServices = this.props.order.ids.length

        if (selectedServices)
            onClick = this.toggleShoppingCart

        return (
            <Fragment>
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    background: 'transparent',
                    zIndex: 2,
                    display: this.state.open ? 'block' : 'none',
                }}/>
                <div className={classNames(shoppingCartStyle.ShoppingCart, {
                    [shoppingCartStyle.Show]: selectedServices || this.routeIs('/services'),
                    [shoppingCartStyle.Toggle]: selectedServices,
                    [shoppingCartStyle.Opened]: this.state.open,
                })} ref={ref => this.ref = ref}>
                    <div className={shoppingCartStyle.Row} onClick={onClick}>
                        <div className={shoppingCartStyle.Icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 21">
                                <g fill="none" fillRule="evenodd" strokeLinejoin="round" strokeWidth="2">
                                    <path strokeLinecap="round" d="M4 8l5-7M18 8l-5-7"/>
                                    <path strokeLinecap="square" d="M16 20H5L1 8h20z"/>
                                </g>
                            </svg>
                        </div>
                        <div className={shoppingCartStyle.Title}>
                            {t('shopping_cart')}
                        </div>
                        <div className={shoppingCartStyle.Count}>
                            <div className={shoppingCartStyle.Total}>
                                <span>{selectedServices}</span>
                            </div>
                        </div>
                    </div>
                    <div className={shoppingCartStyle.Contents} ref={this.contentsRef}>
                        {this.renderServices()}
                    </div>
                </div>
            </Fragment>
        )
    }

    routeIs = (path = '/') => {
        return matchPath(this.props.location.pathname, {
            path: this.props.app_routes[path].path,
            exact: true,
        })
    }
}

ShoppingCart.defaultProps = {}

const mapStateToProps = (state) => {
    return {
        settings: state.salon_location.group_settings,
        app_routes: state.routes.app_routes,
        selected_location: state.salon_location.selected_location,
        order: state.order,
        categories: state.categories,
        schedule: state.schedule,
    }
}

const routerWrap = withRouter(ShoppingCart)

const TranslatedShoppingCart = withTranslation(['general'])(routerWrap)

export default connect(mapStateToProps, {
    releaseContainerScroll,
    preventContainerScroll,
    removeServicesFromOrder,
    clearOrder,
    getEmployeeSchedule,
})(TranslatedShoppingCart)
