import React from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import ReactPageComponent, {connect} from '../components/ReactPageComponent';
import {withTranslation} from 'react-i18next';
import {setActiveProgress, setTransGroup} from '../actions/userInterface';
import style from '../scss/components/Review.module.scss';
import {removeServicesFromOrder} from '../actions/order';
import {confirmBooking} from '../actions/booking';
import getNameInitals from '../getNameInitials';
import Moment from 'react-moment';
import Loading from '../components/Loading';
import {ReactComponent as DeleteLogo} from '../assets/svg/delete.svg';

class Review extends ReactPageComponent {
    unmounted = false;
    state = {
        loading: true,
        employee_image: '',
    };

    scrollPosKey = 'Review';

    componentDidMount() {
        super.componentDidMount();

        this.props.setTransGroup('review');
        this.props.setActiveProgress(6);
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this.unmounted = true;
    }

    removeService = (id) => {
        this.props.removeServicesFromOrder(id);
    };

    confirmBooking = () => {
        this.props.confirmBooking();
    };

    renderEmployeeImage = (employee) => {
        if (employee.image) {
            const img = new Image();

            img.src = employee.image;
            img.onload = () => {
                this.setState({
                    employee_image: employee.image,
                });
            };
        }

        if (!employee.image || !this.state.employee_image)
            return (
                <div className={style.NoImage}>
                    <span>{getNameInitals(employee.name)}</span>
                </div>
            );

        return (
            <img src={this.state.employee_image} alt=""/>
        );
    };

    renderServices() {

        const {t, i18n} = this.props;
        const curLang = i18n.languages[0];

        const shoppingItems = [];

        const ids_by_category = this.props.order.ids_by_category;
        const categories = Object.keys(ids_by_category);

        let sum_total = 0;

        if (categories.length) {
            const currency = this.props.salon.settings['salon.currency'];

            categories.forEach(category_id => {
                const category_names = this.props.categories.names[category_id] || {};

                const category_heading = (
                    <div className={style.Category} key={'cat_' + category_id}>
                        <span>{category_names[curLang] || category_names[Object.keys(category_names)[0]]}</span>
                    </div>
                );

                const categoryItems = [];
                let items_total = 0;

                ids_by_category[category_id].forEach(id => {
                    const item = this.props.order.services_by_id[id] || null;
                    if (item) {
                        ++items_total;
                        let price = item.final_price;

                        if (this.props.order.selected_employee) {
                            const services_info = this.props.order.selected_employee.services_info;

                            if (services_info && services_info[id])
                                price = services_info[id].price;
                        }

                        sum_total = Math.round((sum_total + price) * 100) / 100;

                        price = Number(price).toFixed(2);

                        categoryItems.push(
                            <div className={style.Service} key={id}>
                                <div className={style.Remove} onClick={() => this.removeService(id)}>
                                    <DeleteLogo/>
                                </div>
                                <div className={style.Name}>
                                    {item?.name?.[curLang] ?? item.name[Object.keys(item.name)[0]]}
                                </div>
                                {this.props.group_settings['my_salon_group.booking.show.prices'] &&
                                <div className={style.Price}>
                                    {(price !== '0.00' ? price + currency : t('free'))}
                                </div>
                                }
                            </div>,
                        )
                    }
                });

                if (items_total)
                    shoppingItems.push(
                        category_heading,
                        <div className={style.Services} key={'services_' + category_id}>
                            {categoryItems}
                        </div>,
                    );
            });

            sum_total = Number(sum_total).toFixed(2);

            if (this.props.group_settings['my_salon_group.booking.show.total_price']) {
                shoppingItems.push(
                    <div className={style.Summary} key={'summary_total'}>
                        <div className={style.TotalCost}>
                            {t('total_cost')}:
                        </div>
                        <div className={style.Total}>
                            {sum_total + currency}
                        </div>
                    </div>,
                );
            }
        }

        if (shoppingItems.length)
            return shoppingItems;

        return null;
    }

    renderTime = () => {
        const {i18n} = this.props;

        if (this.props.settings['salon.time_format'] === 12) {
            return (
                <div>
                    <Moment element="span" format="h:mm"
                            locale={i18n.languages[0]}>{this.props.order.selected_date + ' ' + this.props.order.selected_time}</Moment>
                    <Moment className={style.TimeType} element="span" format="A"
                            locale={i18n.languages[0]}>{this.props.order.selected_date + ' ' + this.props.order.selected_time}</Moment>
                </div>

            );
        }

        return this.props.order.selected_time;
    };

    render() {

        const {t, i18n, history} = this.props;
        const showReview = this.props.group_settings['my_salon_group.booking.ask_confirmation'];
        if (!showReview) {
            return <Redirect to={this.props.paths.customer}/>;
        }

        if (!Object.keys(this.props.customer_data).length || this.props.errorFields)
            return <Redirect to={this.props.paths.customer}/>;
        else if (!this.props.order.selected_employee)
            return <Redirect to={this.props.paths.employees}/>
        else if (this.props.bookingErrorCode)
            return <Redirect to={this.props.paths.error}/>;
        else if (this.props.booking)
            return <Redirect to={this.props.paths.booking}/>;
        else if (!this.props.order.ids.length)
            return <Redirect to={this.props.paths.services}/>;

        if (this.props.bookingSubmitting) {
            return <Loading full_screen={true}/>;
        }

        const curLang = i18n.languages[0];

        return (
            <div className={style.Review}>
                {this.props.group_settings['my_salon_group.booking.show.location'] &&
                <div className={style.Block + ' ' + style.Location}>
                    <div className={style.Heading}>{t('location')}</div>
                    <div
                        className={style.SalonName}>{this.props.salon.location_names[curLang] ?? this.props.salon.name}</div>
                    {this.props.salon.addresses[curLang] &&
                    <div className={style.SalonAddress}>
                        {this.props.salon.addresses[curLang]}
                    </div>
                    }
                </div>
                }

                {this.props.group_settings['my_salon_group.booking.show.services'] &&
                <div className={style.Block}>
                    <div className={style.Heading}>{t('services')}</div>
                    <div className={style.ServiceList}>
                        {this.renderServices()}
                    </div>
                </div>
                }

                {this.props.group_settings['my_salon_group.booking.show.team'] &&
                <div className={style.Block}>
                    <div className={style.Heading}>{t('team')}</div>
                    <div className={style.Employee}>
                        <div className={style.Image}>
                            {this.renderEmployeeImage(this.props.order.selected_employee)}
                        </div>
                        <div className={style.Info}>
                            <span className={style.Name}>{this.props.order.selected_employee.name}</span>
                            <span className={style.Email}>{this.props.order.selected_employee.email}</span>
                        </div>
                    </div>
                </div>
                }

                {this.props.group_settings['my_salon_group.booking.show.date_time'] &&
                <React.Fragment>
                    <div className={style.DateTime}>
                        <div className={style.Block + ' ' + style.Date}>
                                <span className={style.Value}>
                                    <Moment element="span" format="DD MMMM"
                                            locale={i18n.languages[0]}>{this.props.order.selected_date}</Moment>
                                </span>
                            <span className={style.Type}>{t('date')}</span>
                        </div>
                        <div className={style.Block}>
                            <div className={style.Value}>
                                {this.renderTime()}
                            </div>
                            <span className={style.Type}>{t('time')}</span>
                        </div>
                    </div>

                    <div className={style.ChangeDate}
                         onClick={() => history.push(this.props.paths.schedule + '?view=preview_booking')}>
                        {t('change_date')}
                    </div>
                </React.Fragment>
                }

                <div className={style.ConfirmButton} onClick={() => this.confirmBooking()}>
                    <span>{t('confirm_booking')}</span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        errorFields: state.booking.errorFields,
        customer_data: state.form.customerForm?.values ?? {},
        paths: state.routes.paths,
        salon: state.salon_location.selected_location,
        order: state.order,
        categories: state.categories,
        settings: state.salon_location.selected_location.settings,
        bookingSubmitting: state.booking.submitting,
        booking: state.booking.booking,
        bookingErrorCode: state.booking.errorCode,
        group_settings: state.salon_location.group_settings,
    };
};

const TranslatedReview = withTranslation(['review'])(Review);

export default withRouter(connect(mapStateToProps, {
    setTransGroup,
    setActiveProgress,
    removeServicesFromOrder,
    confirmBooking,
})(TranslatedReview));
