import React, {Component, Fragment} from 'react'
import moment from 'moment'
import {withTranslation} from 'react-i18next'
import classNames from 'classnames'
import inputStyle from '../scss/components/FormInput.module.scss'

class FormDateField extends Component {
    lang = this.props.i18n.languages[0]
    state = {
        lang: this.props.i18n.languages[0],
        open: false,
        selected: false,
        touched: false,
        value: (new Date()).getFullYear() + '-01-01',
    }

    constructor(props) {
        super(props)

        moment.locale(this.state.lang)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.i18n.languages[0] !== this.state.lang)
            this.setState({
                lang: this.props.i18n.languages[0],
            })

        if (this.state.lang !== prevState.lang)
            moment.locale(this.state.lang)

        if (this.props.input.value && !this.state.selected)
            this.setState({
                selected: true,
            })
    }

    renderDayOptions() {
        const options = []

        for (let i = 1; i <= 31; ++i) {
            let value = i
            if (String(value).length === 1)
                value = '0' + value

            options.push(
                <option value={value} key={'day_' + i}>{i}</option>,
            )
        }

        return options
    }

    renderMonthOptions() {
        const options = []

        for (let i = 0; i <= 11; ++i) {
            let value = i + 1
            if (String(value).length === 1)
                value = '0' + value

            options.push(
                <option value={value} key={'month_' + i}>{moment().month(i).format('MMMM')}</option>,
            )
        }

        return options
    }

    renderYearOptions() {
        const options = []

        const currentYear = (new Date()).getFullYear()
        for (let i = currentYear; i >= currentYear - 116; --i) {
            options.push(
                <option value={String(i)} key={'year_' + i}>{i}</option>,
            )
        }

        return options
    }

    setFocusState = () => {
        this.setState({
            open: true,
        })
    }

    setBlurState = () => {
        this.setState({
            open: false,
        })
    }

    onChange = (e, dateSplit, type) => {
        let newDate = dateSplit[0] + '-' + dateSplit[1] + '-' + dateSplit[2]

        if (type === 'year')
            newDate = e.target.value + '-' + dateSplit[1] + '-' + dateSplit[2]
        else if (type === 'month')
            newDate = dateSplit[0] + '-' + e.target.value + '-' + dateSplit[2]
        else if (type === 'day')
            newDate = dateSplit[0] + '-' + dateSplit[1] + '-' + e.target.value

        this.props.input.onChange(newDate)
    }

    renderDateSelects() {
        const {input: {value}} = this.props

        const dateSplit = (value ? value.split('-') : this.state.value.split('-'))

        const output = [
            <span className={inputStyle.DateCol3} key={'Year'}>
                {dateSplit[0]}
                <select className={inputStyle.Select}
                        value={dateSplit[0]}
                        onChange={e => this.onChange(e, dateSplit, 'year')}
                        onFocus={this.setFocusState}
                        onBlur={this.setBlurState}>
                    {this.renderYearOptions()}
                </select>
            </span>,
            <span className={classNames({
                [inputStyle.DateCol2]: this.state.lang !== 'en',
                [inputStyle.DateCol1]: this.state.lang === 'en',
            })} key={'Month'}>
                {dateSplit[1]}
                <select className={inputStyle.Select}
                        value={dateSplit[1]}
                        onChange={e => this.onChange(e, dateSplit, 'month')}
                        onFocus={this.setFocusState}
                        onBlur={this.setBlurState}>
                    {this.renderMonthOptions()}
                </select>
            </span>,
            <span className={classNames({
                [inputStyle.DateCol2]: this.state.lang === 'en',
                [inputStyle.DateCol1]: this.state.lang !== 'en',
            })} key={'Day'}>
                {dateSplit[2]}
                <select className={inputStyle.Select}
                        value={dateSplit[2]}
                        onChange={e => this.onChange(e, dateSplit, 'day')}
                        onFocus={this.setFocusState}
                        onBlur={this.setBlurState}>
                    {this.renderDayOptions()}
                </select>
            </span>,
        ]

        if (this.state.lang === 'en')
            return (
                <Fragment>
                    {output[1]} / {output[2]} / {output[0]}
                </Fragment>
            )


        return (
            <Fragment>
                {output[2]} / {output[1]} / {output[0]}
            </Fragment>
        )
    }

    renderDateField() {
        const {label, logo, meta: {error, touched}} = this.props

        return (
            <div className={classNames(inputStyle.FormInput, {
                [inputStyle.Selected]: this.state.open,
                [inputStyle.Error]: (touched || this.props.hasSubmissionError) && error,
            })}>
            <span className={inputStyle.Logo}>
                {logo}
            </span>
                <label className={inputStyle.Label}>
                    <span className={inputStyle.Text}>{label}</span>
                    <span className={classNames(inputStyle.BirthdayPlaceholder, {
                        [inputStyle.NotSelected]: !this.state.selected,
                    })}>
                        {this.renderDateSelects()}
                    </span>
                </label>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.renderDateField()}
            </div>
        )
    }
}

export default withTranslation()(FormDateField)
