import React from 'react';
import {withTranslation} from "react-i18next";
import connect from "react-redux/es/connect/connect";
import styleHeader from "../scss/components/Header.module.scss";
import MoveBackButton from "./MoveBackButton";
import classNames from "classnames";

class SimpleHeader extends React.Component {

    render() {
        const {t} = this.props;

        return (
            <div className={classNames(styleHeader.Header, styleHeader.Simple)}>
                <div className={classNames(styleHeader.Navigator, styleHeader.Simple)}>
                    <MoveBackButton />
                </div>
                <div className={styleHeader.Heading}>
                    <div className={classNames(styleHeader.Title, styleHeader.Simple)}>{t(this.props.ui.trans_group + ':heading')}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ui: state.ui
    };
};

const TranslatedSimpleHeader = withTranslation()(SimpleHeader);

export default connect(mapStateToProps)(TranslatedSimpleHeader);
