import {ON_SLUG_CHANGE} from '../actions/type'
import {get_app_routes, initPaths} from '../routes'

const INITIAL_STATE = {
    paths: initPaths(),
    app_routes: get_app_routes(),
    active_payload: '',
}

const routesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ON_SLUG_CHANGE:
            return {
                ...state,
                paths: initPaths(action.payload),
                app_routes: get_app_routes(action.payload !== ''),
                active_payload: action.payload,
            }
        default:
            return state
    }
}

export default routesReducer
