import React from 'react';
import {Redirect} from 'react-router-dom';
import ReactPageComponent, {connect} from '../components/ReactPageComponent';
import {withTranslation} from 'react-i18next';
import {setTransGroup} from '../actions/userInterface';
import {startNewBooking} from '../actions/booking';
import style from '../scss/components/Booking.module.scss';
import {ReactComponent as CheckLogo} from '../assets/svg/check.svg';
import {Component, Property} from 'immutable-ics';
import FileSaver from 'file-saver';
import moment from 'moment';
import classNames from "classnames";
import {getFormValues} from "redux-form";
import {withCookies} from "react-cookie";

class Booking extends ReactPageComponent {
    booking = this.props.booking;
    scrollPosKey = 'Booking';

    state = {
        booking: this.props.booking,
        order: this.props.order,
        selectedLocation: this.props.selectedLocation,
        groupSettings: this.props.groupSettings
    };

    startNewBooking = () => {
        this.props.startNewBooking();
        this.setState({
            booking: null,
            order: null,
            selectedLocation: null,
            groupSettings: null
        });
    };

    saveBookingToState() {
        this.setState({
            booking: this.props.booking,
            order: this.props.order,
            selectedLocation: this.props.selectedLocation,
            groupSettings: this.props.groupSettings,
        });
        this.props.startNewBooking();
    }

    componentDidMount() {
        super.componentDidMount();

        this.props.setTransGroup('booking');

        if (this.props.booking) {
            const {cookies} = this.props;

            const employee_key = 'favorite_employees_' + this.props.selectedLocation.id;
            const customer_key = 'customer_data_' + this.props.selectedLocation.id;

            let employees = cookies.get(employee_key) || [];

            const employeeId = this.props.order.selected_employee.id;
            const selectedTime = this.props.order.selected_date + ' ' + this.props.order.selected_time;

            if (employees && employees.length) {
                const index = employees.findIndex(emp => emp.id === employeeId);

                if (index !== -1) {
                    employees[index]['time'] = selectedTime;
                    employees[index]['count']++;
                } else {
                    employees.push(
                        {
                            id: employeeId,
                            time: selectedTime,
                            count: 0,
                        });
                }
            } else {
                employees = [
                    {
                        id: employeeId,
                        time: selectedTime,
                        count: 0,
                    }];
            }

            let cleanFormValues = this.props.formValues;
            let {terms, ...formValuesWithoutTerms} = cleanFormValues;

            cookies.set(employee_key, JSON.stringify(employees), {path: '/'});
            cookies.set(customer_key, JSON.stringify(formValuesWithoutTerms), {path: '/'});
            this.saveBookingToState();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);

        if (!prevProps.booking && this.props.booking)
            this.saveBookingToState();
    }

    generateIcs = () => {
        const {i18n} = this.props;
        const curLang = i18n.languages[0];
        const booking = this.state.booking;
        const selected_location = this.state.selectedLocation;
        const salon_name = selected_location?.location_names[curLang] ?? booking.salon.name;

        const header = 'Appointment with ' + this.state.order.selected_employee.name + ' at ' + salon_name;

        const services = [];
        this.state.order.ids.forEach(serviceId => {
            const serviceName = this.state.order.services_by_id[serviceId]['name'];
            services.push(serviceName[curLang] || serviceName['en']);
        });

        const startTime = moment(this.state.order.selected_date + ' ' + this.state.order.selected_time, 'YYYY-MM-DD HH:mm');
        const endTime = startTime.clone().add(this.state.order.selected_employee.service_duration, 'minutes');

        const calendar = new Component({
            name: 'VCALENDAR',
            properties: [
                new Property({name: 'VERSION', value: 2}),
            ],
            components: [
                new Component({
                    name: 'VEVENT',
                    properties: [
                        new Property({
                            name: 'DTSTART',
                            parameters: {VALUE: 'DATE-TIME'},
                            value: startTime.toDate(),
                        }),
                        new Property({
                            name: 'DTEND',
                            parameters: {VALUE: 'DATE-TIME'},
                            value: endTime.toDate(),
                        }),
                        new Property({
                            name: 'DESCRIPTION',
                            value: services,
                        }),
                        new Property({
                            name: 'SUMMARY',
                            value: header,
                        }),
                    ],
                }),
            ],
        });

        const fileName = 'booking_' + booking.id + '.ics';

        const blob = new Blob([calendar.toString()], {type: 'text/calendar'});
        FileSaver.saveAs(blob, fileName);
    };

    renderActionButton() {
        const {t} = this.props;
        const newBooking = this.state.groupSettings['my_salon_group.thank_you.action_after'] === 'new_booking';
        let externalLink = this.state.groupSettings['my_salon_group.thank_you.external_link'];

        if (externalLink) {
            if (!/^https?:\/\//i.test(externalLink)) {
                externalLink = 'http://' + externalLink;
            }
        }

        return (
            <div className={style.ActionButton} onClick={() => newBooking ? this.startNewBooking() : window.location.href = externalLink}>
                <span>{newBooking ? t('new_booking') : t('back_to_website')}</span>
            </div>
        );
    }

    render() {
        if (this.props.errorCode) {
            return <Redirect to={this.props.error_path}/>;
        }

        if (!this.state.booking) {
            return <Redirect to={'/'}/>;
        }

        const {t} = this.props;

        return (
            <div className={style.Booking}>
                <div className={style.Image}>
                    <div className={style.Icon}>
                        <CheckLogo/>
                    </div>
                </div>
                <div className={style.Header}>
                    {t('booking_confirmed')}
                </div>
                <div className={style.SubHeader}>
                    {t('order_id')}: {this.state.booking.id}
                </div>
                <div className={classNames(style.Buttons, style.BookingConfirmed)}>
                    {this.state.groupSettings['my_salon_group.thank_you.add_to_calendar_button'] &&
                        <div className={style.ActionButton} onClick={() => this.generateIcs()}>
                            <span>{t('add_to_calendar')}</span>
                        </div>
                    }
                    {this.renderActionButton()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error_path: state.routes.paths.error,
        booking: state.booking.booking,
        errorCode: state.booking.errorCode,
        order: state.order,
        selectedLocation: state.salon_location.selected_location,
        groupSettings: state.salon_location.group_settings,
        formValues: getFormValues('customerForm')(state),
    };
};

const TranslatedBooking = withTranslation(['booking'])(Booking);

export default withCookies(connect(mapStateToProps, {
    setTransGroup,
    startNewBooking,
})(TranslatedBooking));