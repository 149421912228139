import React, {Component} from 'react'
import {withRouter} from 'react-router-dom';
import classNames from 'classnames';
import inputStyle from '../scss/components/FormTermsField.module.scss';
import {ReactComponent as CheckIcon} from '../assets/svg/check.svg';
import {ReactComponent as ArrowIcon} from '../assets/svg/next-icon.svg';
import {connect} from './ReactPageComponent';

class FormTermsField extends Component {
    render() {
        const {input, label, meta: {touched, error}, history} = this.props;

        return (
            <div className={inputStyle.TermsField}>
                <div className={classNames(inputStyle.Container, {
                    [inputStyle.Error]: (touched || this.props.hasSubmissionError) && error,
                })}>
                    <label>
                        <input {...input} id={input.name} type="checkbox" checked={input.value === true}/>
                        <span className={inputStyle.Box}>
                        <CheckIcon/>
                    </span>
                        <span className={inputStyle.Text}>{label}</span>
                    </label>
                    <span className={inputStyle.LinkArrow} onClick={() => history.push(this.props.terms_path)}>
                    <ArrowIcon/>
                </span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        terms_path: state.routes.paths.terms,
    };
};

export default withRouter(connect(mapStateToProps, {})(FormTermsField));
